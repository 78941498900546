import React, { useState, useEffect } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Footer from '../../components/appSpecific/Footer'
import Header2 from '../../components/appSpecific/header2'
import Sidebar from '../../components/appSpecific/Sidebar'
import { languages } from '../../components/appSpecific/LanguageCode'
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import axios from 'axios'
import { Markup } from 'interweave'
import moment from 'moment/moment'

function Listdetails() {
  const [openSide, setopenSide] = useState(false)
  const [allData, setAllData] = useState([])
  const [unitData, setUnitData] = React.useState([])

  const [data3, setData3] = useState()
  const location = useLocation()
  const type = location.state.param2
  const elem = location.state.param1
  const id = elem.id
  // console.log('xxxxxxx', type)
  // console.log('rajeeb', elem)
  // console.log('rajeebswas', unitData)
  // console.log(data3, 'manas')
  // console.log('>>>>>>>>>>>> ID genarate', id)
  const [toggleState, setToggleState] = React.useState(false)

  const handleClickChange = (val) => {
    setToggleState(val)
  }

  const obj1 = localStorage.getItem('token')
  const token = JSON.parse(obj1)
  const detailsData = () => {
    axios
      .get(`https://api.bfa-platform.com/api/investments/show/${id}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        // console.log("details ------------> data investment", res.data.data.project_description);
        setData3(res.data.data.project_description)
        setAllData(res.data.data)
      })
      .catch((e) => {
        console.log('Error--->', e.response.data)
      })
  }

  const detailsData2 = () => {
    axios
      .get(`https://api.bfa-platform.com/api/prix/show/${id}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        // console.log('details unit price', res.data.data)
        setUnitData(res.data.data)
        // setData4(res.data.data.price_designation);
        // console.log('>>>>>>>>>unit_data', unitData)
      })
      .catch((e) => {
        console.log('Error--->', e.response.data)
      })
  }

  useEffect(() => {
    detailsData()
    detailsData2()
  }, [])

  //laguage Translator
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()
  useEffect(() => {
    console.log('Setting page stuff counter2')
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage, t])

  return (
    <>
      {type === 'Projects' && (
        <div class="wrapper">
          <Sidebar value={toggleState} openSideBar={openSide} />
          <div class="main">
            <Header2
              handleChange={handleClickChange}
              handleSideBar={() => {
                setopenSide(!openSide)
              }}
            />
            <main class="content">
              <div class="container-fluid p-0">
                <div class="row">
                  <div class="col-12 col-xl-8 mb-4 ">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">
                          <NavLink to="/dashboard">{t('Home')}</NavLink>
                        </li>
                        <li class="breadcrumb-item">
                          <NavLink to="/projects">{t(type)}</NavLink>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          {elem.name ? elem.name.split(' ', 10).join(' ') : ''}{' '}
                          ...
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-8 col-xxl-8 ">
                    <div class="card min-vh-100">
                      <div class="card-header pt-4 mb-0">
                        <h5 class="card-title mb-3">
                          {elem.name ? elem.name : ''}
                        </h5>
                        <div class="badge bg-warning country_pill mr-3">
                          {elem.country == null ? 'N/A' : elem.country}
                        </div>
                        <div class="badge bg-info status_pill">
                          {elem.project_status == null
                            ? 'N/A'
                            : elem.project_status}
                        </div>

                        <h3> {t("Project summary")}</h3>
                        <Markup
                          content={
                            elem.analytic_summary == null
                              ? 'N/A'
                              : elem.analytic_summary
                          }
                        />
                      </div>
                      <div></div>
                      <div class="card-body pt-0">
                        {}
                        <p class="text-muted">{}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xxl-4">
                    <div class="card mb-4 h-100">
                      <div class="card-header pt-4">
                        <h5 class="card-title mb-0">{t('Basic Data :')}</h5>
                      </div>
                      <div class="card-body">
                        <div class="row"></div>
                        <dl class="row mb-1">
                          <dt class="col-6 col-xxl-6"> {t('Status :')} </dt>
                          <dd class="col-6 col-xxl-6">
                            <span class="badge bg-info status_pill mb-1">
                              {elem.project_status == null
                                ? 'N/A'
                                : elem.project_status}
                            </span>
                          </dd>

                          <dt class="col-6 col-xxl-6"> {'Project :'}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.name == null ? 'N/A' : elem.name}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6"> {'Continent :'}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.continent == null ? 'N/A' : elem.continent}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Country :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.country == null ? 'N/A' : elem.country}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Sector :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.sector == null ? 'N/A' : elem.sector}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Montant ($) :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                            ${elem.amount == null ? 'N/A' : elem.amount}
                            </p>
                          </dd>

                          {/* <dt class="col-6 col-xxl-6">{t("Web link :")}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">{ }</p>
                          </dd> */}
                        </dl>
                      </div>
                      <hr />

                      <div class="card-header">
                        <h5 class="card-title mb-0">{t('Contact :')}</h5>
                      </div>
                      <div class="card-body">
                        <dl class="row mb-1">
                          <dt class="col-6 col-xxl-6">
                            {t('Executive Agency :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.executing_agency == null
                                ? 'N/A'
                                : elem.executing_agency}
                            </p>
                          </dd>
                          <dt class="col-6 col-xxl-6">{t('Responsable :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.boss_name == null ? 'N/A' : elem.boss_name}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Link :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.website == null ? 'N/A' : elem.website}
                            </p>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      )}

      {type === 'Tenders' && (
        <div class="wrapper">
          <Sidebar />
          <div class="main">
            <Header2 />
            <main class="content">
              <div class="container-fluid p-0">
                <div class="row">
                  <div class="col-12 col-xl-8 mb-4 ">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">
                          <NavLink to="/dashboard">{t('Home')}</NavLink>
                        </li>
                        <li class="breadcrumb-item">
                          <NavLink to="/tenders">{t(type)}</NavLink>
                          {console.log('type',type)}
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          {elem.acquisition_title
                            ? elem.acquisition_title.split(' ', 10).join(' ')
                            : ''}{' '}
                          ...
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-8 col-xxl-8 ">
                    <div class="card min-vh-100">
                      <div class="card-header pt-4 mb-0">
                        <h5 class="card-title mb-3">
                          {elem.acquisition_title ? elem.acquisition_title : ''}
                        </h5>
                        <div class="badge bg-warning country_pill mr-3">
                          {elem.country == null ? 'N/A' : elem.country}
                        </div>

                        <div class="badge bg-info status_pill mr-3">
                          {elem.selection_mode == null
                            ? 'N/A'
                            : elem.selection_mode}
                        </div>

                        <div class="badge bg-success country_pill">
                          {moment(elem.submission_date).format('DD/MM/YYYY') ==
                          null
                            ? 'N/A'
                            : moment(elem.submission_date).format('DD/MM/YYYY')}
                        </div>
                        
                        {moment(elem.submission_date).diff(moment(), 'days') >=
                          0 && (
                          <div class="badge bg-success country_pill ml-3">
                            {moment(elem.submission_date).diff(
                              moment(),
                              'days',
                            )+1} {t("days remaining")}
                          </div>
                        )}
                      </div>

                      <div class="card-body pt-0">
                        <p style={{ fontSize: '20px' }}>
                          {t('Tender summary')}
                        </p>

                        <p class="text-muted">
                          {' '}
                          <Markup
                            content={
                              elem.acquisition_description == null
                                ? 'N/A'
                                : elem.acquisition_description
                            }
                          />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xxl-4">
                    <div class="card mb-4 h-100">
                      <div class="card-header pt-4">
                        <h5 class="card-title mb-0">{t('Basic Data :')}</h5>
                      </div>
                      <div class="card-body">
                        <div class="row"></div>
                        <dl class="row mb-1">
                          <dt class="col-6 col-xxl-6">{t('Project :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.project == null ? 'N/A' : elem.project}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{'Continent :'}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.continent == null ? 'N/A' : elem.continent}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Country :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.country == null ? 'N/A' : elem.country}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Sector :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.sector == null ? 'N/A' : elem.sector}
                            </p>
                          </dd>
                          <dt class="col-6 col-xxl-6">{t('Donor :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.bailleur == null ? 'N/A' : elem.bailleur}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Cost :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.estimated_amount_currency == null
                                ? 'N/A'
                                : elem.estimated_amount_currency}
                            </p>
                          </dd>
                          <dt class="col-6 col-xxl-6">{t('Launch Date :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.start_date == null
                                ? 'N/A'
                                : moment(elem.start_date).format('DD/MM/YYYY')}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Deadline :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.submission_date == null
                                ? 'N/A'
                                : moment(elem.submission_date).format(
                                    'DD/MM/YYYY',
                                  )}
                            </p>
                          </dd>


                          
                          {moment(elem.submission_date).diff(moment(), 'days') >=
                            0 && (
                            <>
                              <dt class="col-6 col-xxl-6">{t('Days Left :')}</dt>
                              <dd class="col-6 col-xxl-6">
                              <div class="">
                            {moment(elem.submission_date).diff(
                              moment(),
                              'days',
                            )+1} 
                          </div>
                              </dd>                       
                            </>
                        )}



                          
                          



                        </dl>
                      </div>
                      <hr />

                      <div class="card-header">
                        <h5 class="card-title mb-0">{t('Contacts :')} </h5>
                      </div>
                      <div class="card-body">
                        <dl class="row mb-1">
                          {}
                          <dt class="col-6 col-xxl-6">
                            {' '}
                            {t('Executive Agency :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.executing_agency == null
                                ? 'N/A'
                                : elem.executing_agency}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Responsable :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.country_decision_maker_name == null
                                ? 'N/A'
                                : elem.country_decision_maker_name}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Address :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.executing_agency_address == null
                                ? 'N/A'
                                : elem.executing_agency_address}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Email :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">{elem.executing_agency_email}</p>
                          </dd>

                          {/* <dt class="col-6 col-xxl-6">{t('Link :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.ref == null ? 'N/A' : elem.elem.ref}
                            </p>
                          </dd> */}
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      )}

      {type === 'Investments' && (
        <div class="wrapper">
          <Sidebar />
          <div class="main">
            <Header2 />
            <main class="content">
              <div class="container-fluid p-0">
                <div class="row">
                  <div class="col-12 col-xl-8 mb-4 ">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">
                          <NavLink to="/dashboard">{t('Home')}</NavLink>
                        </li>
                        <li class="breadcrumb-item">
                          <NavLink to="/investment">{t(type)}</NavLink>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          {elem.project_name
                            ? elem.project_name.split(' ', 10).join(' ')
                            : ''}
                          ...
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-8 col-xxl-8 ">
                    <div class="card min-vh-100">
                      <div class="card-header pt-4 mb-4">
                        <h5 class="card-title mb-0">
                          {elem.project_name ? elem.project_name : ''}
                        </h5>
                        <div class="badge status_pill bg-info my-2">
                          {elem.project_classification == null
                            ? 'N/A'
                            : elem.project_classification}
                        </div>
                        <h4> {t('Investments Summary')}</h4>
                        <p>
                          <Markup content={data3} />
                        </p>
                      </div>
                      <div class="card-body pt-0">
                        <p class="text-muted">
                          {' '}
                          <Markup
                            content={
                              elem.acquisition_description == null
                                ? ''
                                : elem.acquisition_description
                            }
                          />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xxl-4">
                    <div class="card mb-4 h-100">
                      <div class="card-header pt-4">
                        <h5 class="card-title mb-0"> {t('Basic Data :')}</h5>
                      </div>
                      <div class="card-body">
                        <div class="row"></div>
                        <dl class="row mb-1">
                          {/* <dt class="col-6 col-xxl-6">
                            {" "}
                            {t("Name of the Project :")}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">{elem.project_name}</p>
                          </dd> */}
                          <dt class="col-6 col-xxl-6">
                            {t('Date of creation :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {allData.date_event == null
                                ? 'N/A'
                                : // : allData.date_event}
                                  moment(allData.date_event).format(
                                    'DD/MM/YYYY',
                                  )}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Total Project Value ($) :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.total_project_value == null
                                ? 'N/A'
                                : elem.total_project_value}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">
                            {t('Financial gap ($):')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.projected_financial_gap == null
                                ? 'N/A'
                                : elem.projected_financial_gap}
                            </p>
                          </dd>

                          {/* <dt class="col-6 col-xxl-6">
                            {t('Date of Event :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {allData.date_event == null
                                ? 'N/A'
                                : allData.date_event}
                            </p>
                          </dd> */}

                          {/* <dt class="col-6 col-xxl-6">
                            {t('Type of Project :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">{'N/A'}</p>
                          </dd> */}

                          <dt class="col-6 col-xxl-6">
                            {t('Stage of the Project :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.project_stage == null
                                ? 'N/A'
                                : elem.project_stage}
                            </p>
                          </dd>
                          <dt class="col-6 col-xxl-6">
                            {t('Implementation schedule :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {allData.project_time_frame == null
                                ? 'N/A'
                                : allData.project_time_frame}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">
                            {' '}
                            {t('Project Classification(Size) :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.project_classification_by_size == null
                                ? 'N/A'
                                : elem.project_classification_by_size}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">
                            {t('Project readiness indicators :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.project_readiness_indicators == null
                                ? 'N/A'
                                : elem.project_readiness_indicators}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">
                            {t('Number of jobs created :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {allData.number_of_jobs_created == null
                                ? 'N/A'
                                : allData.number_of_jobs_created}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">
                            {t('Number of direct beneficiaries :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {allData.direct_number_of_beneficiaries == null
                                ? 'N/A'
                                : allData.direct_number_of_beneficiaries}
                            </p>
                          </dd>
                        </dl>
                      </div>
                      <hr />

                      <div class="card-header">
                        <h5 class="card-title mb-0">{t('Funding :')}</h5>
                      </div>
                      <div class="card-body">
                        <dl class="row mb-1">
                          {}
                          <dt class="col-6 col-xxl-6">
                            {t('Total Project Value :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.total_project_value == null
                                ? 'N/A'
                                : elem.total_project_value}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">
                            {t('Amount of Debt :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {allData.debt == null ? 'N/A' : allData.debt}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">
                            {t('Amount of Donation :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">{'N/A'}</p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Equity :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">{'N/A'}</p>
                          </dd>

                          <dt class="col-6 col-xxl-6">
                            {t('Risk mitigation :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {allData.risk_mitigation == null
                                ? 'N/A'
                                : allData.risk_mitigation}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">
                            {t('Guaranties :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">{'N/A'}</p>
                          </dd>
                          <dt class="col-6 col-xxl-6">
                            {t('Gap in term of Debt :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {allData.gap_of_which_debt == null
                                ? 'N/A'
                                : allData.gap_of_which_debt}
                            </p>
                          </dd>
                          <dt class="col-6 col-xxl-6">
                            {' '}
                            {t('Gap en term d Equity :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {allData.gap_of_which_equity == null
                                ? 'N/A'
                                : allData.gap_of_which_equity}
                            </p>
                          </dd>
                          {/* <dt class="col-6 col-xxl-6">
                            {' '}
                            {t('Planned schedule of bussiness operation :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">{'N/A'}</p>
                          </dd> */}
                        </dl>
                      </div>
                      <hr />
                      <div class="card-header">
                        <h5 class="card-title mb-0">{t('Investors :')}</h5>
                      </div>
                      <div class="card-body">
                        <dl class="row mb-1">
                          {}
                          <dt class="col-6 col-xxl-6">
                            {' '}
                            {t('Project sponsor :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">A rechercher</p>
                          </dd>

                          <dt class="col-6 col-xxl-6">
                            {t('Current Investor :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">A rechercher</p>
                          </dd>

                          <dt class="col-6 col-xxl-6">
                            {t('Other key stakeholders :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">A rechercher</p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Lenders :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">A rechercher</p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Investors :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">A rechercher</p>
                          </dd>

                          <dt class="col-6 col-xxl-6">
                            {t('Special Purpose Vehicle :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">A rechercher</p>
                          </dd>
                          <dt class="col-6 col-xxl-6">
                            {' '}
                            {t('Granting authority :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">A rechercher</p>
                          </dd>
                          <dt class="col-6 col-xxl-6">
                            {' '}
                            {t('Off-taker/Buyer :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">A rechercher</p>
                          </dd>
                          <dt class="col-6 col-xxl-6">
                            {' '}
                            {t('Guarantee providers :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">A rechercher</p>
                          </dd>

                          <dt class="col-6 col-xxl-6">
                            {' '}
                            {t('Developers/Operators :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">A rechercher</p>
                          </dd>
                          <dt class="col-6 col-xxl-6">
                            {' '}
                            {t('Transaction advisors :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">A rechercher</p>
                          </dd>
                          <dt class="col-6 col-xxl-6">
                            {' '}
                            {t('Technical assistance providers :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">A rechercher</p>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      )}

      {type === 'Unit-Prices' && (
        <div class="wrapper">
          <Sidebar />
          <div class="main">
            <Header2 />
            <main class="content">
              <div class="container-fluid p-0">
                <div class="row">
                  <div class="col-12 col-xl-8 mb-4 ">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">
                          <NavLink to="/dashboard">{t('Home')}</NavLink>
                        </li> 
                        <li class="breadcrumb-item">
                          <NavLink to="/unitprice">{t(type)}</NavLink>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          {elem.price_designation
                            ? elem.price_designation.split(' ', 10).join(' ')
                            : ''}{' '}
                          ...
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-8 col-xxl-8 ">
                    <div class="card min-vh-100">
                      <div class="card-header pt-4 mb-4">
                        <h5 class="card-title mb-0">
                          <Markup
                            content={
                              elem.price_designation.split(' ', 10).join(' ')
                                ? elem.price_designation
                                    .split(' ', 10)
                                    .join(' ')
                                : ''
                            }
                          />
                        </h5>
                        <div class="badge status_pill bg-info my-2">
                          {elem.category_acquisition}
                        </div>
                        <h4> Price Descripition</h4>
                        <Markup
                          content={
                            elem.price_designation ? elem.price_designation : ''
                          }
                        />
                      </div>
                      <div class="card-body pt-0">
                        <p class="text-muted"></p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xxl-4">
                    <div class="card mb-4 h-100">
                      <div class="card-header pt-4">
                        <h5 class="card-title mb-0">{t('Basic Data :')}</h5>
                      </div>
                      <div class="card-body">
                        <div class="row"></div>
                        <dl class="row mb-1">
                          <dt class="col-6 col-xxl-6">{t('Country :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.country == null ? 'N/A' : elem.country}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('City :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {unitData?.city?.name == null
                                ? 'N/A'
                                : unitData?.city?.name}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Minimum :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.min_price == null ? 'N/A' : elem.min_price}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Average :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.middle_price == null
                                ? 'N/A'
                                : elem.middle_price}
                            </p>
                          </dd>

                          {/* <dt class="col-6 col-xxl-6">{t("Descrption :")}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">{ }</p>
                          </dd> */}

                          <dt class="col-6 col-xxl-6">{t('Maximum :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.max_price == null ? 'N/A' : elem.max_price}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Unit :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.unit == null ? 'N/A' : elem.unit}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Family :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {unitData.family == null
                                ? 'N/A'
                                : unitData.family}
                            </p>
                          </dd>
                          <dt class="col-6 col-xxl-6">{t('Sub-family :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {unitData.sub_family == null
                                ? 'N/A'
                                : unitData.sub_family}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Continent :')} </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {unitData?.continent?.name == null
                                ? 'N/A'
                                : unitData?.continent?.name}
                            </p>
                          </dd>

                          {/* <dt class="col-6 col-xxl-6">{t("Minimum :")}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">{elem.middle_price}</p>
                          </dd> */}
                          <dt class="col-6 col-xxl-6">{t('Sector :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.sector == null ? 'N/A' : elem.sector}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Year :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.years == null ? 'N/A' : elem.years}
                            </p>
                          </dd>
                          <dt class="col-6 col-xxl-6"> {t('Institution :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {unitData.institution == null
                                ? 'N/A'
                                : unitData.institution}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Project :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {unitData.project_id == null
                                ? 'N/A'
                                : unitData.project_id}
                            </p>
                          </dd>
                          <dt class="col-6 col-xxl-6">{t('Source :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">www.maroc.prix-construction</p>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      )}

      {type === 'Jobs' && (
        <div class="wrapper">
          <Sidebar />
          <div class="main">
            <Header2 />
            <main class="content">
              <div class="container-fluid p-0">
                <div class="row">
                  <div class="col-12 col-xl-8 mb-4 ">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">
                          <NavLink to="/dashboard">{t('Home')}</NavLink>
                        </li>
                        <li class="breadcrumb-item">
                          <NavLink to="/jobs">{t(type)}</NavLink>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          {elem.experience
                            ? elem.experience.split(' ', 10).join(' ')
                            : ''}{' '}
                          ...
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-8 col-xxl-8 ">
                    <div class="card min-vh-100">
                      <div class="card-header pt-4">
                        <h5 class="card-title mb-0">
                          {elem.job_title ? elem.job_title : ''}
                        </h5>
                        <div class="badge status_pill bg-info my-2 mr-3">
                          {elem.type}
                        </div>
                        <div class="badge country_pill bg-success my-2">
                          {moment(elem.apply_by).format('DD/MM/YYYY')}
                        </div>

                        {moment(elem.apply_by).diff(moment(), 'days') >=
                          0 && (
                          <div class="badge bg-success country_pill ml-3">
                            {moment(elem.apply_by).diff(
                              moment(),
                              'days',
                            )+1} {t("days remaining")}
                          </div>
                          )}
                        


                      </div>

                      <div class="card-body pt-0">
                        <h3 style={{ fontSize: '24px' }}>Job Description</h3>
                        <p class="text-muted">
                          <Markup content={elem.job_description} />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-xxl-4">
                    <div class="card mb-4 h-100">
                      <div class="card-header pt-4">
                        <h5 class="card-title mb-0">{t('Basic Data :')}</h5>
                      </div>
                      <div class="card-body">
                        <div class="row"></div>
                        <dl class="row mb-1">
                          <dt class="col-6 col-xxl-6"> {t('Job title :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.job_title == null ? 'N/A' : elem.job_title}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Country :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">Multinational</p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Organisation :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">{elem.organization}</p>
                          </dd>

                          <dt class="col-6 col-xxl-6">
                            {t('Publication Date :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.publication_date == null
                                ? 'N/A'
                                : // : elem.publication_date}
                                  moment(elem.publication_date).format(
                                    'DD/MM/YYYY',
                                  )}
                            </p>
                          </dd>
                          <dt class="col-6 col-xxl-6">
                            {t('Date of Submission :')}
                          </dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.apply_by == null
                                ? 'N/A'
                                : // : elem.apply_by}
                                  moment(elem.apply_by).format('DD/MM/YYYY')}
                            </p>
                          </dd>

                          {moment(elem.apply_by).diff(moment(), 'days') >=
                            0 && (
                            <>
                              <dt class="col-6 col-xxl-6">{t('Days Left :')}</dt>
                              <dd class="col-6 col-xxl-6">
                              <div class="">
                            {moment(elem.apply_by).diff(
                              moment(),
                              'days',
                            )+1} 
                          </div>
                              </dd>                       
                            </>
                        )}

                         
                          






                          <dt class="col-6 col-xxl-6">{t('Sector :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.ftq == null ? 'N/A' : elem.ftq}
                            </p>
                          </dd>
                          {/* <dt class="col-6 col-xxl-6">{t("Deadline :")}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">{ elem.apply_by}</p>
                          </dd> */}
                          <dt class="col-6 col-xxl-6">{t('Type :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.type == null ? 'N/A' : elem.type}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Experience :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {}
                              {elem.experience == null
                                ? 'N/A'
                                : elem.experience}
                            </p>
                          </dd>

                          <dt class="col-6 col-xxl-6">{t('Location :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              {elem.address == null ? 'N/A' : elem.address}
                            </p>
                          </dd>
                          <dt class="col-6 col-xxl-6">{t('Link :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                              <Link to={elem.web_link}><div class="badge bg-info status_pill">Link</div> </Link>
                              
                            </p>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      )}
    </>
  )
}

export default Listdetails
