import React from 'react'
import location from '../../assets/img/catagory/location.svg'
import report from '../../assets/img/catagory/report.svg'
import annexes from '../../assets/img/catagory/annexes.svg'
import ppm from '../../assets/img/catagory/ppm.svg'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { numberWithSpaces } from '../../assets/js/settings'

function Projectcard(props) {
  const {
    Phase,
    countrey,
    status,
    Report,
    val1,
    val2,
    val3,
    val4,
    val5,
    val6,
    row4,
    row5,
    row6,
    row1,
    row2,
    row3,
    btn1,
    btn2,
    btn3,
    type,
    document,
    link,
    bussiness_plan,
    project_sheet,
    repobtn,
    annex,
    ppmbtn,
    routpath,
    designation,
    isDetails = false,
    id,
  } = props
  // console.log('first', isDetails)
  const detailNavigate = useNavigate()
  // console.log('iiiiiiidddd',id);
  const handleOnClickDetails = () => {
    detailNavigate('/details', { state: props, id: id })
  }

  // console.log(document);
  const apidata = 'https://api.bfa-platform.com/'
  // console.log(apidata);
  const OperNewUrl = () => {
    window.open(link, '_blank')
  }

  return (
    <>
      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4 grid-margin stretch-card">
        <div className="card">
          <div className="card-body card_box_content">
            <div className="d-flex justify-content-between location_status">
              <div className="card_box_location">
                <p>
                  <img src={location} /> <b>{countrey}</b>
                </p>
              </div>
              <div className="card_box_status">
                <p>{Report}</p>
              </div>
              <div className="card_box_status">
                <p>
                  {type === 'project' &&
                    (status === 'in progress' ? (
                      <span className="fir"></span>
                    ) : (
                      <span className="puspa"></span>
                    ))}
                  {status}
                </p>
              </div>
            </div>

            <div className="card_box_content_title">
              <h5 className="card-title-s1" onClick={handleOnClickDetails}>
                {Phase}
              </h5>
            </div>
            <div className="card_box_content_description">
              <p>
                {row1} {val1}
              </p>
              <p>
                {row2} {val2}
              </p>
              <p
                style={{ color: '#087cb7', fontWeight: '700' }}
                className="des_hover"
                onClick={handleOnClickDetails}
              >
                {designation}
              </p>
              {type === "Projects" && (
                 <p>
                 {row3} ${numberWithSpaces(val3)}
               </p>
              )}
             
              <p>
                {row4} {val4}
              </p>
              <p>
                {row5} {val5}
              </p>
              <p>
                {row6} {val6}
              </p>
            </div>

            {type === 'Projects' && (
              <div className="">
                <div className="row">
                  <div className="col">
                    <div className="card_box_content_button_report">
                      <a
                        href={repobtn ? apidata + repobtn : apidata + '/null'}
                        download
                        target="_blank"
                      >
                        <button className="btn">
                          <img src={report} />
                          {btn1}
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="col">
                    <div className="card_box_content_button_Ppm">
                      <a
                        href={ppmbtn ? apidata + ppmbtn : apidata + '/null'}
                        download
                      >
                        <button className="btn">
                          <img src={ppm} />
                          {btn3}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>

                {/* <div className="box_details">
                  <div className="octa_f">
                    <a href={apidata + annex} download>
                    <button
                      className="btn"
                      style={{
                        border: "none",
                        color: "#52b5e7",
                        fontWeight: "600",
                      }}
                      onClick={handleOnClickDetails}>
                      {btn2}{" "}
                      <span className="">
                        {" "}
                        <MdOutlineKeyboardArrowRight />{" "}
                      </span>
                    </button>
                    </a>
                  </div>
                </div> */}
              </div>
            )}

            {type === 'Tenders' && (
              <div className="">
                <div className="row">
                  <div className="col d-flex justify-content-center">
                    <div className="card_box_content_button_Ppm">
                      <a
                        href={document ? apidata + document : apidata + '/null'}
                        download
                        target="_blank"
                      >
                        <button className="btn">
                          <img src={ppm} />
                          {btn3}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>

                {/* <div className="box_details">
                <div className="octa_f">
                 <a href={apidata + annex} download>
                    <button
                        className="btn"
                        style={{
                        border: "none",
                        color: "#52b5e7",
                        fontWeight: "600",
                      }}
                      onClick={handleOnClickDetails}>
                    
                      {btn2}{" "}
                      <span className="">
                        {" "}
                        <MdOutlineKeyboardArrowRight />{" "}
                      </span>
                    </button>
                    </a>
                  </div>
                </div> */}
              </div>
            )}

            {type === 'Investments' && (
              <div className="d-flex card_box_content_button justify-content-between">
                <div className="card_box_content_button_report">
                  <a
                    href={
                      project_sheet
                        ? apidata + project_sheet
                        : apidata + '/null'
                    }
                    download
                    target="_blank"
                  >
                    <button className="btn">
                      <img src={report} />
                      {btn1}
                    </button>
                  </a>
                </div>
                <div className="card_box_content_button_annexes">
                  <a
                    href={
                      bussiness_plan
                        ? apidata + bussiness_plan
                        : apidata + '/null'
                    }
                    download
                    target="_blank"
                  >
                    <button className="btn">
                      <img src={annexes} />
                      {btn2}
                    </button>
                  </a>
                </div>
              </div>
            )}

            {type === 'Jobs' && (
              <div className="">
                <div className="card_box_content_button_report">
                  <div className="row">
                    <div className="col justify-content-center">
                      <button
                        className="btn"
                        style={{ position: 'relative', left: '30%' }}
                        onClick={() => OperNewUrl()}
                      >
                        <img src={report} />
                        {btn1}
                      </button>
                    </div>
                  </div>

                  <div className="box_details">
                    <div className="octa_f">
                      {/* <a href={apidata + annex} download> */}
                      {/* <button
                        className="btn"
                        style={{
                          border: "none",
                          color: "#52b5e7",
                          fontWeight: "600",
                        }}
                        onClick={handleOnClickDetails}
                      >
                        {btn2}{" "}
                        <span className="">
                          {" "}
                          <MdOutlineKeyboardArrowRight />{" "}
                        </span>
                      </button> */}
                      {/* </a> */}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {type === 'Investments' || type === 'Unit-Prices' ? (
              <>
                <div className="box_details">
                  <div className="card_box_content_button_annexes_cos">
                    {/* <button
                      className="btn btn_details_pro"
                      onClick={handleOnClickDetails}
                    >
                      Details{" "}
                      <span className="icon_right_project">
                        {" "}
                        <MdOutlineKeyboardArrowRight />{" "}
                      </span>
                      <i></i>
                    </button> */}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default Projectcard
