import React, { useState } from "react";

function Inputform(props) {
  const {
    icon,
    label,
    type,
    placeholder,
    Value,
    onChangeText,
    Validate,
    CustomErrorLine,
    Hint,
    multiline,
    editable = true,
    passwordVisibleIcon,
    customPasswordValidation,
    customEmailValidation,
    customPhoneValidation,
    inputName,
    ...input
  } = props;

  const [name, setName] = useState({
    value: Value ? Value : "",
    error: false,
    success: false,
  });

  const [error, setError] = useState("");
  const [toggleShowPassword, setToggleShowPassword] = useState(false);
  const [textType, setTextType] = useState(type);
  const [showTooltip, setShowToolTip] = useState(false);
  console.log("Tyep#########################");
  console.log(type);
  //   const nameCustomeLineError =
  //   Strings["component.FormTextInputNameCustomErrorLine"],
  // fieldCustomeLineError =
  //   Strings["component.FormTextInputFieldCustomErrorLine"],
  // emailCustomeLineError =
  //   Strings["component.FormTextInputEmailCustomErrorLine"],
  // numberemptyCustomeLineError =
  //   Strings["component.FormTextInputNumberCustomErrorLine"],
  // numberinvalidCustomLineError =
  //   Strings["component.FormTextInputNumberinvalidCustomErrorLine"],
  // passwordinvalidCustomLineError =
  //   Strings["component.FormTextInputPasswordinvalidCustomErrorLine"];

  const handleChangeText = (e) => {
    
    const data = e.target.value;
    // console.log("first", data);
    if (Validate && editable) {
      if (type === "text") {
        if (data.length === 0) {
          // console.log("--olenthhdjw");
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        } else {
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      } else if (type === "alpha") {
        const aplhaRegex = /^[0-9a-zA-Z]+$/;
        // console.log(aplhaRegex.test(data));
        // console.log(data.length === 0 && !aplhaRegex.test(data));
        if (data.length === 0 && !aplhaRegex.test(data)) {
          const value = {
            value: data,
            success: false,
          };
          console.log("value-->true", value);
          onChangeText(value);
          setName(value);
        } else {
          const value = {
            value: data,
            success: true,
          };
          console.log("value-->false", value);
          onChangeText(value);
          setName(value);
        }
      } else if (type === "textarea") {
        // console.log("clicked text area");
        if (data.length === 0) {
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        } else {
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      } else if (type === "email") {
        const emailRe =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailTest = customEmailValidation
          ? customEmailValidation
          : emailRe;
        if (!emailTest.test(String(data).toLowerCase())) {
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      } else if (type === "number") {
        if (data.length === 0) {
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        } else {
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      } else if (type === "phone") {
        const phonenoRe = /^(\+91[-\s]?)?[0]?(91)?[789]\d{9}$/;
        const phoneNo = customPhoneValidation
          ? customPhoneValidation
          : phonenoRe;
        if (!phoneNo.test(data)) {
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      } else if (type === "password") {
        const password =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const validationLogic = customPasswordValidation
          ? customPasswordValidation
          : password;
        if (!validationLogic.test(data)) {
          const value = {
            value: data,
            success: false,
          };
          onChangeText(value);
          setName(value);
        } else {
          setError("");
          const value = {
            value: data,
            success: true,
          };
          onChangeText(value);
          setName(value);
        }
      }
    } else if (!Validate && editable) {
      setError("");
      // console.log("----->hhh");
      const value = {
        value: data,
        error: true,
        success: true,
      };
      // console.log(value);
      onChangeText(value);
      setName(value);
    }
  };

  const handleBlurText = (e) => {
    const data = e.target.value;

    if (Validate && editable) {
      if (textType === "text") {
        if (data.length === 0) {
          setError(
            CustomErrorLine ? CustomErrorLine : ""

            // nameCustomeLineError
          );
          const value = {
            ...name,
            error: true,
            success: false,
          };
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          onChangeText(value);
        }
      } else if (textType === "alpha") {
        const aplhaRegex = /^[a-z\d\-_\s]+$/i;
        if (!aplhaRegex.test(data)) {
          setError(
            CustomErrorLine ? CustomErrorLine : ""

            // fieldCustomeLineError
          );
          const value = {
            ...name,
            error: true,
            success: false,
          };
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          onChangeText(value);
        }
      } else if (textType === "textarea") {
        if (data.length === 0) {
          setError(
            CustomErrorLine ? CustomErrorLine : ""

            // fieldCustomeLineError
          );
          const value = {
            ...name,
            error: true,
            success: false,
          };
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          onChangeText(value);
        }
      } else if (textType === "email") {
        const emailRe =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailTest = customEmailValidation
          ? customEmailValidation
          : emailRe;
        if (
          !emailTest.test

          // (String(data).toLowerCase())
        ) {
          setError(
            CustomErrorLine ? CustomErrorLine : ""

            // emailCustomeLineError
          );
          const value = {
            ...name,
            error: true,
            success: false,
          };
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          onChangeText(value);
        }
      } else if (textType === "number") {
        if (data.length === 0) {
          setError(
            CustomErrorLine ? CustomErrorLine : ""

            // numberemptyCustomeLineError
          );
          const value = {
            ...name,
            error: true,
            success: false,
          };
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          onChangeText(value);
        }
      } else if (textType === "phone") {
        const phonenoRe = /^(\+91[-\s]?)?[0]?(91)?[789]\d{9}$/;
        const phoneNo = customPhoneValidation
          ? customPhoneValidation
          : phonenoRe;
        if (!phoneNo.test(data)) {
          setError(
            CustomErrorLine ? CustomErrorLine : ""
            //  numberinvalidCustomLineError
          );
          const value = {
            ...name,
            error: true,
            success: false,
          };
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          onChangeText(value);
        }
      } else if (textType === "password") {
        const password =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const validationLogic = customPasswordValidation
          ? customPasswordValidation
          : password;
        if (!validationLogic.test(data)) {
          setError(
            CustomErrorLine ? CustomErrorLine : ""

            // passwordinvalidCustomLineError
          );
          const value = {
            ...name,
            error: true,
            success: false,
          };
          onChangeText(value);
        } else {
          setError("");
          const value = {
            ...name,
            error: false,
            success: true,
          };
          onChangeText(value);
        }
      }
    } else if (!Validate && editable) {
      setError("");

      const value = {
        value: data,
        error: false,
        success: true,
      };
      onChangeText(value);
    }
  };

  const handleVisible = () => {
    setToggleShowPassword(!toggleShowPassword);
    setTextType("text");
    if (textType === "text") {
      setTextType("password");
    }
  };

  const handleClick = () => {
    setShowToolTip(!showTooltip);
  };
  // console.log("name.value", name.value);
  return (
    <div>
      <div>{label && <label>{label}</label>}</div>
      <div>
        {icon ? icon : null}
        {multiline && (type === "text" || type === "alpha") ? (
          <textarea
            {...input}
            type={type}
            name={inputName}
            placeholder={placeholder}
            value={Value ? Value : name.value}
            onChange={handleChangeText}
            onBlur={handleBlurText}
            readOnly={!editable}
          ></textarea>
        ) : (
          <div className="form-group">
          <input
          className="form-control from_inut"
            {...input}
            // inputMode={"numeric"}
            type={
              textType === "phone"
                ? "tel"
                : textType === "alpha"
                ? "text"
                : textType
            }
            placeholder={placeholder}
            value={Value ? Value : name.value}
            onChange={handleChangeText}
            onBlur={handleBlurText}
            onClick={handleClick}
            readOnly={!editable}
            // data-toggle="tooltip"
            // data-placement="top"
            // title={Hint ? Hint : ""}
          />
          </div>
        )}

        {type === "password" &&
          passwordVisibleIcon &&
          (textType === "password" ? (
            <div>
              {/* <VisibilityIcon
               onClick={handleVisible} /> */}
            </div>
          ) : (
            <div>{/* <VisibilityOffIcon onClick={handleVisible} /> */}</div>
          ))}
      </div>
      {error && Hint && <p>{Hint}</p>}
      {error && (
        <div className="clearFix">
          <div className="float-right">{error ? error : CustomErrorLine}</div>
        </div>
      )}
    </div>
  );
}

export default Inputform;
