import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Footer from '../../components/appSpecific/Footer'
import Header2 from '../../components/appSpecific/header2'
import Sidebar from '../../components/appSpecific/Sidebar'
import { Markup } from 'interweave'
import { languages } from '../../components/appSpecific/LanguageCode'
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import axios from 'axios'
import moment from 'moment/moment'

function Details() {
  const [data3, setData3] = useState()
  const [data4, setData4] = useState()
  const [alldata2, setAlldata2] = useState([])
  const [data5, setData5] = useState([])
  const location = useLocation()
  const val = location.state

  console.log(val, 'dhfg')
  const id = location.state.id
  // console.log('gggggggggggggggg',id);
  const obj1 = localStorage.getItem('token')
  const token = JSON.parse(obj1)

  //
  // useEffect(() => {
  //   axios
  //   .post(
  //       `https://api.bfa-platform.com/api/project/all-grid2`,
  //       bodyData,
  //       config
  //   )
  //   .then((res) => {

  //     console.log("inside hook ", res.data.data);

  //     setBossData(res.data.data)

  //   })
  //   .catch((e) => {
  //       console.log("Error->", e.response);
  //   });
  // },[])

  const detailsData = () => {
    axios
      .get(`https://api.bfa-platform.com/api/investments/show/${id}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        // console.log('details ------------> data investment', res.data)
        setData3(res.data.data.project_description)
        setAlldata2(res.data.data)
        console.log(res)
        // console.log('>>>>>>>>>>>>>> hello details', alldata2)
      })
      .catch((e) => {
        console.log('Error--->', e.response.data)
      })
  }

  const detailsData2 = () => {
    axios
      .get(`https://api.bfa-platform.com/api/prix/show/${id}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        console.log('details ------------> data investment', data5)
        setData4(res.data.data.price_designation)
        setData5(res.data.data)
      })
      .catch((e) => {
        console.log('Error--->', e.response.data)
      })
  }

  React.useEffect(() => {
    detailsData()
    detailsData2()
  }, [])

  //laguage Translator
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff counter2')
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage, t])

  return (
    <>
      <div class="wrapper">
        <Sidebar />
        <div class="main">
          <Header2 />
          <main class="content">
            <div class="container-fluid p-0">
              <div class="row">
                <div class="col-12 col-xl-8 mb-4">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0">
                      <li class="breadcrumb-item">
                        <NavLink to="/dashboard">{t('Home')}</NavLink>
                      </li>
                      <li class="breadcrumb-item">
                        <NavLink to={val.routpath}>{t(val.type)}</NavLink>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        {val.type === 'Unit-Prices'
                          ? val.designation.split(' ', 10).join(' ')
                          : val.Phase.split(' ', 10).join(' ')}{' '}
                        ...
                      </li>

                      {/* <li class="breadcrumb-item active" aria-current="page">
                          {all.price_designation ? all.price_designation.split(" ",10).join(" ") : ""}
                     </li> */}
                      
                    </ol>
                  </nav>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-8 col-xxl-8 ">
                  <div class="card min-vh-100">
                    <div class="card-header pt-4 mb-0">
                      <h5 class="card-title mb-3">
                        {val.type === 'Unit-Prices'
                          ? val.designation.split(' ', 10).join(' ')
                          : val.Phase
                          ? val.Phase
                          : val.val2}
                      </h5>
                      {val.type === 'Projects' && (
                        <div class="badge country_pill bg-warning mr-3">
                          {' '}
                          {val.countrey}
                        </div>
                      )}
                      {val.type === 'Tenders' && (
                        <div class="badge country_pill bg-warning mr-3">
                          {' '}
                          {val.countrey}
                        </div>
                      )}

                      <div class="badge status_pill bg-info mr-3">
                        {' '}
                        {val.status}
                      </div>

                      {val.type === 'Tenders' && (
                        <>
                          <div class="badge country_pill bg-success mr-3">
                            {' '}
                            {moment(val.val5).format('DD/MM/YYYY')}
                          </div>

                          {moment(val.val5).diff(moment(), 'days') >= 0 && (
                            <div class="badge bg-success country_pill">
                              {moment(val.val5).diff(moment(), 'days') + 1} {t("days remaining")}
                            </div>
                          )}
                        </>
                      )}

                      {val.type === 'Jobs' && (
                        <>
                          <div class="badge country_pill bg-success">
                            {' '}
                            {val.val4}
                          </div>

                          {moment(val.val4).diff(moment(), 'days') >= 0 && (
                            <div class="badge bg-success country_pill ml-3">
                              {moment(val.val4).diff(moment(), 'days') + 1} {t("days remaining")}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div class="card-body pt-0">
                      <h4>{t(val.heading)}</h4>
                      <Markup content={val.descripition} />
                      <p class="text-muted">
                        <Markup content={val.summary} />
                      </p>{' '}
                      <p class="text-muted">
                        <Markup content={data3} />
                      </p>
                      <p class="text-muted">
                        <Markup content={data4} />
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-xxl-4">
                  <div class="card mb-4 h-100">
                    <div class="card-header pt-4">
                      <h5 class="card-title mb-0"> {t('Basic Data :')} </h5>
                    </div>
                    <div class="card-body">
                      <div class="row"></div>
                      <dl class="row mb-1">
                        {val.type === 'Projects' && (
                          <>
                            <dt class="col-6 col-xxl-6">
                              {' '}
                              <p>{t('Status :')}</p>
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <span class="badge status_pill bg-info mb-1">
                                {val.status}
                              </span>
                            </dd>
                          </>
                        )}
                        {val.type === 'Tenders' && (
                          <>
                            <dt class="col-6 col-xxl-6">
                              <p>{t('Project :')}</p>
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.project == null ? 'N/A' : val.project}
                              </p>
                            </dd>
                          </>
                        )}
                        {val.type === 'Projects' && (
                          <>
                            <dt class="col-6 col-xxl-6">
                              <p>{t('Project :')}</p>
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.Phase == null ? 'N/A' : val.Phase}
                              </p>
                            </dd>

                            <dt class="col-6 col-xxl-6"> {'Continent :'}</dt>

                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.continent == null ? 'N/A' : val.continent}
                              </p>
                            </dd>

                            <dt class="col-6 col-xxl-6">{t('Country :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.countrey == null ? 'N/A' : val.countrey}
                              </p>
                            </dd>

                            <dt class="col-6 col-xxl-6">{t('Sector :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.val1 == null ? 'N/A' : val.val1}
                              </p>
                            </dd>

                            <dt class="col-6 col-xxl-6">{t('Montant ($) :')}</dt>
                          <dd class="col-6 col-xxl-6">
                            <p class="mb-1">
                            ${val.val3 == null ? 'N/A' : val.val3}
                            </p>
                            </dd>
                            

                          </>
                        )}

                        {val.type === 'Tenders' && (
                          <>
                            <dt class="col-6 col-xxl-6">
                              <p>{'Continent :'}</p>{' '}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.continent == null ? 'N/A' : val.continent}
                              </p>
                            </dd>
                          </>
                        )}
                        {/* 
                        {val.type !== 'Investments' && (
                          <>
                            <dt class="col-6 col-xxl-6">{t('Country :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.countrey == null ? 'N/A' : val.countrey}
                              </p>
                            </dd>
                          </>
                        )} */}

                        {/* {val.type !== "Unit-Prices" && (
                          <>
                            <dt class="col-6 col-xxl-6">{t("Sector :")}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">{val.val1}</p>
                            </dd>
                          </>
                        )} */}

                        {val.type === 'Tenders' && (
                          <>
                            <dt class="col-6 col-xxl-6">{t('Country :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.countrey == null ? 'N/A' : val.countrey}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">{t('Sector :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.val2 == null ? 'N/A' : val.val2}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">{t('Donor :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.donor == null ? 'N/A' : val.donor}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">{t('Cost :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.cost == null ? 'N/A' : val.cost}
                              </p>
                            </dd>
                          </>
                        )}

                        {val.type === 'Investments' && (
                          <>
                            <dt class="col-6 col-xxl-6">
                              {t('Date of creation :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {moment(alldata2.date_event).format(
                                  'DD/MM/YYYY',
                                ) == null
                                  ? 'N/A'
                                  : moment(alldata2.date_event).format(
                                      'DD/MM/YYYY',
                                    )}
                              </p>
                            </dd>

                            <dt class="col-6 col-xxl-6">{t('Total Project Value ($) :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.val1 == null ? 'N/A' : val.val1}
                              </p>
                            </dd>

                            <dt class="col-6 col-xxl-6">
                              {t('Financial gap ($): ')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.val4 == null ? 'N/A' : val.val4}
                              </p>
                            </dd>

                            {/* <dt class="col-6 col-xxl-6">
                              {t('Date of Event :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {alldata2.date_event == null
                                  ? 'N/A'
                                  : alldata2.date_event}
                              </p>
                            </dd> */}

                            {/* <dt class="col-6 col-xxl-6">
                              {t('Type of Project :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">N/A</p>
                            </dd> */}
                            <dt class="col-6 col-xxl-6">
                              {t('Stage of the Project :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.project_stage == null
                                  ? 'N/A'
                                  : val.project_stage}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">
                              {t('Implementation schedule :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {alldata2.project_time_frame == null
                                  ? 'N/A'
                                  : alldata2.project_time_frame}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">
                              {t('Project Classification(Size) :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.status == null ? 'N/A' : val.status}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">
                              {t('Project readiness indicators :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.val3 == null ? 'N/A' : val.val3}
                                {/* {moment(val.val3).format('DD/MM/YYYY') == null ? 'N/A' : moment(val.val3).format('DD/MM/YYYY')} */}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">
                              {t('Number of jobs created :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.noofjob == null ? 'N/A' : val.noofjob}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">
                              {t('Number of direct beneficiaries :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {alldata2.direct_number_of_beneficiaries == null
                                  ? 'N/A'
                                  : alldata2.direct_number_of_beneficiaries}
                              </p>
                            </dd>

                            {/* --------------------- */}
                            <hr />
                            <div class="card-header">
                              <h5 class="card-title mb-0">{t('Funding :')}</h5>
                            </div>
                            <div class="card-body">
                              <dl class="row mb-1">
                                <dt class="col-6 col-xxl-6">
                                  {t('Total Project Value :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">
                                    {val.val1 == null ? 'N/A' : val.val1}
                                  </p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Amount of Debt :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">
                                    {alldata2.debt == null
                                      ? 'N/A'
                                      : alldata2.debt}
                                  </p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Amount of Donation :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{'N/A'}</p>
                                </dd>
                                <dt class="col-6 col-xxl-6">{t('Equity :')}</dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{'N/A'}</p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Risk mitigation :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">
                                    {alldata2.risk_mitigation == null
                                      ? 'N/A'
                                      : alldata2.risk_mitigation}
                                  </p>
                                </dd>
                                {/* <dt class="col-6 col-xxl-6">
                                  {t("Internal rate of return-IRR(%) :")}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{ "N/A"}</p>
                                </dd> */}
                                <dt class="col-6 col-xxl-6">
                                  {t('Guaranties :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{'N/A'}</p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Gap in term of Debt :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">
                                    {alldata2.gap_of_which_debt == null
                                      ? 'N/A'
                                      : alldata2.gap_of_which_debt}
                                  </p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Gap en term d Equity :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">
                                    {alldata2.gap_of_which_equity == null
                                      ? 'N/A'
                                      : alldata2.gap_of_which_equity}
                                  </p>
                                </dd>
                                {/* <dt class="col-6 col-xxl-6">
                                  {t(
                                    'Planned schedule of bussiness operation :',
                                  )}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{'N/A'}</p>
                                </dd> */}
                              </dl>
                            </div>

                            {/* -------------------------- */}
                            <hr />
                            <div class="card-header">
                              <h5 class="card-title mb-0">{t('Stakholders :')}</h5>
                            </div>
                            <div class="card-body">
                              <dl class="row mb-1">
                                <dt class="col-6 col-xxl-6">
                                  {t('Project sponsor :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">
                                    {val.val2 == null ? 'N/A' : val.val2}
                                  </p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Current Investor :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">
                                    {val.val2 == null ? 'N/A' : val.val2}
                                  </p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Other key stakeholders :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{val.val2}</p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Lenders :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{val.val2}</p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Investors :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{val.val2}</p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Special Purpose Vehicle :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{val.val2}</p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Granting authority :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{val.val2}</p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Off-taker/Buyer :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{val.val2}</p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Guarantee providers :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{val.val2}</p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Developers/Operators :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{val.val2}</p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Transaction advisors :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{val.val2}</p>
                                </dd>
                                <dt class="col-6 col-xxl-6">
                                  {t('Technical assistance providers :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{val.val2}</p>
                                </dd>
                              </dl>
                            </div>
                          </>
                        )}

                        {val.type === 'Jobs' && (
                          <>
                            <dt class="col-6 col-xxl-6"> {t('Job title :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.Phase == null ? 'N/A' : val.Phase}
                              </p>
                            </dd>

                            <dt class="col-6 col-xxl-6">{t('Country :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">Multinational</p>
                            </dd>

                            <dt class="col-6 col-xxl-6">
                              {t('Organisation :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">{val.val1}</p>
                            </dd>

                            <dt class="col-6 col-xxl-6">
                              {t('Publication Date :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.val3 == null ? 'N/A' : val.val3}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">
                              {t('Date of Submission :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {moment(val.val4).format('DD/MM/YYYY') == null
                                  ? 'N/A'
                                  : moment(val.val4).format('DD/MM/YYYY')}
                              </p>
                            </dd>

                            {moment(val.val4).diff(moment(), 'days') >= 0 && (
                              <>
                                <dt class="col-6 col-xxl-6">
                                  {t('Days Left :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <div class="">
                                    {moment(val.val4).diff(moment(), 'days') +
                                      1}
                                  </div>
                                </dd>
                              </>
                            )}

                            {/* <dt class="col-6 col-xxl-6">{t('Continent :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">{'N/A'}</p>
                            </dd> */}
                            <dt class="col-6 col-xxl-6">{t('Sector :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.sector == null ? 'N/A' : val.sector}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">{t('Type :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.status == null ? 'N/A' : val.status}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">{t('Experience :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.experiencee == null
                                  ? 'N/A'
                                  : val.experiencee}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">{t('Location :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.val2 == null ? 'N/A' : val.val2}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">{t('Link :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {/* {val.liimk == null ? 'N/A' : val.liimk} */}

                                <Link to={val.liimk}><div class="badge bg-info status_pill">Link</div></Link>
                              </p>
                            </dd>
                          </>
                        )}

                        {/* {val.type !== 'Unit-Prices' ||
                            ('investment' && (
                              <>
                                <dt class="col-6 col-xxl-6">{val.detail10}</dt>
                                <dd class="col-6 col-xxl-6">
                                  <p class="mb-1">{val.val5}</p>
                                </dd>
                              </>
                            ))} */}
                        {val.type === 'Tenders' && (
                          <>
                            <dt class="col-6 col-xxl-6">
                              {t('Launch Date :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {moment(val.val8).format('DD/MM/YYYY') == null
                                  ? 'N/A'
                                  : moment(val.val8).format('DD/MM/YYYY')}
                              </p>
                            </dd>

                            <dt class="col-6 col-xxl-6">{t('Deadline :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {moment(val.val5).format('DD/MM/YYYY')}
                              </p>
                            </dd>
                            {moment(val.val5).diff(moment(), 'days') >= 0 && (
                              <>
                                <dt class="col-6 col-xxl-6">
                                  {t('Days Left :')}
                                </dt>
                                <dd class="col-6 col-xxl-6">
                                  <div class="">
                                    {moment(val.val5).diff(moment(), 'days') +
                                      1}
                                  </div>
                                </dd>
                              </>
                            )}
                          </>
                        )}

                        {val.type === 'Unit-Prices' && (
                          <>
                            {/* <dt class="col-6 col-xxl-6">{val.detail23}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">{val.val2}</p>
                            </dd> */}

                            <dt class="col-6 col-xxl-6">{t('Country :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {data5?.country?.name == null
                                  ? 'N/A'
                                  : data5?.country?.name}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">{t('City :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">{data5?.city?.name}</p>
                            </dd>

                            <dt class="col-6 col-xxl-6">{t('Minimum :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {data5.val5 ? data5.val5 : 'N/A'}
                              </p>
                            </dd>

                            <dt class="col-6 col-xxl-6">{t('Average :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {data5.middle_price
                                  ? data5.middle_price
                                  : 'N/A'}
                              </p>
                            </dd>

                            <dt class="col-6 col-xxl-6">{t('Maximum :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">{val.val3 ? val.val3 : 'N/A'}</p>
                            </dd>

                            <dt class="col-6 col-xxl-6">{t('Unit :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">{val.val6}</p>
                            </dd>

                            <dt class="col-6 col-xxl-6">{t('Family :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">{data5.family}</p>
                            </dd>
                            <dt class="col-6 col-xxl-6">{t('Sub-family :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">{data5.sub_family}</p>
                            </dd>
                            <dt class="col-6 col-xxl-6">{t('Continent :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {' '}
                                {data5?.continent?.name == null
                                  ? 'N/A'
                                  : data5?.continent?.name}
                              </p>
                            </dd>

                            <dt class="col-6 col-xxl-6">{t('Sector :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">{val.sector}</p>
                            </dd>
                            <dt class="col-6 col-xxl-6">{t('Year :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">{val.year}</p>
                            </dd>
                            <dt class="col-6 col-xxl-6">{val.detail18}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {data5.institution ? data5.institution : 'N/A'}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">{t('Project :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {data5.project_id ? data5.project_id : 'N/A'}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">{t('Source :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">www.maroc.prix-construction</p>
                            </dd>
                          </>
                        )}
                        {val.type === 'Projects' && (
                          <>
                            {/* <dt class="col-6 col-xxl-6 mb-0">{t("Web link :")}</dt>
                            <dd class="col-6 col-xxl-6 mb-0">
                              <p class="mb-0">

                              </p>
                            </dd> */}
                          </>
                        )}
                      </dl>
                    </div>
                    {val.type === 'Projects' && (
                      <>
                        <hr />
                        <div class="card-header">
                          <h5 class="card-title mb-0"> {t('Contact')} </h5>
                        </div>
                        <div class="card-body">
                          <dl class="row mb-0">
                            <dt class="col-6 col-xxl-6">
                              {t('Executive Agency :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.agency ? val.agency : 'N/A'}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">
                              {t('Responsable :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              {val.responsable ? val.responsable : 'N/A'}
                            </dd>

                            {/* <dt class="col-6 col-xxl-6">{t('Address :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.address ? val.address : 'N/A'}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6 mb-0">{t('Email :')}</dt>
                            <dd class="col-6 col-xxl-6 mb-0">
                              <p class="mb-0">
                                {val.emaill ? val.emaill : 'N/A'}
                              </p>
                            </dd> */}

                            {val.type === 'Projects' && (
                              <>
                                <dt class="col-6 col-xxl-6 mb-0">
                                  {t('Link :')}
                                </dt>
                                <dd class="col-6 col-xxl-6 mb-0">
                                  <p class="mb-0">
                                    {val.link ? val.link : 'N/A'}
                                  </p>
                                </dd>
                              </>
                            )}
                          </dl>
                        </div>
                      </>
                    )}

                    {val.type === 'Tenders' && (
                      <>
                        <hr />
                        <div class="card-header">
                          <h5 class="card-title mb-0"> {t('Contacts')} </h5>
                        </div>
                        <div class="card-body">
                          <dl class="row mb-1">
                            <dt class="col-6 col-xxl-6">
                              {t('Executive Agency :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.agency ? val.agency : 'N/A'}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6">
                              {t('Responsable :')}
                            </dt>
                            <dd class="col-6 col-xxl-6">
                              {val.responsable ? val.responsable : 'N/A'}
                            </dd>

                            <dt class="col-6 col-xxl-6">{t('Address :')}</dt>
                            <dd class="col-6 col-xxl-6">
                              <p class="mb-1">
                                {val.address ? val.address : 'N/A'}
                              </p>
                            </dd>
                            <dt class="col-6 col-xxl-6 mb-0">{t('Email :')}</dt>
                            <dd class="col-6 col-xxl-6 mb-0">
                              <p class="mb-0">
                                {val.emaill ? val.emaill : 'N/A'}
                              </p>
                            </dd>

                            {val.type === 'Projects' && (
                              <>
                                <dt class="col-6 col-xxl-6 mb-0">
                                  {t('Link :')}
                                </dt>
                                <dd class="col-6 col-xxl-6 mb-0">
                                  <p class="mb-0">
                                    {val.link ? val.link : 'N/A'}
                                  </p>
                                </dd>
                              </>
                            )}
                          </dl>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Details
