import React,{useEffect} from "react";
import Header2 from "../../components/appSpecific/header2";
import Sidebar from "../../components/appSpecific/Sidebar";
import Footer from "../../components/appSpecific/Footer";
import Projectcard from "../../components/common/Projectcard";
import Chartboard from "../ChartBoard/Chartboard";
import { useTranslation } from 'react-i18next'; 
import cookies from 'js-cookie'; 
import { languages } from "../../components/appSpecific/LanguageCode";

function Bussinessadvisors() {
 //laguage Translator
 const currentLanguageCode = cookies.get('i18next') || 'en'
 const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
 const { t } = useTranslation()
 
   useEffect(() => {
   console.log('Setting page stuff counter2')
   document.body.dir = currentLanguage.dir || 'ltr'
 }, [currentLanguage, t])
  return (
    <>
      <div class="wrapper">
        <Sidebar />
        <div class="main">
          <Header2 />
          <main class="content">
            <div class="container-fluid p-0">
              <div class="row">
                <div class="col-12 col-xl-8 mb-4 ">
                  <h3 class="font-weight-bold">{t('Business_Advisors')}</h3>
                  <h6 class="font-weight-normal mb-0">
                    {" "}
                    You have <span class="text-primary"> 15 business advisors !</span>
                  </h6>
                </div>
              </div>
              <div class="row">
                <Projectcard
                  countrey="Africa"
                  status="Good"
                  Phase="Programme to open up agriculture and mining areas-phase 1 (pdzam-1)"
                  val1="Agriculture"
                  val2=" Banque africaine de developpement"
                  val3="  $ 142450000"
                  row1="Sector"
                  row2="Donor"
                  row3="Amount"
                  routpath="/bussinessadvisors"
                />
                <Projectcard
                  countrey="Africa"
                  status="Good"
                  Phase="Programme to open up agriculture and mining areas-phase 1 (pdzam-1)"
                  val1="Agriculture"
                  val2=" Banque africaine de developpement"
                  val3="  $ 142450000"
                  row1="Sector"
                  row2="Donor"
                  row3="Amount"
                />
                <Projectcard
                  countrey="Africa"
                  status="Good"
                  Phase="Programme to open up agriculture and mining areas-phase 1 (pdzam-1)"
                  val1="Agriculture"
                  val2=" Banque africaine de developpement"
                  val3="  $ 142450000"
                  row1="Sector"
                  row2="Donor"
                  row3="Amount"
                />
                <Projectcard
                  countrey="Africa"
                  status="Good"
                  Phase="Programme to open up agriculture and mining areas-phase 1 (pdzam-1)"
                  val1="Agriculture"
                  val2=" Banque africaine de developpement"
                  val3="  $ 142450000"
                  row1="Sector"
                  row2="Donor"
                  row3="Amount"
                />
                <Projectcard
                  countrey="Africa"
                  status="Good"
                  Phase="Programme to open up agriculture and mining areas-phase 1 (pdzam-1)"
                  val1="Agriculture"
                  val2=" Banque africaine de developpement"
                  val3="  $ 142450000"
                  row1="Sector"
                  row2="Donor"
                  row3="Amount"
                />
                <Projectcard
                  countrey="Africa"
                  status="Good"
                  Phase="Programme to open up agriculture and mining areas-phase 1 (pdzam-1)"
                  val1="Agriculture"
                  val2=" Banque africaine de developpement"
                  val3="  $ 142450000"
                  row1="Sector"
                  row2="Donor"
                  row3="Amount"
                />
                <Projectcard
                  countrey="Africa"
                  status="Good"
                  Phase="Programme to open up agriculture and mining areas-phase 1 (pdzam-1)"
                  val1="Agriculture"
                  val2=" Banque africaine de developpement"
                  val3="  $ 142450000"
                  row1="Sector"
                  row2="Donor"
                  row3="Amount"
                />
                <Projectcard
                  countrey="Africa"
                  status="Good"
                  Phase="Programme to open up agriculture and mining areas-phase 1 (pdzam-1)"
                  val1="Agriculture"
                  val2=" Banque africaine de developpement"
                  val3="  $ 142450000"
                  row1="Sector"
                  row2="Donor"
                  row3="Amount"
                />
                <Projectcard
                  countrey="Africa"
                  status="Good"
                  Phase="Programme to open up agriculture and mining areas-phase 1 (pdzam-1)"
                  val1="Agriculture"
                  val2=" Banque africaine de developpement"
                  val3="  $ 142450000"
                  row1="Sector"
                  row2="Donor"
                  row3="Amount"
                />
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-5">
                  <div
                    class="dataTables_info"
                    id="datatables-buttons_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing 1 to 9 of 15 entries
                  </div>
                </div>
                <div class="col-sm-12 col-md-7">
                  <div
                    class="dataTables_paginate paging_simple_numbers"
                    id="datatables-buttons_paginate"
                  >
                    <ul class="pagination mb-0 justify-content-end">
                      <li
                        class="paginate_button page-item previous disabled"
                        id="datatables-buttons_previous"
                      >
                        <a
                          href="#"
                          aria-controls="datatables-buttons"
                          data-dt-idx="0"
                          tabindex="0"
                          class="page-link"
                        >
                          Previous
                        </a>
                      </li>
                      <li class="paginate_button page-item active">
                        <a
                          href="#"
                          aria-controls="datatables-buttons"
                          data-dt-idx="1"
                          tabindex="0"
                          class="page-link"
                        >
                          1
                        </a>
                      </li>
                      <li class="paginate_button page-item ">
                        <a
                          href="#"
                          aria-controls="datatables-buttons"
                          data-dt-idx="2"
                          tabindex="0"
                          class="page-link"
                        >
                          2
                        </a>
                      </li>
                      <li class="paginate_button page-item ">
                        <a
                          href="#"
                          aria-controls="datatables-buttons"
                          data-dt-idx="3"
                          tabindex="0"
                          class="page-link"
                        >
                          3
                        </a>
                      </li>
                      <li class="paginate_button page-item ">
                        <a
                          href="#"
                          aria-controls="datatables-buttons"
                          data-dt-idx="4"
                          tabindex="0"
                          class="page-link"
                        >
                          4
                        </a>
                      </li>
                      <li class="paginate_button page-item ">
                        <a
                          href="#"
                          aria-controls="datatables-buttons"
                          data-dt-idx="5"
                          tabindex="0"
                          class="page-link"
                        >
                          5
                        </a>
                      </li>
                      <li class="paginate_button page-item ">
                        <a
                          href="#"
                          aria-controls="datatables-buttons"
                          data-dt-idx="6"
                          tabindex="0"
                          class="page-link"
                        >
                          6
                        </a>
                      </li>
                      <li
                        class="paginate_button page-item next"
                        id="datatables-buttons_next"
                      >
                        <a
                          href="#"
                          aria-controls="datatables-buttons"
                          data-dt-idx="7"
                          tabindex="0"
                          class="page-link"
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Chartboard/>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Bussinessadvisors;
