import React, { useState, useEffect } from 'react'
import Header from '../../components/appSpecific/header'
import registerMale from '../../assets/img/register-male.svg'
import registerFemale from '../../assets/img/register-female.svg'
import { NavLink, useNavigate } from 'react-router-dom'
import Inputform from '../../components/common/Inputform'
import Buttonform from '../../components/common/Buttonform'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { languages } from '../../components/appSpecific/LanguageCode'

function Register() {
  const [fname, setFName] = useState()
  const [lname, setLName] = useState()
  const [email, setEmail] = useState()
  const [pass, setPass] = useState()
  const [cpass, setCPass] = useState()

  const registerNavigate = useNavigate()
  const handleOnregister = (e) => {
    e.preventDefault()
    const user = {
      first_name: fname,
      last_name: lname,
      email: email,
      account_type_id: 1,
      account_sub_type_id: 1,
      nationality_id: '',
      country_id: '',
      city_id: '',
      gender: 'male',
      home_phone: '',
      org_societe: '',
      org_country_id: '',
      org_city_id: '',
      org_email: '',
      phone: '',
      countries: '',
      sectors: '',
      specialities: '',
      password: pass,
      password_confirmation: cpass,
    }
    axios
      .post('https://api.bfa-platform.com/api/register', user)
      .then((res) => {
        // console.log("Register Successfully", res.data);
        registerNavigate('/')
      })
      .catch((e) => {
        console.log('Error---->', e.response.data)
      })
  }

  //laguage Translator
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff counter2')
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage, t])

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="col-md-4 d-none d-md-flex bg-image justify-content-center">
            <img id="myPicture" className="w-75" src={registerMale} />
          </div>
          <div className="col-md-4">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 col-xl-10 mx-auto">
                    <div className="card register_card">
                      <h3 className="signin_title">{t('Register')}</h3>
                      <div className="create_an_account">
                        <strong>{t('Have an account ?')}</strong>
                        <NavLink to="/signin">{t('Sign in')}</NavLink>
                      </div>
                      <form id="form">
                        <div className="form-group">
                          <Inputform
                            type="text"
                            placeholder={t('First name')}
                            Value={fname}
                            onChangeText={(val) => setFName(val.value)}
                          />
                        </div>
                        <div className="form-group">
                          <Inputform
                            type="text"
                            placeholder={t('Last name')}
                            Value={lname}
                            onChangeText={(val) => setLName(val.value)}
                          />
                        </div>

                        <div className="form-group">
                          <Inputform
                            type="text"
                            placeholder={t('Email')}
                            Value={email}
                            onChangeText={(val) => setEmail(val.value)}
                          />
                        </div>
                        <div className="form-group form_password">
                          <Inputform
                            type="password"
                            placeholder={t('Password')}
                            Value={cpass}
                            onChangeText={(val) => setCPass(val.value)}
                          />
                        </div>

                        <div className="form-group">
                          <Inputform
                            type="password"
                            placeholder={t('Confirm Password')}
                            Value={pass}
                            onChangeText={(val) => setPass(val.value)}
                          />
                        </div>

                        <div className="row ">
                          <div className="col-12 col-xl-12">
                            <Buttonform
                              type="submit"
                              buttonName={t('Register')}
                              onClick={handleOnregister}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-none d-md-flex bg-image justify-content-center">
            <img id="myPicture" className="w-75" src={registerFemale} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Register
