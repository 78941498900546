import { useEffect, useState } from 'react'
import axios from 'axios'

export const useInvestmentSearch = () => {
  const [newInvestmentData, setNewInvestmentData] = useState([])
  const [newLoading, setNewLoading] = useState(false)
  const [newTotalDataCount, setNewTotalDataCount] = useState(0)
  const [countryList, setCountryList] = useState([])
  const [sectorList, setSectorList] = useState([])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const obj1 = localStorage.getItem('token')
  const token = JSON.parse(obj1)
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  }

  useEffect(() => {
    getInvestmentData()
    getCountryList()
    getSectorList()
  }, [])

  const getInvestmentData = (
    search = '',
    country_id = null,
    sector_id = null,
  ) => {
    console.log('country_id ', country_id)
    const bodyData = {
      requireTotalCount: true,
      searchOperation: 'contains',
      searchValue: null,
      userData: {},
      sort: null,
    }

    if (!search && !country_id && !sector_id) {
      bodyData.skip = perPage * (page - 1)
      bodyData.take = perPage
    }

    bodyData.filter = []
    if (search && search !== '') {
      //bodyData.filter = filter;
      bodyData.filter.push(
        ['project_readiness_indicators', 'contains', `${search}`],
        'or',
        ['project_name', 'contains', `${search}`],
      )
    }

    if (country_id) {
      bodyData.filter.push(['country_id', '=', country_id])
    }

    if (sector_id) {
      bodyData.filter.push(['sector_id', '=', sector_id])
    }

    setNewLoading(true)
    axios
      .post(
        `https://api.bfa-platform.com/api/investments/all-grid2`,
        bodyData,
        config,
      )
      .then((res) => {
        const { data: { data = [], totalCount } = {} } = res
        console.log('inside hook ', res)
        setNewTotalDataCount(totalCount)
        setNewInvestmentData(data)
        setNewLoading(false)
      })
      .catch((e) => {
        console.log('Error->', e.response)
      })
  }

  const getCountryList = () => {
    axios
      .get('https://api.bfa-platform.com/api/country/all', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        setCountryList(res.data)
      })
      .catch((e) => {
        console.log('Error--->', e.response.data)
      })
  }

  const getSectorList = () => {
    axios
      .post('https://api.bfa-platform.com/api/sector/all-grid', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        setSectorList(res.data.data)
      })
      .catch((e) => {
        console.log('Error--->', e.response.data)
      })
  }

  return {
    newLoading,
    newInvestmentData,
    newTotalDataCount,
    getInvestmentData,
    countryList,
    sectorList,
    page,
    perPage,
    setPage,
    setPerPage,
  }
}
