import React from 'react';
import "../../assets/css/footer.css"
import { NavLink } from 'react-router-dom'

const Footerlanding = () => {
  return (
    <>
<footer className='land_foot'> 
<div className="container">
          <div className='txt_footer'> 
            <div className='row'>
            <div className='col-md-6 text-left'><p> BFA. All Rights Reserved 2021 ©</p></div>
              <div className='col-md-6 text-end'><NavLink to='Privacy' style={{color:"white"}}>Privacy</NavLink></div>
              </div>

</div>
</div>
</footer>
</>
  )
 }

export default Footerlanding; 

