import React, { useEffect, useState } from "react";
// import usFlag from "../../../assets/img/flags/us.png";
// import esFlag from "../../../assets/img/flags/es.png";
// import deFlag from "../../../assets/img/flags/de.png";
// import nlFlag from "../../../assets/img/flags/nl.png";

import { languages } from "../LanguageCode";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import classNames from "classnames";

function Languages() {
  const [flag, setFlag] = useState("english");
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Setting page stuff  counter1");
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);
  console.log(">>>>>>>>>>>>>>>>>>", flag);
    return (
     <>
     
<ul className="d-flex"> 
       {languages.map(({ code, name, flag, flang }) => (
         <li class="nav-item d-flex">
          
          <a class="nav-link " aria-current="page"
          href=""  onClick={() => {
          setFlag(flang);
          i18next.changeLanguage(code);
          }}
          >
          <div className="en_lang" style={{
            backgroundColor: currentLanguageCode === code ? "#F39C12" : "#56aae7",
             }}> 
             <p> {name} </p>
           </div> 
          </a>  
          </li>
          ))}
    </ul>


         {/* <li className="nav-item dropdown">
         <a
          className="nav-flag dropdown-toggle"
          href="#"
          id="languageDropdown"
          data-toggle="dropdown">
          {flag === "english" && <img src={usFlag} alt="English" />}
          {flag === "spanish" && <img src={esFlag} alt="Spanish" />}
          {flag === "dutch" && <img src={nlFlag} alt="Dutch" />}
          {flag === "german" && <img src={deFlag} alt="German" />}
        </a>

       
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="languageDropdown"
        >
            {languages.map(({ code, name, flag, flang }) => (
            <li>
              <a
                href="#"
                className={classNames("dropdown-item", {
                  disabled: currentLanguageCode === code,
                })}
                onClick={() => {
                  setFlag(flang);
                  i18next.changeLanguage(code);
                }}
                style={{
                  color: currentLanguageCode === code ? "#F39C12" : "#566573",
                }}
              >
                {console.log(flag)}
                <img src={flag} width="20" className="align-middle mr-1" />
                <span className="align-middle">{name}</span>
              </a>
            </li>
          ))}
        </div>
      </li> */}
    </>
  );
}

export default Languages;
