import React,{useEffect} from "react";
import { useTranslation } from 'react-i18next'; 
import cookies from 'js-cookie'; 
import { languages } from "../../components/appSpecific/LanguageCode"

function Footer() {


 //laguage Translator
 const currentLanguageCode = cookies.get('i18next') || 'en'
 const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
 const { t } = useTranslation()

  useEffect(() => {
  console.log('Setting page stuff counter2')
  document.body.dir = currentLanguage.dir || 'ltr'
}, [currentLanguage, t]); 


  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row text-muted">
            <div className="col-6 text-left">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a className="text-muted" href="#">
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="text-muted" href="#">
                  {t("Help Center")}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="text-muted" href="#">
                     {t("Privacy")}
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="text-muted" href="#">
                  {t("Terms of Service")}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-6 text-right">
              <p className="mb-0">
                &copy; 2022 -{" "}
                <a href="#" className="text-muted">
                  {t("Business For Africa")}
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
