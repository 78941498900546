import React from "react";

function Checkbox() {
  return (
    <>
      <div className="col-12 col-xl-6">
        <div className="form-group">
          <label className="custom-control custom-checkbox d-block">
            <input type="checkbox" className="custom-control-input" name="" />
            <span className="custom-control-label">Remember </span>
          </label>
        </div>
      </div>
    </>
  );
}

export default Checkbox;
