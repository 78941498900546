import React from 'react'
import { useEffect, useState } from 'react'
import Sidebar from '../../components/appSpecific/Sidebar'
import { NavLink, useLocation } from 'react-router-dom'
import Header2 from '../../components/appSpecific/header2'
import Chartboard from '../ChartBoard/Chartboard'
import Footer from '../../components/appSpecific/Footer'
import '../../assets/css/newscard.css'
import { IoArrowBackCircleSharp } from 'react-icons/io5'
import { Markup } from 'interweave'
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { languages } from '../../components/appSpecific/LanguageCode'
import { FaSquareFull } from 'react-icons/fa'

function Newsdetails(props) {
  const location = useLocation()
  const valnews = location.state
  const source = 'https://api.bfa-platform.com/'

  //laguage Translator
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff counter2')
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage, t])

  return (
    <>
      <div className="wrapper">
        <Sidebar />
        <div className="main">
          <Header2 />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12 col-xl-8 mb-4 ">
                  <h3 className="font-weight-bold">{t('News details')}</h3>
                  <h6 className="font-weight-normal mb-0"></h6>
                </div>
              </div>
              <div className="row">
                <div class="col-lg-9 col-xxl-9 ">
                  <div class="card min-vh-100">
                    <NavLink to="/news">
                      <span className="back_button">
                        <IoArrowBackCircleSharp />
                      </span>
                    </NavLink>
                    <img
                      src={source + valnews.image}
                      height="400"
                      width="100%"
                      alt=""
                      style={{ objectFit: 'cover', objectPosition: 'top' }}
                    />
                    <div class="card-body description_pt">
                      <div className="card_box_content_title">
                        <h5 className="headline_dt">
                          <span className="icon_right_2">
                            <FaSquareFull />
                          </span>{' '}
                          {valnews.title}
                        </h5>
                        <hr className="liner_hr" />
                        <p class="card-text">
                          <Markup content={valnews.content} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Chartboard />
          <Footer />
        </div>
      </div>
    </>
  )
}
export default Newsdetails
