import React from "react";

function Buttonform(props) {
  const {
    buttonName,
    onClick = () => {
      alert("Functionality Not Implemented");
    },
    icon,
    buttonStyle,
    textStyle,
    disable,
    disableButtonStyle,
    disableTextStyle,
    variantType = "contained",
    ...def
  } = props;

  // console.log("props-------", props);

  return (
    <button
      className="btn btn-primary w-100 signinbutton"
      {...def}
      variant={variantType}
      // className="mat-button"
      // anim="ripple"
      onClick={onClick}
      disabled={disable ? true : false}
      style={Object.assign(
        {},
        // Style.buttonStyle,
        variantType === "contained"
          ? ""
          : //  Style.buttonStyleContained
            null,
        buttonStyle,
        // Style.buttonTextStyle,
        variantType === "contained"
          ? ""
          : // Style.buttonTextStyleContained
            null,
        textStyle,
        disable
          ? Object.assign(
              {},
              //  Style.disableButtonStyle,
              disableButtonStyle
            )
          : null,
        disable
          ? Object.assign(
              {},
              //  Style.disableButtonTextStyle,
              disableTextStyle
            )
          : null
      )}
    >
      {" "}
      {icon} {buttonName}{" "}
    </button>
  );
}

export default Buttonform;
