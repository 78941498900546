import React from 'react'; 
import "./loading.css" ; 

const Loading = () => {
  return (
    <>
          <div className='loder_fox'>
          <div class="loader"></div>
          <h5 className='loading_text'> loading...</h5>
          </div> 
         

    </>
  )
}

export default Loading; 