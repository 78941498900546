import React, { useState, useEffect } from 'react'
import Header from '../../components/appSpecific/header'

import layer from '../../assets/img/Layer.svg'
import { NavLink, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Checkbox from '../../components/appSpecific/Checkbox'
import Inputform from '../../components/common/Inputform'
import Buttonform from '../../components/common/Buttonform'
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { languages } from '../../components/appSpecific/LanguageCode'

const SignIn = () => {
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')

  const navigateSignin = useNavigate()

  const handleOnClickSignin = () => {
    const user = {
      email: email,
      password: pass,
    }
    axios
      .post('https://api.bfa-platform.com/api/login', user)
      .then((res) => {
        console.log('first', res)
        localStorage.setItem('token', JSON.stringify(res.data.token))
        localStorage.setItem('name', JSON.stringify(res.data.user.name))
        localStorage.setItem('id', JSON.stringify(res.data.user.id))

        console.log('Resister Succesifully', res.data)
        navigateSignin('/dashboard')
      })
      .catch((e) => {
        console.log('Error----->', e.response.data)
      })
  }

  //laguage Translator
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff counter2')
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage, t])
  return (
    <>
      <Header />

      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="col-md-5">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 col-xl-7 mx-auto">
                    <div className="card register_card">
                      <h3 className="signin_title">{t('Sign in')}</h3>
                      <div className="create_an_account">
                        <strong>{t('New User ?')}</strong>
                        <NavLink to="/register">
                          {t('Create an account')}
                        </NavLink>
                      </div>

                      <Inputform
                        type="text"
                        placeholder={t('Email')}
                        Value={email}
                        onChangeText={(val) => setEmail(val.value)}
                      />
                      <Inputform
                        type="password"
                        placeholder={t('Password')}
                        Value={pass}
                        onChangeText={(val) => setPass(val.value)}
                      />

                      <div className="row ">
                        <Checkbox />
                        <div className="col-12 col-xl-6">
                          <div className="text-right">
                            <a href="#">{t('Forget Password ?')}</a>
                          </div>
                        </div>
                        <Buttonform
                          buttonName={t('Sign in')}
                          onClick={handleOnClickSignin}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 d-none d-md-flex bg-image">
            <img id="myPicture" className="w-100" src={layer} />
          </div>
        </div>
      </div>
    </>
  )
}
export default SignIn
