import React, { useEffect, useRef, useState } from 'react'
import './chartboard.css'
import Sidebar from '../../components/appSpecific/Sidebar'
import avtar2 from '../../assets/img/avatars/avatar-2.jpg'
import avtar3 from '../../assets/img/avatars/Asset-12.png'
import avtar4 from '../../assets/img/avatars/avatar-4.jpg'
import avtar5 from '../../assets/img/avatars/avatar-5.jpg'
import avtar1 from '../../assets/img/avatars/avatar.jpg'
import axios from 'axios'
import Buttonform from '../../components/common/Buttonform'
import Chartbox from '../../components/appSpecific/Chartbox'
import { MdCall } from 'react-icons/md'
import { RiMessage3Fill } from 'react-icons/ri'
import { MdClose } from 'react-icons/md'
import { FaVideo } from 'react-icons/fa'
import { BsThreeDots } from 'react-icons/bs'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FaCircle } from 'react-icons/fa'
import { IoSendSharp } from 'react-icons/io5'
import ReactTooltip from 'react-tooltip'
import { MdUploadFile } from 'react-icons/md'
import { VscRefresh } from 'react-icons/vsc'
import { height } from '@mui/system'
import { Markup } from 'interweave'

import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { languages } from '../../components/appSpecific/LanguageCode'

function Chartboard() {
  const obj = localStorage.getItem('name')
  // const obj2 = localStorage.getItem("first_name");
  // console.log("local>>>>>>", localStorage.getItem())
  const name = JSON.parse(obj)
  // const firstName = JSON.parse(obj2);
  const [chart, setChart] = useState([])
  const [click, setClick] = useState([])
  const [data, setData] = useState([])
  const [currentMessage, setCurrntMessage] = useState('')
  const [buttonMessage, setButtonMessage] = useState(null)
  //const [time, setTime] = React.useState();
  const [show, setShow] = useState(false)
  const [randomId, setRandomId] = useState()
  // const [constrandomID,setCostrandomid] = useState();
  // const [randomId2]=useState(Math.floor(Math.random() * 1000));
  const messageRef = useRef()
  const obj1 = localStorage.getItem('token')
  const token = JSON.parse(obj1)

  useEffect(() => {
    let rN = getRandomNumber()
    console.log('rN ', rN)
    setRandomId(rN)
  }, [])

  useEffect(() => {
    if (buttonMessage) {
      console.log('came here')
      Chartbot()
    }
  }, [randomId])

  console.log('buttonMessage', buttonMessage)

  useEffect(() => {
    if (buttonMessage) {
      setRandomId(getRandomNumber())
    }
    // handelOnCLickrefres()
  }, [buttonMessage])

  function getRandomNumber() {
    return Math.floor(Math.random() * 10000)
  }

  // function getRandomNumber2() {
  //   return Math.floor(Math.random() * 10000);
  // }

  const Chartbot = () => {
    console.log('randomId ', randomId)
    const tempObj = {
      user: `${name}`,
      text: currentMessage || buttonMessage,
    }

    // setChart([...chart, tempObj]);
    let temp_list = [...chart, tempObj]

    console.log('temp_list ', temp_list)
    const user = {
      message: currentMessage || buttonMessage,
      sender: randomId,
    }

    axios
      .post('https://api.bfa-platform.com/webhooks/rest/webhook', user, {
        // .post("https://208.87.131.39:5005/webhooks/rest/webhook", user, {
        headers: {
          "Authorization": 'Bearer ' + token,
          // 'Access-Control-Allow-Origin': '*',
          'Content-Type': 'text/plain',
        }
      })
      .then((res) => {
        setCurrntMessage('')
        res.data.map((item) => {
          const obj1 = {
            user: 'bot',
            ...item,
          }
          setChart([...temp_list, obj1])
          temp_list = [...temp_list, obj1]
        })

        console.log('chart bot Success', res)
        // eslint-disable-next-line no-const-assign
        chart = chart.push(obj1)
        setChart(chart.push(obj1))
      })
      .catch((e) => {
        console.log('Error', e)
      })
  }

  const ChartButtonClick = (payload) => {
    let temp_list = [...chart]

    const user = {
      message: payload,
      sender: `${randomId}`,
    }
    console.log({ user })
    axios
      .post('https://api.bfa-platform.com/webhooks/rest/webhook', user, {
        headers: {
          Authorization: 'Bearer ' + token,
          // 'Access-Control-Allow-Origin': '*',
          'Content-Type': 'text/plain',
        },
      })
      .then((res) => {
        setCurrntMessage('')
        console.log('chart bot Success', res.data)
        res.data.map((item) => {
          const obj1 = {
            user: 'bot',
            ...item,
          }
          setChart([...temp_list, obj1])
          temp_list = [...temp_list, obj1]
        })
      })
      .catch((e) => {
        console.log('Error', e.response.data)
      })
  }

  const handleOnChange = (e) => {
    setCurrntMessage(e.target.value)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // setButtonMessage(e.target.value)
      setCurrntMessage(e.target.value)
      Chartbot()
    }
  }
  const handelOnCLickrefres = () => {
    setChart([])
    setCurrntMessage('')
    setButtonMessage(null)
    setRandomId(getRandomNumber())
  }

  const handelOnCLick = (e) => {
    if (!buttonMessage) {
      setRandomId(getRandomNumber())
    }

    Chartbot()
    // // setButtonMessage(e.target.value)
    // setCurrntMessage(e.target.value);
  }
  console.log('Chart is------>', chart)

  useEffect(() => {
    if (buttonMessage) {
      Chartbot()
    }
    // handelOnCLickrefres()
  }, [buttonMessage])

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  })

  //laguage Translator
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff counter2')
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage, t])

  const [toggleState, setToggleState] = React.useState(false)
  const handleClickChange = (val) => {
    setToggleState(val)
  }

  return (
    <>
      <div className="all_in_one">
        {show ? (
          <div className="card messagebox">
            <div class="row g-0 " style={{ height: '69%' }}>
              <div
                class="col-12 col-lg-12 col-xl-12"
                style={{ height: '100%' }}
              >
                <div class="py-2 px-4 border-bottom d-lg-block">
                  <div class="d-flex align-items-start align-items-center py-1">
                    <div
                      class="position-relative h-100"
                      style={{ height: '100%' }}
                    >
                      <img
                        src={avtar3}
                        class="rounded-circle me-1"
                        alt="Bertha Martin"
                        width="40"
                        height="40"
                      />
                    </div>
                    <div class="flex-grow-1">
                      <strong style={{ fontSize: '12px' }}>
                        Sara Assistant
                      </strong>
                      <div class="text-muted small">
                        <em>
                          <span className="online_icon">
                            {' '}
                            <FaCircle />{' '}
                          </span>{' '}
                          online
                        </em>
                      </div>
                    </div>
                    <div>
                      <button class="btn btn-sm">
                        <span className="icon_call">
                          {' '}
                          <MdCall />{' '}
                        </span>
                      </button>

                      <button class="btn btn-sm tree_dot d-none d-md-inline-block">
                        <span className="icon_call">
                          {' '}
                          <FaVideo />{' '}
                        </span>
                      </button>

                      <button class="btn btn-sm">
                        <span className="icon_call">
                          {' '}
                          <BsThreeDots />{' '}
                        </span>
                      </button>
                      <span
                        className="close_btn"
                        onClick={() => setShow(false)}
                      >
                        {' '}
                        <AiFillCloseCircle />{' '}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="position-relative">
                  <div className="chat-messages p-2">
                    <div
                      class="container"
                      style={{
                        backgroundColor: 'rgb(218, 233, 240)',
                        borderRadius: '15px',
                        boxShadow:
                          'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
                        padding: '10px 20px',
                      }}
                    >
                      <div class="row row-cols-auto">
                        <button
                          class="col btn info_chart"
                          onClick={() => {
                            setButtonMessage('informations')
                            // handelOnCLickinfo();
                          }}
                        >
                          Pays
                        </button>
                        <button
                          class="col btn info_chart"
                          onClick={() => {
                            // handelOnCLickinfo();
                            setButtonMessage('contacts')
                          }}
                        >
                          Contacts
                        </button>

                        <button
                          class="col btn info_chart"
                          onClick={() => {
                            // handelOnCLickinfo();
                            setButtonMessage('consultants')
                          }}
                        >
                          Consultants
                        </button>
                        <button
                          class="col btn info_chart"
                          onClick={() => {
                            // handelOnCLickinfo();
                            setButtonMessage('partenaires')
                          }}
                        >
                          Partenaires
                        </button>
                        <button
                          class="col btn info_chart"
                          onClick={() => {
                            // handelOnCLickinfo();
                            setButtonMessage('situation secteur')
                          }}
                        >
                          Secteurs
                        </button>
                        <button
                          class="col btn info_chart"
                          onClick={() => {
                            // handelOnCLickinfo();
                            setButtonMessage('météo')
                          }}
                        >
                          Météo
                        </button>

                        <button
                          class="col btn info_chart"
                          onClick={() => {
                            // handelOnCLickinfo();
                            setButtonMessage('importation')
                          }}
                        >
                          Importer
                        </button>

                        <button
                          class="col btn info_chart"
                          onClick={() => {
                            // handelOnCLickinfo();
                            setButtonMessage('exportation')
                          }}
                          style={{ flex: '0' }}
                        >
                          Exporter
                        </button>

                        <button
                          class="col info_chart"
                          onClick={() => {
                            // handelOnCLickinfo();
                            // setButtonMessage("Appels");
                            setButtonMessage('appels d’offres')
                          }}
                          style={{
                            flex: '0',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          Appels d'offers
                        </button>
                      </div>
                    </div>
                    <br />

                    <div className="chat-message-left pb-4" ref={messageRef}>
                      <div>
                        <img
                          src={avtar3}
                          className="rounded-circle me-1"
                          alt="Bertha Martin"
                          width="40"
                          height="40"
                        />
                        <div className="text-muted small text-nowrap mt-2">
                          2:34 am
                        </div>
                      </div>
                      <div
                        className="flex-shrink-1 rounded py-2 px-3 ms-3"
                        style={{
                          backgroundColor: '#cbdce4',
                          color: 'black',
                          // height: "230px",
                          borderRadius: '20px',
                          boxShadow:
                            'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                          textAlign: 'justify',
                        }}
                      >
                        <p>
                          “{t('Hi')}{' '}
                          <span style={{ fontWeight: '600', color: '#2E4053' }}>
                            {name}
                          </span>
                          ,{' '}
                          {t(
                            "I'm Sara, the Chabot of Business for Africa. I am at your disposal to accompany you to develop your business in Africa. You can either use the buttons at the top of the Chatbot or write a sentence to directly access information on countries, sectors, import and export processes, or look for contacts, partners, consultants, calls for tenders, contacts of heads of organizations or know the weather,  etc. For example, you can write: Je cherche des appels d’offres en Tanzanie dans le secteur de transport . For the moment the Chatbot Sara is in French. The circular button inside left the text box is used to initialize the Chabot if necessary. How then can I help you?",
                          )}
                          "
                        </p>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                          }}
                        ></div>
                      </div>
                    </div>

                    {chart.map((item) => {
                      if (item.user === 'bot')
                        return (
                          <div
                            className="chat-message-left pb-4"
                            ref={messageRef}
                          >
                            {item.text && (
                              <>
                                <div>
                                  <img
                                    src={avtar3}
                                    className="rounded-circle me-1"
                                    alt="Bertha Martin"
                                    width="40"
                                    height="40"
                                  />
                                  <div className="text-muted small text-nowrap mt-2">
                                    2:34 am
                                  </div>
                                </div>
                                <div
                                  className="flex-shrink-1 rounded py-2 px-3 ms-3"
                                  style={{
                                    backgroundColor: 'rgba(218, 233, 240)',
                                    color: 'black',
                                    borderRadius: '20px',
                                    boxShadow:
                                      'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                  }}
                                >
                                  <p>
                                    <Markup content={item.text} />
                                  </p>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      flexWrap: 'wrap',
                                    }}
                                  >
                                    {item.hasOwnProperty('buttons') &&
                                      item.buttons &&
                                      item.buttons.map((buttonItem) => {
                                        return (
                                          <button
                                            onClick={() => {
                                              ChartButtonClick(
                                                buttonItem.payload,
                                              )
                                            }}
                                            style={{
                                              borderRadius: '100px',
                                              backgroundColor: '#aadcf5',
                                              border: 'none',
                                              margin: '5px',
                                              padding: '8px',
                                              border: '2px solid #52b5e7',
                                              boxShadow:
                                                'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                            }}
                                            classNameName="btn btn-primary signinbutton mr-2 mb-2"
                                          >
                                            {buttonItem.title}
                                          </button>
                                        )
                                      })}
                                  </div>
                                </div>
                              </>
                            )}

                            {item?.custom?.data?.map((customsData) => {
                              return (
                                <div>
                                  <div
                                    className="chat-message-left pb-4 callme"
                                    ref={messageRef}
                                  >
                                    <div>
                                      <img
                                        src={avtar3}
                                        className="rounded-circle me-1"
                                        alt="Bertha Martin"
                                        width="40"
                                        height="40"
                                      />
                                      <div className="text-muted small text-nowrap mt-2">
                                        2:34 am
                                      </div>
                                    </div>
                                    <div
                                      className="rounded py-2 px-3 ms-3"
                                      style={{
                                        backgroundColor: '#cbdce4',
                                        color: 'black',
                                        borderRadius: '20px',
                                        boxShadow:
                                          'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                      }}
                                    >
                                      <Markup content={customsData.title} />
                                      <Markup
                                        content={customsData.description}
                                      />
                                      <Markup content={customsData.deadline} />
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )
                      else
                        return (
                          <div
                            className="chat-message-right pb-4"
                            ref={messageRef}
                          >
                            {item.text ? (
                              <>
                                <div>
                                  <img
                                    src={avtar1}
                                    className="rounded-circle me-1"
                                    alt="Chris Wood"
                                    width="40"
                                    height="40"
                                  />
                                  <div className="text-muted small text-nowrap mt-2">
                                    2:33 am
                                  </div>
                                </div>
                                <div
                                  className="flex-shrink-1 rounded py-2 px-3 me-3"
                                  style={{
                                    backgroundColor: '#aadcf5',
                                    color: 'black',
                                    height: '40px',
                                    boxShadow:
                                      'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                                  }}
                                >
                                  {item.text}
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        )
                    })}
                  </div>
                </div>

                <div className="flex-grow-0 py-3 px-4 border-top">
                  <div className="input-group">
                    <span
                      style={{
                        backgroundColor: '#4694f9',
                        height: '35px',
                        width: '35px',
                        textAlign: 'center',
                        color: 'white',
                        borderRadius: '20px',
                        position: 'relative',
                        top: '0.2vh',
                        right: '0vh',
                        fontSize: '20px',
                      }}
                    >
                      <MdUploadFile />
                    </span>{' '}
                    &nbsp; &nbsp;
                    <span
                      className="refresh_queen"
                      style={{
                        position: 'relative',
                        left: '30px',
                        zIndex: '888',
                        top: '1vh',
                        fontSize: '17px',
                      }}
                      onClick={handelOnCLickrefres}
                    >
                      {' '}
                      <VscRefresh />{' '}
                    </span>
                    <input
                      onKeyPress={handleKeyPress}
                      onChange={handleOnChange}
                      type="text"
                      className="form-control msg_chat"
                      placeholder="Type your message"
                      value={currentMessage}
                      autoFocus={true}
                      autocomplete="off"
                      style={{
                        width: '10vh',
                        float: 'right',
                        height: '40px',
                        borderRadius: '20px',
                      }}
                    />
                    <button
                      className="btn send_btn"
                      type="submir"
                      onClick={handelOnCLick}
                    >
                      <span className="icon_send">
                        {' '}
                        <IoSendSharp />{' '}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="dash_main">
        <div className="dash_iconq" onClick={() => setShow(true)}>
          <span className="icon_msg">
            {' '}
            <img
              src={avtar3}
              className="rounded-circle"
              alt="Bertha Martin"
              width="60"
              height="60"
            />{' '}
          </span>
        </div>
      </div>
    </>
  )
}

export default Chartboard
