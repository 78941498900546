import React from "react";
import { NavLink } from "react-router-dom";

function Dashboardcard(props) {
  const { navlink, title, subtitle, date, total, image } = props; 
  return (
    <>
      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4 ">
        <NavLink to={navlink}>
          <div className="catagory_box">
            <div className="catagory_content ">
              <div className="catagory_box_icon">
                <img src={image} />
              </div>
              <div className="catagory_box_text">
                <h6>{title}</h6>
                <p>{subtitle}</p>
              </div>
            </div>
            <div className="catagory_box_velue">
              <div className="date">{date}</div>
              <div className="total">{total}</div>
            </div>
          </div>
        </NavLink>
      </div>
    </>
  );
}

export default Dashboardcard;
