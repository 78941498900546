import React from "react";

function Paginate() {
  return (
    <>
      <div class="row">
        <div class="col-sm-12 col-md-5">
          <div
            class="dataTables_info"
            id="datatables-buttons_info"
            role="status"
            aria-live="polite"
          >
            Showing 1 to 9 of 15 entries
          </div>
        </div>
        <div class="col-sm-12 col-md-7">
          <div
            class="dataTables_paginate paging_simple_numbers"
            id="datatables-buttons_paginate"
          >
            <ul class="pagination mb-0 justify-content-end">
              <li
                class="paginate_button page-item previous disabled"
                id="datatables-buttons_previous"
              >
                <a
                  href="#"
                  aria-controls="datatables-buttons"
                  data-dt-idx="0"
                  tabindex="0"
                  class="page-link"
                >
                  Previous
                </a>
              </li>


              <li class="paginate_button page-item active">
                <a
                  href="#"
                  aria-controls="datatables-buttons"
                  data-dt-idx="1"
                  tabindex="0"
                  class="page-link"
                >
                  1
                </a>
              </li>

              <li class="paginate_button page-item ">
                <a
                  href="#"
                  aria-controls="datatables-buttons"
                  data-dt-idx="2"
                  tabindex="0"
                  class="page-link"
                >
                  2
                </a>
              </li>

              <li class="paginate_button page-item ">
                <a
                  href="#"
                  aria-controls="datatables-buttons"
                  data-dt-idx="3"
                  tabindex="0"
                  class="page-link"
                >
                  3
                </a>
              </li>

              <li class="paginate_button page-item ">
                <a
                  href="#"
                  aria-controls="datatables-buttons"
                  data-dt-idx="4"
                  tabindex="0"
                  class="page-link"
                >
                  4
                </a>
              </li>
              <li class="paginate_button page-item ">
                <a
                  href="#"
                  aria-controls="datatables-buttons"
                  data-dt-idx="5"
                  tabindex="0"
                  class="page-link"
                >
                  5
                </a>
              </li>

              <li class="paginate_button page-item ">
                <a
                  href="#"
                  aria-controls="datatables-buttons"
                  data-dt-idx="6"
                  tabindex="0"
                  class="page-link"
                >
                  6
                </a>
              </li>

              <li
                class="paginate_button page-item next"
                id="datatables-buttons_next"
              >
                <a
                  href="#"
                  aria-controls="datatables-buttons"
                  data-dt-idx="7"
                  tabindex="0"
                  class="page-link"
                >
                  Next
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Paginate;
