import React from 'react'

const PrivacyData = () => {
  return (
    <>
      <div className="container">
        <h2 className="my-5 text-center">
          Business For Africa (BFA) Privacy Policy
        </h2>
        <p>
          We are the BFA Business for Africa Inc. (BFA), and we are committed to
          keeping your personal information safe and secure. If you have any
          questions regarding our Cookies &amp; Privacy Policy, please email us
          at info@bfa-platform.com, or write to:{' '}
        </p>
        <h4 className="my-2">
          <a name="_w3tlybla76yn" />
          1. Application of This Policy
        </h4>
        <p>This privacy policy relates to:</p>
        <ol>
          <li>
            How we handle information received or collected about you as an
            individual through our website,{' '}
            <a href="https://www.afdb.org/fr/">www.bfa-platform.</a>com (the
            “Website”);{' '}
          </li>
          <li>How we communicate with you as an individual; and </li>
          <li>
            Your rights as an individual in respect to such information and
            communications.{' '}
          </li>
        </ol>
        <h4 className="my-2">
          <a name="_igo2ts65zskb" />
          2. Collection and Use of Your Personal Information
        </h4>
        <ol start={1} type="A">
          <li>
            Any information about you as an individual which we collect from the
            Website api.bfa-platform.com will be used in accordance with
            applicable data privacy laws. If you would like to have a copy of
            your personal information, please email us:{' '}
            <a href="mailto:info@bfa-platform.com">info@bfa-platform.com</a>.{' '}
          </li>
          <li>
            We will only collect your information when we have your consent.{' '}
          </li>
          <li>
            Your personal information will be collected if you register as a
            client and subscribe to our services. We would collect your name,
            email address and profile information.&nbsp; We will use this
            information strictly to assess your Business For Africa (BFA)
            related business.&nbsp; We may disclose or share personal data with
            third parties with your prior consent only for the purposes of
            business connections, in which case we will take steps to ensure
            that your privacy rights continue to be protected.{' '}
          </li>
          <li>
            Your personal information will be collected if you participate in
            the Website’s Site contains bulletin boards, chat rooms, access to
            mailing lists or other message or communication facilities
            (collectively, "Forums"). We may collect your name, email address,
            telephone number, and company name, as well as any other personal
            data you may include in your messages. This information will be used
            to communicate with you and / or your company to discuss your query.{' '}
          </li>
          <li>
            Your IP Address, a string of numbers unique to your computer, is
            recorded by our web server when you request any page or component of
            the Website. This information is used to monitor your usage of the
            Website and also avoid DDOS attacks from the hackers.{' '}
          </li>
        </ol>
        <h4 className="my-2">
          <a name="_u0dpfyx7i3vb" />
          3. Registration privacy policy
        </h4>
        <ol start={1} type="A">
          <li>
            When you choose to register with Business For Africa (BFA), we will
            ask you personal data about you, such as, your name, address,
            country of residence, business profiles and email address. This
            information enables us to:
          </li>
        </ol>
        <ol>
          <li>administer your account. </li>
          <li>
            communicate with you in a personal way to your business
            opportunities and other business-related information; and{' '}
          </li>
          <li>
            communicate with you in a personal way to inform you about our other
            new opportunities matching your profile.{' '}
          </li>
        </ol>
        <ol start={2} type="A">
          <li>
            You can share your personal data with us in a number of ways, for
            instance by:
          </li>
        </ol>
        <ol>
          <li>registering your account. </li>
          <li>
            applying for a certain business opportunity by uploading the
            relevant personal data; and{' '}
          </li>
          <li>
            communicating with Business For Africa (BFA) by email, phone or in
            writing.{' '}
          </li>
        </ol>
        <ol start={3} type="A">
          <li>
            Your personal data internally may be used by the business marketing
            and communication department.
          </li>
          <li>
            We may also share your personal data with third party service
            providers or agents to perform certain processing activities on
            behalf of Business For Africa (BFA). We will not sell, rent, lease,
            or provide your personal data to third parties. We require these
            service providers to protect your personal data diligently.
          </li>
          <li>
            We will only release your personal data if so, required by law,
            where necessary for preventing or combating fraud, where necessary
            for dispute resolution, or for any other pressing legitimate need
            which under the circumstances must outweigh your privacy interests,
            such as the security of our business and the safety of our staff.
          </li>
          <li>
            If you choose to register with us, you authorize the Business For
            Africa (BFA) and its staff to collect, store, use, disclose, and
            otherwise process your personal data in connection with the purposes
            as described above.
          </li>
          <li>
            If you opt in for the option of receiving business opportunity
            notices we will contact you in case of matching opportunities.
          </li>
        </ol>
        <h4 className="my-2">
          <a name="_u3i2pll8pw6r" />
          4. Protection of your information
        </h4>
        <ol start={1} type="A">
          <li>
            We take appropriate technical and organizational security measures
            against loss or unlawful processing of this data. This may include
            using secure registration forms, encryption of data, and restricting
            access to your personal information.
          </li>
          <li>
            We will retain your personal data within a reasonable timeframe
            after your last activity or as long as you are a candidate on an
            active requisition.
          </li>
          <li>
            If you do not want your personal data to be retained, you may
            request deletion of your account by contacting us or you can delete
            your profile through website. Your personal data will be deleted
            from the servers permanently in the next backup cycle.
          </li>
          <li>
            We will only retain personal data related to your account to comply
            with our legal obligations, resolve disputes, and enforce our
            agreements.
          </li>
        </ol>
        <h4 className="my-2">
          <a name="_m3nw1g4zc3jn" />
          5. Your IP Address and Cookies
        </h4>
        <ol start={1} type="A">
          <li>
            We will not use your IP address to identify you and your personal
            information in any way.
          </li>
          <li>
            Cookies are used by the Website to allow us to recognize your
            preferred settings e.g. to store your ID and password for future
            sessions. This saves you from re-entering information on return
            visits to the Website.{' '}
          </li>
        </ol>
        <p>
          For further explanation of cookies, see the{' '}
          <a href="http://www.allaboutcookies.org/">All About Cookie</a>.You can
          set your browser not to accept cookies.
          <br />
          If you have any questions regarding the use of cookies please contact
          us..
        </p>
        <h4 className="my-2">
          <a name="_m329v5kp4cbb" />
          6. Websites and mobile applications (Apple Appstore, Google Play
          store, etc.)
        </h4>
        <p>
          This privacy policy covers the Business For Africa (BFA) Website and
          Business For Africa (BFA) Mobile applications. Any other websites
          which may be linked to by the Website may be subject to their own
          privacy policy, which may differ from ours and we are not responsible
          for the content provided on any third-party websites.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </>
  )
}

export default PrivacyData
