import React ,{useEffect}from "react";
// import { BsSearch } from "react-icons/bs";
import { useTranslation } from 'react-i18next'; 
import cookies from 'js-cookie'; 
import { languages } from "../LanguageCode";

  function Search() {
  //laguage Translator
 const currentLanguageCode = cookies.get('i18next') || 'en'
 const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
 const { t } = useTranslation()
 
    useEffect(() => {
    console.log('Setting page stuff counter2')
    document.body.dir = currentLanguage.dir || 'ltr'
 }, [currentLanguage, t]); 
    
  return (
    <>
       {/* <form className="form-inline d-none d-sm-inline-block">
       <span className="search_icon"> <BsSearch/>  </span>
       <input
        className="form-control mr-sm-2"
        type="text"
        placeholder={t( " Search Projects...")}
        aria-label="Search"
        />
      </form> */}
    </>
  );
}

export default Search;
