import React from 'react'
import Footerlanding from '../../components/appSpecific/Footerlanding'
import Header3 from '../../components/appSpecific/Header3'
import PrivacyData from './PrivacyData'

const Privacy = () => {
  return (
      <>
          
          <Header3 />
            <PrivacyData/>
          
          <Footerlanding/>
    </>
  )
}

export default Privacy