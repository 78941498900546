import React from "react";
import Notification from "./HeaderComponents/Notification";
import Languages from "./HeaderComponents/Languages";
import Search from "./HeaderComponents/Search";
import linkedin from "../../assets/img/linkedin-in.svg";
import facebook from "../../assets/img/facebook-f.svg";
import youtube from "../../assets/img/youtube.svg";
import "../../assets/css/classic.css"
import logo from "../../assets/img/BFA High Quality.svg";
import { NavLink} from "react-router-dom";


function Header3() {
  return (
    <>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">

   <NavLink to="/"> 
   <img src={logo} className="logo_bs" alt="" />
   </NavLink>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

   <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul style={{float:"right"}} class="navbar-nav me-auto mb-2 mb-lg-1 justify-content-right      navbar_social">
        <li class="nav-item">
          <a class="nav-link active" href="#">
          <img  className="icon_social" src={youtube} alt=""/>
          </a>
        </li>

        <li class="nav-item active">
        <a class="nav-link" href="#">
        <img  className="icon_social" src={linkedin} alt=""/>
           </a>
        </li>

        <li class="nav-item active">
        <a class="nav-link" href="#">  
        <img  className="icon_social" src={facebook} alt=""/>
         </a>
        </li>
       </ul>


      <ul style={{float:"right"}} class="navbar-nav me-auto mb-2 mb-lg-1 justify-content-right    navbar_language">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">
          <div className="en_lang"> 
             <p> EN </p>
           </div> 
          </a>
          </li>
        <li class="nav-item active">
        <a class="nav-link" href="#"> <div className="fr_lang"> 
             <p> FR </p>
            </div> </a>
            </li>
            </ul>

        <ul style={{float:"right"}} class="navbar-nav me-auto mb-2 mb-lg-1 justify-content-right  navbar_text">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Who are we </a>
        </li>
        <li class="nav-item active">
        <a class="nav-link" href="#"> Our service</a>
        </li>
        <li class="nav-item active">
        <a class="nav-link" href="#">  Our offer </a>
         </li>
        </ul>
        </div>
        </div>
        </nav>

    </>
  );
}

export default Header3;
