import location from '../../assets/img/catagory/location.svg'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { BiDetail } from 'react-icons/bi'
import { BsFillEyeFill } from 'react-icons/bs'
import Tenders from '../../container/Tenders/Tenders'
import { IoLinkSharp } from 'react-icons/io5'
import axios from 'axios'
import { BiLink } from 'react-icons/bi'
import { BsCircleFill } from 'react-icons/bs'
import LightTooltip from '@mui/material/Tooltip'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { Typography } from '@mui/material'
import { Button } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import { Markup } from 'interweave'

import moment from 'moment/moment'
import { numberWithSpaces } from '../../assets/js/settings'

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
    marginTop: '-13px',
  },
  customArrow: {
    color: 'grey',
  },
}))

function Listview(props) {
  const classes = useStyles()
  const obj1 = localStorage.getItem('token')
  const token = JSON.parse(obj1)
  const [data2, setData2] = useState([])
  const {
    type,
    document,
    link,
    projectdata,
    investdata,
    jobdata,
    unitdata,
    data,
    hader1,
    hader2,
    hader3,
    hader4,
    hader5,
    hader6,
    hader7,
    hader8,
    hader9,
    hader10,
    isDetails = false,
  } = props
  // console.log('first',isDetails)
  const detailNavigate = useNavigate()
  const handleOnDetailsClick = () => {
    detailNavigate('/Listdetails')
  }

  // console.log(document);
  // console.log("project data", projectdata);
  const apidata = 'https://api.bfa-platform.com/'
  // console.log("listview data", investdata);
  const OperNewUrl = () => {
    window.open(link, '_blank')
  }

  const JobGet = () => {
    axios
      .get('https://api.bfa-platform.com/api/country/all', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((res) => {
        console.log('Jobs Get Countrey', res.data)
        setData2(res.data)
      })
      .catch((e) => {
        console.log('Error--->', e.response.data)
      })
  }

  useEffect(() => {
    JobGet()
  }, [])

  const Ffunction = (val) => {
    let country
    data2.map((item) => {
      if (item.id === val) {
        country = item.name
      }
    })
    return country
  }

  return (
    <>
      <table
        class="table mx-auto"
        style={{
          backgroundColor: 'white',
          borderCollapse: 'separate',
          borderSpacing: '0px 15px',
          width: '100%',
          boxShadow:
            'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          borderRadius: '10px',
        }}
      >
        <thead>
          <tr
            align="center"
            style={{
              fontSize: '14px',
              color: '#52A3CC',
              backgroundColor: 'white',
              boxShadow:
                'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            }}
          >
            <th scope="col" style={{ width: '10%' }}>
              {hader1}
            </th>
            <th scope="col" style={{ width: '30%' }}>
              {hader4}{/* Unit Price */}
            </th>
            
            <th scope="col" style={{ width: '21%' }}>
              {hader2}{/* type */}
            </th>

            <th scope="col" style={{ width: '20%' }}>
              {hader3}{/* Unit */}
            </th>
            
            <th scope="col">
              {hader5}
              {/* min */}
            </th>

            <th scope="col" style={{ width: '20%' }}>
              {hader6} {/* Average */}
             
            </th>
            <th scope="col" style={{ width: '100%' }}>
              {hader7}
              {/* Max */}
            </th>
            <th scope="col" style={{ width: '20%' }}>
              {hader8}
            </th>
            <th scope="col">{hader9}</th>
            <th scope="col">{hader10}</th>
          </tr>
        </thead>
        <tbody>
          {type === 'tender' &&
            data.map((elem) => {
              return (
                <tr
                  align="center"
                  style={{
                    backgroundColor: 'white',
                    boxShadow:
                      'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                    borderRadius: '30px',
                  }}
                >
                  <td>
                    {' '}
                    <span>
                      <img src={location} />
                    </span>{' '}
                    <br />
                    {elem.country == null ? 'N/A' : elem.country}{' '}
                  </td>
                  <td
                    className="tbl_spoter"
                    onClick={() => {
                      detailNavigate('/listdetails', {
                        state: { param1: elem, param2: 'Tenders' },
                      })
                    }}
                  >
                    {elem.acquisition_title == null
                      ? 'N/A'
                      : elem.acquisition_title}
                  </td>

                  <td>
                    {elem.category_acquisition == null
                      ? 'N/A'
                      : elem.category_acquisition}
                  </td>
                  <td>
                    {elem.selection_mode == null ? 'N/A' : elem.selection_mode}
                  </td>

                  <td>{elem.bailleur == null ? 'N/A' : elem.bailleur} </td>
                  <td>{elem.sector == null ? 'N/A' : elem.sector}</td>
                  <td>
                    {' '}
                    {elem.estimated_amount_euro == null
                      ? 'N/A'
                      : elem.estimated_amount_euro}
                  </td>
                  <td>
                  {moment(elem.publication_date).format('DD/MM/YYYY') == null
                      ? 'N/A'
                      :moment(elem.publication_date).format('DD/MM/YYYY')}
                  </td>
                  <td>
                    {moment(elem.submission_date).format('DD/MM/YYYY') == null
                      ? 'N/A'
                      : moment(elem.submission_date).format('DD/MM/YYYY')}
                  </td>
                  <td>
                    <span>
                      <a
                        href={
                          elem.document_path
                            ? apidata + elem.document_path
                            : apidata + '/null'
                        }
                        download
                        target="_blank"
                      >
                        <img
                          src="/static/media/ppm.65e7965ca64115a0c5bd587340a53735.svg"
                          height="20"
                          width="20"
                          style={{
                            position: 'relative',
                            left: '-3px',
                            top: '-2px',
                          }}
                        />
                      </a>
                    </span>
                    {/* <a
            href={elem.document_path ? apidata + elem.document_path : apidata + "/null"}
            download   target="_blank"
                  >
                     <span style={{fontSize:"18px",color:"#087cb7"}}>
            <BiDetail/>
            </span>
       <button className="reports_btn_listview"> Doc</button>
            </a>

           <span style={{fontSize:"18px",color:"#087cb7",cursor:"pointer"}} onClick={()=>{
         detailNavigate("/listdetails",{ state: { param1: elem, param2: "tender"}});
         }}><BsFillEyeFill/></span>

                 <button  className="reports_btn_listview" onClick={()=>{
         detailNavigate("/listdetails",{ state: { param1: elem, param2: "tender"}});
         }}> Details</button> */}

                    {/* <Button
                      variant=""
                      onClick={() => {
                        detailNavigate("/listdetails", { state: { param1: elem, param2: "tender" } });
                      }}
                      style={{
                        border: "1.5px solid rgb(82 163 204)",
                        backgroundColor: "none",
                        color: "white",
                        fontSize: "10px",
                        padding: "10px"
                      }}>
                      Details
                    </Button> */}

                    {/* <LightTooltip
                      classes={{
                        tooltip: classes.customTooltip,
                        arrow: classes.customArrow,
                      }}
                      title={
                        <React.Fragment>
                          <a
                            href={elem.document_path ? apidata + elem.document_path : apidata + "/null"}
                            download target="_blank">
                            <Button
                              variant=""
                              style={{
                                border: "1.5px solid rgb(82 163 204)",
                                backgroundColor: "none",
                                color: "white",
                                fontSize: "10px",
                                padding: "10px"
                              }}>
                              <img src="/static/media/ppm.65e7965ca64115a0c5bd587340a53735.svg"
                                style={{ position: "relative", left: "-3px", top: "-2px" }} />
                              &nbsp;Documnets
                            </Button>
                          </a>
                        </React.Fragment>
                      }
                      placement="left-start"
                      arrow>
                      <MoreVertOutlinedIcon
                        sx={{ fontSize: 22, marginLeft: "4px" }}
                      />
                    </LightTooltip> */}
                  </td>
                  <td></td>
                </tr>
              )
            })}

          {type === 'project' &&
            projectdata.map((elem) => {
              return (
                <tr
                  align="center"
                  style={{
                    backgroundColor: 'white',
                    boxShadow:
                      'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                  }}
                >
                  <td>
                    {' '}
                    <span>
                      <img src={location} />
                    </span>{' '}
                    <br />
                    {elem.country == null ? 'N/A' : elem.country}{' '}
                  </td>
                  <td
                    className="tbl_spoter"
                    onClick={() => {
                      detailNavigate('/listdetails', {
                        state: { param1: elem, param2: 'Projects' },
                      })
                    }}
                  >
                    {elem.name == null ? 'N/A' : elem.name}
                  </td>
                  <td>
                    {elem.project_status == 'in progress' ? (
                      <span style={{ color: '#E67E22', fontSize: '10px' }}>
                        <BsCircleFill />
                      </span>
                    ) : (
                      <span style={{ color: '#57c27e', fontSize: '10px' }}>
                        <BsCircleFill />
                      </span>
                    )}
                    <br />
                    {elem.project_status == null ? 'N/A' : elem.project_status}
                  </td>
                  <td>{elem.donor == null ? 'N/A' : elem.donor} </td>
                  <td>{elem.sector == null ? 'N/A' : elem.sector}</td>
                  <td>${elem.amount == null ? 'N/A' : numberWithSpaces(elem.amount)}</td>
                  <td style={{}}>
                    {/* <a
                      href={elem.ppm ? apidata + elem.ppm : apidata + "/null"}
                      download
                      target="_blank"
                    >
                      <span style={{fontSize:"18px",color:"#087cb7"}}>
                    <BiDetail/>
                    </span>
                      <button className="reports_btn_listview">
                        <span>  <img src="/static/media/report.5cb92f9f76a01de10fe50021ebf920e0.svg"
                      height={5} width={5}
                     /> </span>
                        Reports
                      </button>
                    </a>
                    &nbsp;
                    <a
                      href={
                        elem.par_report
                          ? apidata + elem.par_report
                          : apidata + "/null"
                      }
                      download
                      target="_blank"
                    >
                      <span style={{fontSize:"20px",color:" #087cb7"}} >
                      <BiLink/>
                      </span>
                      <button className="reports_btn_listview"> Tender</button>
                    </a>
                    &nbsp;
                    <span style={{fontSize:"18px",color:"#087cb7",cursor:"pointer"}} onClick={()=>{
  detailNavigate("/listdetails",{ state: { param1: elem, param2: "project"}});
         }}><BsFillEyeFill/></span>
                    <button
                      className="reports_btn_listview"
                      onClick={() => {
                        detailNavigate("/listdetails", {
                          state: { param1: elem, param2: "project" },
                        });
                      }}>
                      Details
                    </button> */}
                    {/* <LightTooltip
                      classes={{
                        tooltip: classes.customTooltip,
                        arrow: classes.customArrow,
                      }}
                      title={
                        <React.Fragment>
                          <a
                            href={
                              elem.par_report
                                ? apidata + elem.par_report
                                : apidata + "/null"
                            }
                            download
                            target="_blank">

                            <Button
                              variant=""
                              style={{
                                border: "1.5px solid rgb(82 163 204)",
                                backgroundColor: "none",
                                color: "white",
                                fontSize: "10px",
                                padding: "10px"
                              }}>
                             
                              &nbsp;Report
                            </Button>
                          </a>
                          */}

                    {/* <Button
                            variant=""
                            onClick={() => {
                              detailNavigate("/listdetails", {
                                state: { param1: elem, param2: "project" },
                              });
                            }}
                            style={{
                              border: "1.5px solid rgb(82 163 204)",
                              backgroundColor: "none",
                              color: "grey",
                              fontSize:"10px",
                              padding:"10px"
                            }}>
                            Details
                        </Button> */}
                    {/* </React.Fragment>
                      }
                      placement="left-start"
                      arrow
                    >

                      <MoreVertOutlinedIcon
                        sx={{ fontSize: 22, marginLeft: "4px" }}
                      />
                    </LightTooltip> */}
                    <span>
                      <a
                        href={
                          elem.par_report
                            ? apidata + elem.par_report
                            : apidata + '/null'
                        }
                        download
                        target="_blank"
                      >
                        <img
                          src="/static/media/report.5cb92f9f76a01de10fe50021ebf920e0.svg"
                          style={{
                            position: 'relative',
                            left: '-3px',
                            top: '-2px',
                          }}
                          height="20"
                          width="20"
                        />
                      </a>
                    </span>
                  </td>
                  <td>
                    <span>
                      <a
                        href={elem.ppm ? apidata + elem.ppm : apidata + '/null'}
                        download
                        target="_blank"
                      >
                        <img
                          src="/static/media/ppm.65e7965ca64115a0c5bd587340a53735.svg"
                          height="20"
                          width="20"
                          style={{
                            position: 'relative',
                            left: '-3px',
                            top: '-2px',
                          }}
                        />
                      </a>
                    </span>
                  </td>
                  <td> </td>
                </tr>
              )
            })}
          {type === 'invest' &&
            investdata.map((elem) => {
              return (
                <tr
                  align="center"
                  style={{
                    backgroundColor: 'white',
                    boxShadow:
                      'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                  }}
                >
                  <td>
                    {' '}
                    <span>
                      <img src={location} />
                    </span>{' '}
                    <br />
                    {elem.country == null ? 'N/A' : elem.country}{' '}
                  </td>
                  <td
                    className="tbl_spoter"
                    onClick={() => {
                      detailNavigate('/listdetails', {
                        state: { param1: elem, param2: 'Investments' },
                      })
                    }}
                  >
                    {elem.project_name == null ? 'N/A' : elem.project_name}
                  </td>
                  <td>
                    {elem.project_classification == null
                      ? 'N/A'
                      : elem.project_classification}
                  </td>
                  <td>
                    {elem.total_project_value == null
                      ? 'N/A'
                      : elem.total_project_value}{' '}
                  </td>
                  <td>{elem.investors == null ? 'N/A' : elem.investors}</td>
                  <td>
                    {' '}
                    {elem.project_readiness_indicators == null
                      ? 'N/A'
                      : elem.project_readiness_indicators}
                  </td>
                  <td>
                    {elem.projected_financial_gap == null
                      ? 'N/A'
                      : elem.projected_financial_gap}
                  </td>
                  <td style={{ width: '300px' }}>
                    <span>
                      <a
                        href={elem.ppp ? apidata + elem.ppp : apidata + '/null'}
                        download
                        target="_blank"
                      >
                        <img
                          src="/static/media/report.5cb92f9f76a01de10fe50021ebf920e0.svg"
                          height="20"
                          width="20"
                          style={{
                            position: 'relative',
                            left: '-3px',
                            top: '-2px',
                          }}
                        />
                      </a>
                    </span>
                    {/* <span style={{ fontSize: "18px", color: "#087cb7" }}>
                      <a
                        href={elem.ppp ? apidata + elem.ppp : apidata + "/null"}
                        download
                        target="_blank">
                        <span style={{ fontSize: "18px", color: "#087cb7" }}>
                          {" "}
                          <BiDetail />
                        </span>
                      </a>
                    </span>{" "}
                    &nbsp;
                    <span style={{ fontSize: "18px", color: "#087cb7" }}>
                      <a
                        href={
                          elem.other_docs
                            ? apidata + elem.other_docs
                            : apidata + "/null"
                        }
                        download
                        target="_blank"
                      >
                        <span style={{ fontSize: "18px", color: "#087cb7" }}>
                          {" "}
                          <BiLink />
                        </span>
                      </a>
                    </span>{" "}
                    &nbsp;
                    <span
                      style={{
                        fontSize: "18px",
                        color: "#087cb7",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        detailNavigate("/listdetails", {
                          state: { param1: elem, param2: "invest" },
                        });
                      }}
                    >
                      <BsFillEyeFill />
                    </span> */}
                    {/* <LightTooltip
                      classes={{
                        tooltip: classes.customTooltip,
                        arrow: classes.customArrow,
                      }}
                      title={
                        <React.Fragment>
                          <a
                            href={elem.ppp ? apidata + elem.ppp : apidata + "/null"}
                            download
                            target="_blank">

                            <Button
                              variant=""
                              style={{
                                border: "1.5px solid rgb(82 163 204)",
                                backgroundColor: "none",
                                color: "white",
                                fontSize: "10px",
                                padding: "10px"
                              }}>
                              <img src="/static/media/report.5cb92f9f76a01de10fe50021ebf920e0.svg"
                                style={{ position: "relative", left: "-3px", top: "-2px" }}
                              />
                              &nbsp;<span style={{ fontSize: "7px" }}>Project sheet</span>
                            </Button>

                          </a>
                          &nbsp; &nbsp;
                          <a
                            href={
                              elem.other_docs
                                ? apidata + elem.other_docs
                                : apidata + "/null"
                            }
                            download
                            target="_blank">
                            <Button
                              variant=""
                              style={{
                                border: "1.5px solid rgb(82 163 204)",
                                backgroundColor: "none",
                                color: "white",
                                fontSize: "10px",
                                padding: "10px"
                              }}>
                              <img src="/static/media/ppm.65e7965ca64115a0c5bd587340a53735.svg"
                                style={{ position: "relative", left: "-3px", top: "-2px" }} />
                              &nbsp;<span style={{ fontSize: "7px" }}>Bussiness Plane</span>
                            </Button>
                          </a>
                          &nbsp; &nbsp;
                          <Button
                            variant=""
                            onClick={() => {
                              detailNavigate("/listdetails", {
                                state: { param1: elem, param2: "invest" },
                              });
                            }}
                            style={{
                              border: "1.5px solid rgb(82 163 204)",
                              backgroundColor: "none",
                              color: "grey",
                              fontSize:"10px",
                              padding:"10px"
                            }}>
                            <span style={{fontSize:"7px"}}>Details</span>
                        </Button>
                        </React.Fragment>
                      }
                      placement="left-start"
                      arrow
                    >

                      <MoreVertOutlinedIcon
                        sx={{ fontSize: 22, marginLeft: "4px" }}
                      />
                    </LightTooltip> */}
                  </td>
                  <td>
                    <span>
                      <a
                        href={elem.ppp ? apidata + elem.ppp : apidata + '/null'} target="_blank"
                      >
                        <img
                          src="/static/media/ppm.65e7965ca64115a0c5bd587340a53735.svg"
                          height="20"
                          width="20"
                          style={{
                            position: 'relative',
                            left: '-3px',
                            top: '-2px',
                          }}
                        />
                      </a>
                    </span>
                  </td>
                  <td> </td>
                </tr>
              )
            })}

          {type === 'jobs' &&
            jobdata.map((elem) => {
              return (
                <tr
                  align="center"
                  style={{
                    backgroundColor: 'white',
                    boxShadow:
                      ' rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                  }}
                >
                  <td>
                    {' '}
                    <span>
                      <img src={location} />
                    </span>{' '}
                    <br />
                    {elem.country == null ? 'N/A' : elem.country}{' '}
                  </td>
                  <td
                    className="tbl_spoter"
                    onClick={() => {
                      detailNavigate('/listdetails', {
                        state: { param1: elem, param2: 'Jobs' },
                      })
                    }}
                  >
                    {elem.job_title == null ? 'N/A' : elem.job_title}
                  </td>

                  <td>{elem.type == null ? 'N/A' : elem.type}</td>

                  <td>
                    {elem.organization == null ? 'N/A' : elem.organization}{' '}
                  </td>
                  <td>{elem.address == null ? 'N/A' : elem.address}</td>
                  <td>
                   
                    {moment(elem.publication_date).format('DD/MM/YYYY') == null
                      ? 'N/A'
                      : moment(elem.publication_date).format('DD/MM/YYYY')}
                  </td>
                  <td>{moment(elem.apply_by).format('DD/MM/YYYY') == null ? 'N/A' : moment(elem.apply_by).format('DD/MM/YYYY')}</td>
                  <td>
                    <span>
                      <a
                        href={elem.web_link ? elem.web_link : apidata + '/null'}
                        target="_blank"
                      >
                        <img
                          src="/static/media/ppm.65e7965ca64115a0c5bd587340a53735.svg"
                          height="20"
                          width="20"
                          style={{
                            position: 'relative',
                            left: '-3px',
                            top: '-2px',
                          }}
                        />
                      </a>
                    </span>
                    {/* <a
                      href={elem.web_link ? elem.web_link : apidata + "/null"}
                      target="_blank"
                    >
                      <span style={{ fontSize: "18px", color: "#087cb7" }}>
                        <IoLinkSharp />
                      </span>
                    </a>
                    &nbsp;
                    <span
                      style={{
                        fontSize: "18px",
                        color: "#087cb7",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        detailNavigate("/listdetails", {
                          state: { param1: elem, param2: "jobs" },
                        });
                      }}
                    >
                      <BsFillEyeFill />
                    </span>{" "} */}

                    {/* <LightTooltip
                      classes={{
                        tooltip: classes.customTooltip,
                        arrow: classes.customArrow,
                      }}
                      title={
                        <React.Fragment>
                          <a
                            href={elem.web_link ? elem.web_link : apidata + "/null"}
                            target="_blank">
                            <Button
                              variant=""
                              style={{
                                border: "1.5px solid rgb(82 163 204)",
                                backgroundColor: "none",
                                color: "white",
                                fontSize: "10px",
                                padding: "10px"
                              }}>
                              <img src="/static/media/ppm.65e7965ca64115a0c5bd587340a53735.svg"
                                style={{ position: "relative", left: "-3px", top: "-2px" }} />
                              &nbsp;Link
                            </Button>
                          </a>
                          &nbsp; &nbsp;
                          <Button
                            variant=""
                            onClick={()=>{
                                detailNavigate("/listdetails", {
                                state: { param1: elem, param2: "jobs" },
                              });
                              }}
                            style={{
                              border: "1.5px solid rgb(82 163 204)",
                              backgroundColor: "none",
                              color: "grey",
                              fontSize:"10px",
                              padding:"10px"
                            }}>
                            Details
                        </Button>
                        </React.Fragment>
                      }
                      placement="left-start"
                      arrow
                    >

                      <MoreVertOutlinedIcon
                        sx={{ fontSize: 22, marginLeft: "4px" }}
                      />
                    </LightTooltip> */}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )
            })}
          {type === 'unit' &&
            unitdata.map((elem) => {
              return (
                <tr
                  align="center"
                  style={{
                    backgroundColor: 'white',
                    boxShadow:
                      ' rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                  }}
                >
                  <td style={{ width: '80px' }}>
                    {' '}
                    <span>
                      <img src={location} />
                    </span>{' '}
                    <br />
                    {elem.country == null ? 'N/A' : elem.country}{' '}
                  </td>
                  <td
                    className="tbl_spoter"
                    onClick={() => {
                      detailNavigate('/listdetails', {
                        state: { param1: elem, param2: 'Unit-Prices' },
                      })
                    }}
                  >
                    {elem.price_designation == null
                      ? 'N/A'
                      : elem.price_designation}
                  </td>
                  <td>
                    {elem.category_acquisition == null
                      ? 'N/A'
                      : elem.category_acquisition}
                  </td>

                  <td>{elem.min_price == null ? 'N/A' : elem.min_price}</td>
                  <td>{elem.unit == null ? 'N/A' : elem.unit} </td>
                  <td>
                    {' '}
                    {elem.middle_price == null ? 'N/A' : elem.middle_price}
                  </td>
                  <td>N/A</td>
                  <td>{elem.years == null ? 'N/A' : elem.years}</td>
                  <td>
                    {/* <span style={{fontSize:"18px",color:"#087cb7",cursor:"pointer"}} onClick={()=>{
     detailNavigate("/listdetails",{ state: { param1: elem, param2: "unit"}});
      }}><BsFillEyeFill/></span> */}

                    {/* <LightTooltip
                      classes={{
                        tooltip: classes.customTooltip,
                        arrow: classes.customArrow,
                      }}
                      title={
                        <React.Fragment>
                          <Button
                            variant=""
                            onClick={() => {
                              detailNavigate("/listdetails", {
                                state: { param1: elem, param2: "unit" },
                              });
                            }}
                            style={{
                              border: "1.5px solid rgb(82 163 204)",
                              color: "white",
                              fontSize: "10px"
                            }}>
                            Details
                          </Button>
                        </React.Fragment>
                      }
                      placement="left-start"
                      arrow
                    >
                      <MoreVertOutlinedIcon
                        sx={{ fontSize: 22, marginLeft: "4px" }}
                      />
                    </LightTooltip> */}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </>
  )
}

export default Listview
