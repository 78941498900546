import React, { useEffect, useRef, useState, useCallback } from "react";
import Header2 from "../../components/appSpecific/header2";
import Sidebar from "../../components/appSpecific/Sidebar";
import Footer from "../../components/appSpecific/Footer";
import Projectcard from "../../components/common/Projectcard";
import Paginate from "../../components/common/Paginate";
import axios from "axios";
import Loading from "../../components/appSpecific/Loading";
import Chartboard from "../ChartBoard/Chartboard";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { languages } from "../../components/appSpecific/LanguageCode";
import Listview from "../../components/tableview/Listview";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { FaListUl } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import investimg from "../../assets/img/invest.png";
import projectimg from "../../assets/img/project.png";
import { MdOutlinePlace } from "react-icons/md";
import { Pagination, SelectPicker } from "rsuite";
import "../../assets/css/ref.css";
import { BiBuildings } from "react-icons/bi";
import { useInvestmentSearch } from "../../hooks/useInvestmentSearch";
import { debounce } from 'lodash';


function Investments() {
  const [openSide, setopenSide] = useState(false);
  const [btncolor, setBtncolor] = useState(false);
  const [show, setShow] = useState(true);
  const [search, setSearch] = useState(null);
  const [selectedCountry, setselectedCountry] = useState(null);
  const [selectedSector, setselectedSector] = useState(null);
  const countryRef = useRef();

  const {
    newInvestmentData,
    newLoading,
    newTotalDataCount,
    countryList,
    sectorList,
    getInvestmentData,
    page,
    perPage,
    setPage,
    setPerPage,
  } = useInvestmentSearch();

  useEffect(() => {
    let searchParam = search ? search : null;
    let selectedCountryParam = selectedCountry ? selectedCountry : null;
    let selectedSectorParam = selectedSector ? selectedSector : null;
    getInvestmentData(searchParam, selectedCountryParam, selectedSectorParam);
  }, [page, perPage]);

  const handleSearch = (searchValue) => {
    setSearch(searchValue);
    let selectedSectorParam = selectedSector ? selectedSector : null;
    let selectedCountryParam = selectedCountry ? selectedCountry : null;
    console.log("selectedCountryParam ", selectedCountryParam);
    getInvestmentData(searchValue, selectedCountry, selectedSectorParam);
  };

  const handler = useCallback(debounce((value) => handleSearch(value), 500), [selectedCountry]);

  const handleCountry = (e) => {
    const country_id = e.target.value;
    setselectedCountry(country_id);
    let searchParam = search ? search : null;
    let selectedSectorParam = selectedSector ? selectedSector : null;
    getInvestmentData(searchParam, country_id, selectedSectorParam);
  };

  const handleSector = (e) => {
    const sector_id = e.target.value;
    setselectedSector(sector_id);
    let searchParam = search ? search : null;
    let selectedCountryParam = selectedCountry ? selectedCountry : null;
    getInvestmentData(searchParam, selectedCountryParam, sector_id);
  };

  //>>>>>>>>>>>>>>>>>>>>>limit log for pagination....
  const limitOpt = [10, 20, 50];
  const limitOption = (data) => {
    const res = [];
    const length = newTotalDataCount;
    const allDataLength = length + (5 - (length % 5));
    for (let i = 5; i <= allDataLength; i += 5) {
      res.push(i);
    }
    return res;
  };
  const handleChangeLimit = (data) => {
    setPage(1);
    setPerPage(data);
  };

  const obj = localStorage.getItem("investement");
  const invest = JSON.parse(obj);

  //laguage Translator
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Setting page stuff counter2");
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage, t]);

  const [toggleState, setToggleState] = React.useState(false);
  const handleClickChange = (val) => {
    setToggleState(val);
  };

  return (
    <>
      <div class="wrapper">
        <Sidebar value={toggleState} openSideBar={openSide} />
        <div class="main">
          <Header2
            handleChange={handleClickChange}
            handleSideBar={() => {
              setopenSide(!openSide);
            }} />
          <main class="content">
            <div class="container-fluid p-0">
              <div class="row">
                <div class="col-12 col-xl-6 mb-5 ">
                  <span>
                    <img
                      src={investimg}
                      alt="img.."
                      style={{ position: "absolute", top: "10px", left: "18px" }}
                      height={50}
                      width={50}
                    />
                    <h3
                      class="font-weight-bold"
                      style={{
                        position: "relative",
                        top: "20px",
                        color: "",
                        left: "50px",
                      }}
                    >
                      {" "}
                      &nbsp;{t("Investments")}{" "}
                    </h3>
                  </span>
                  <h6
                    class="font-weight-normal mb-0"
                    style={{ position: "relative", top: "15px", color: "" }}>
                    {t("You have")}
                    <span class="text-primary">
                      &nbsp;{newTotalDataCount} {t("investments!")}{" "}
                    </span>
                  </h6>
                </div>
              </div>
              <div class="row">
                <div class="col col-xl-4 mb-4">
                  <form className="">
                    <input
                      className="form-control search_control"
                      type="text"
                      placeholder={t("Search")}
                      aria-label="Search"
                      // onChange={handleSearch}
                      onChange={(event) => handler(event.target.value)}
                    // value={search}
                    />
                    <span className="search_icon">
                      {" "}
                      <BsSearch />{" "}
                    </span>
                  </form>
                </div>
                <div class="col col-xl-2 mb-4" style={{ paddingLeft: "0" }}>
                  <span
                    style={{
                      position: "absolute",
                      fontSize: "20px",
                      marginLeft: "13px",
                      marginTop: "0px",
                    }}>
                    {" "}
                    <MdOutlinePlace />
                  </span>
                  <select
                    class="form-select select_country"
                    ref={countryRef}
                    aria-label="Default select example"
                    onChange={handleCountry}>
                    &nbsp;
                    <option selected value="" className="option_txt">
                      {t("All Country")}
                    </option>
                    {/* <option value=" "> All Country</option> */}
                    {countryList.map((elem) => {
                      return (
                        <>
                          <option value={elem.id}>{elem.name} </option>
                        </>
                      );
                    })}
                  </select>
                </div>

                <div class="col col-xl-2 mb-4" style={{ paddingLeft: "0" }}>
                  <span
                    style={{
                      position: "absolute",
                      fontSize: "20px",
                      marginLeft: "16px",
                      marginTop: "0px",
                      zIndex: "99999",
                    }}>
                    {" "}
                    <BiBuildings />
                  </span>
                  <select
                    class="form-select Doner_side"
                    aria-label="Default select example"
                    onChange={handleSector}>
                    <option selected value="" className="option_txt">
                      {t("All Sector")}
                    </option>
                    {sectorList.map((elem) => {
                      return (
                        <>
                          <option value={elem.id}>{elem.name} </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col col-xl-4  mb-4" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <span style={{
                    marginRight: "10px"
                  }}>

                    <SelectPicker
                      value={perPage}
                      onChange={handleChangeLimit}
                      cleanable={false}
                      searchable={false}
                      data={limitOpt.map((key) => ({ value: key, label: key }))}
                    />
                  </span>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    style={{
                      backgroundColor: btncolor ? "#56aae7" : "#F39C12",
                      borderRadius: "30px",
                      border: "none",
                      height: "32px",
                      color: "white",
                      width: "100px",
                      marginRight: "5px"
                    }}
                    onClick={() => {
                      setShow(true);
                      setBtncolor(false);
                    }}
                  >
                    <span>
                      {" "}
                      <FaListUl />
                    </span>
                    <span
                      style={{ position: "relative", left: "6px", top: "1px" }}>
                      {t("List view")}
                    </span>
                  </button>
                  &nbsp;

                  <button
                    type="button"
                    style={{
                      backgroundColor: btncolor ? "#F39C12" : "#56aae7",
                      borderRadius: "30px",
                      border: "none",
                      height: "32px",
                      color: "white",
                      width: "100px",
                      marginRight: "10px"
                    }}
                    onClick={() => {
                      setShow(false);
                      setBtncolor(true);
                    }}
                  >
                    <span>
                      {" "}
                      <BsFillGrid1X2Fill />
                    </span>
                    <span
                      style={{ position: "relative", left: "2px", top: "1px" }}
                    >
                      {" "}
                      {t("Grid view")}
                    </span>
                  </button>{" "}
                </div>
              </div>
              {show && !newLoading ? (
                <Listview
                  investdata={newInvestmentData}
                  type="invest"
                  hader1={t("Country")}
                  hader2={t("Project Classification")}
                  hader3={t("Amount ($)")}
                  hader4={t("Title")}
                  hader5={t("Sponsor")}
                  hader6={t("Readiness")}
                  hader7={t("Financing gap")}
                  hader8={t("Project Sheet")}
                  hader9={t("Buiness Plan")}
                />
              ) : (
                <div class="row">
                  {/* {(selectedCountry && !countryFilteredData.length) && (
                    <p align="center" style={{ paddingBottom: "20px", color: "#85929E" }}>
                      sorry there is no data ...
                    </p>)} */}
                  {!newLoading ? (
                    newInvestmentData.map((elem) => {
                      return (
                        <Projectcard
                          id={elem.id}
                          countrey={elem.country}
                          status={elem.project_classification_by_size}
                          Phase={elem.project_name}
                          val1={elem.total_project_value}
                          val2={elem.investors}
                          val3={elem.project_readiness_indicators}
                          val4={elem.projected_financial_gap}
                          val9={elem.financial_gap}
                          row1={t("Amount :")}
                          row2={t("Sponsor :")}
                          row3={t("Readiness :")}
                          row4={t("Financing gap :")}
                          btn1={t("Project sheet")}
                          btn2={t("Bussiness Plan")}
                          type="Investments"
                          detail1="Project Name:"
                          detail5="Projected finsncial gap:"
                          dateof_event="Date of Event:"
                          typeof_project="Type of Project:"
                          stageof_project="Stage of the Project:"
                          project_shedule="Project implementation shedule:"
                          project_classification="Project Classification(Size):"
                          project_indicator="Project readiness indicators:"
                          numberofjob="Number of jobs created:"
                          numberof_beneficies="Number of direct beneficiaries:"
                          total_project_value="Total Project Value(USD Million):"
                          amount_debt="Amount of Debt:"
                          amountof_donation="Amount of Donation($):"
                          equity="Equity:"
                          risk_mitigation="Risk mitigation($):"
                          internal_rate="Internal rate of return-IRR(%):"
                          guaranties="Guaranties :"
                          gap_debt="Gap in term of Debt (millions USD):"
                          gap_equit="Gap en term d Equity: "
                          plane_shedule="Planned schedule of bussiness operation:"
                          sponser="Project sponsor:"
                          current_investor="Current Investor:"
                          key_shedule="Other key stakeholders:"
                          lender="Lenders:"
                          investor="Investors :"
                          special="Special Purpose Vehicle(SPV):"
                          authority="Granting authority:"
                          buyer="Off-taker/Buyer:"
                          provider="Guarantee providers:"
                          operator="Developers/Operators:"
                          advisor="Transaction advisors:"
                          tech_provider="Technical assistance providers:"
                          noofjob={elem.number_of_jobs_created}
                          project_stage={elem.project_stage}
                          bussiness_plan={
                            elem.business_plan ? elem.business_plan : ""
                          }
                          project_sheet={elem.ppp}
                          routpath="/investment"
                          heading={t("Investments Summary")}
                        // summary={elem.}
                        />
                      );
                    })
                  ) : (
                    <Loading />
                  )}
                </div>
              )}
              <Pagination
                style={{}}
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                size="xs"
                layout={["pager", "skip"]}
                total={newTotalDataCount}
                limitOptions={limitOption(newInvestmentData)}
                limit={perPage}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
              {/* <Stack spacing={3}>
                <Pagination
                  count={Math.ceil(totalData / perPage)}
                  onChange={handleChangePage}
                  variant="outlined"
                  shape="rounded"
                />
              </Stack> */}
              {/* <Paginate /> */}
            </div>
          </main>
          <Chartboard />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Investments;
