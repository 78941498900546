import { Chartboard, Dashboard } from "./container";
import { SignIn } from "./container";
import { Register } from "./container";
import "./App.css";
import { Details } from "./container";
import {
  
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import { Projects } from "./container";
import { Bussinessadvisors } from "./container";
import { Tenders } from "./container";
import { Investments } from "./container";
import { Jobs } from "./container";
import { Unitprice } from "./container";
import News from "./container/News/News";
import Newsdetails from "./container/Newsdetails/Newsdetails";
import Landingpage from "./container/Landingpage/Landingpage";
import Listdetails from "./container/Listdetails/Listdetails";
import Privacy from "./container/Privacy/Privacy";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="signin" element={<SignIn />} />
          <Route path="register" element={<Register />} />
          <Route path="details" element={<Details />} />
          <Route path="listdetails" element={<Listdetails />} />
          <Route path="bussinessadvisors" element={<Bussinessadvisors />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="projects" element={<Projects />} />
          <Route path="tenders" element={<Tenders />} />
          <Route path="investment" element={<Investments />} />
          <Route path="jobs" element={<Jobs />} />
          <Route path="unitprice" element={<Unitprice />} />
          <Route path="chartboard" element={<Chartboard />} />
          <Route path="news" element={<News />} />
          <Route path="newsdetails" element={<Newsdetails />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="*" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
