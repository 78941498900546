import React from "react";
import logo from "../../assets/img/logo.svg";
import linkedin from "../../assets/img/linkedin-in.svg";
import facebook from "../../assets/img/facebook-f.svg";
import youtube from "../../assets/img/youtube.svg";
import bg1 from "../../assets/img/bg.png";
import bg2 from "../../assets/img/bg.png";
import Languages from "./HeaderComponents/Languages";
import { NavLink} from "react-router-dom";


function Header() {
  return (
      <>
      <div className="bg-1">
        <img src={bg1} alt="" />
      </div>
      <div className="bg-2">
        <img src={bg2} alt="" />
      </div>

      <NavLink to="/">
      <div className="left_top_bar">
        <img src={logo} alt="" />
      </div>
      </NavLink>

      <div className="right_top_bar">
         <Languages />
        <ul className="social">
          <li>
    
          <a href="#">
          <img src={linkedin} alt="" />
          </a>
          </li>
          <li>
            <a href="#">
              <img src={facebook} alt="" />
            </a>
          </li>
          <li>
          <a href="#">
          <img src={youtube} alt="" />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Header;
