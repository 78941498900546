import usFlag from "../../assets/img/flags/us.png";
import deFlag from "../../assets/img/flags/de.png";
import nlFlag from "../../assets/img/flags/nl.png";
import esFlag from "../../assets/img/flags/es.png";

export const languages = [
  {
    code: "en",
    name: "EN",
    // flag: usFlag,
    flang: "english",
  },
  {
    code: "fr",
    name: "FR",
    // flag: esFlag,
    flang: "franch",
  }
  // {
  //   code: "du",
  //   name: "Dutch",
  //   flag: nlFlag,
  //   flang: "dutch",
  // },
  // {
  //   code: "ge",
  //   name: "German",
  //   flag: deFlag,
  //   flang: "german",
  // },
];

export default {
  languages,
};
