import React from 'react'
import Header3 from '../../components/appSpecific/Header3'
import banner from '../../assets/img/Group 26.png'
import subbanner1 from '../../assets/img/Group 22.png'
import subbanner2 from '../../assets/img/Group 24.png'
import subbanner3 from '../../assets/img/Group 25.png'
import subbanner4 from '../../assets/img/Group 23.png'
import icon from '../../assets/img/Layer 2.svg'
import { Player } from 'video-react'
// import Footer from '../../components/appSpecific/Footer';
import Footerlanding from '../../components/appSpecific/Footerlanding'
import { NavLink } from 'react-router-dom'
import vector from '../../assets/img/Groupbackvector.png'
import bfavideopoints from '../../assets/videos/bfavideo.mp4'

const Landingpage = () => {
  return (
    <>
      <Header3 />
      <div className="banner">
        <div className="row  row_tag">
          <div className="col-lg-6 ">
            <div className="text_box_bnr">
              <div className="txt_form">
                <h1> Business For Africa</h1>
                <br />
                <div className="sub_txt">
                  <p>
                    {' '}
                    offers you innovative services <br /> to develop your
                    activities in Africa{' '}
                  </p>
                </div>
                <br />
                <div>
                  <NavLink to="/signin">
                    <span>
                      {' '}
                      <button className="login_btm">Login </button>
                    </span>
                  </NavLink>
                  <NavLink to="/register">
                    <span>
                      {' '}
                      <button className="signup_btm">Register</button>
                    </span>
                  </NavLink>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6"
            style={{ height: '90vh', userSelect: 'auto' }}
          >
            <div></div>
            <video
              src={bfavideopoints}
              autoPlay={'autoplay'}
              preLoad="auto"
              muted="auto"
              loop
              className="video_bfa"
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '95%',
                border: 'none',
                position: 'relative',
                top: '17%',
                clipPath: 'inset(1px 1px)',
                top: '50%',
                transform: 'translateY(-50%)',
                right: '6%',
              }}
            >
              {' '}
              something
            </video>
            {/* <img src={vector} alt="..." height="100%" width="100%" style={{position:'relative',left:'-10%',top:'30%'}} />  */}
          </div>
        </div>
      </div>

      <div class="container back_con">
        <br />
        <div class="row ">
          <div class="col-md-6 mt-12">
            <img alt="..." src={subbanner1} height="95%" width="100%" />
          </div>
          <div class="col-md-6 col_stack">
            <div className="col_txt">
              <h1>Business for Africa</h1>
              <br />
              <p>
                {' '}
                The Fourth Industrial Revolution has transformed actors and
                business opportunities, especially after the COVID19 crisis. The
                Business for Africa (BFA) platform is a smart platform that aims
                to change the way people access information on African
                countries, business opportunities, jobs, investments, financing
                and knowledge in Africa. The BFA platform is conducive to the
                entry into force of the African Continental Free Trade Agreement
                (AFCTFA).{' '}
              </p>
            </div>
          </div>
        </div>

        <div class="row mt-6">
          <div class="col-md-6 col_stack">
            <div className="col_txt">
              <h1>Our Service</h1>
              <br />
              <p>
                The Business for Africa (BFA) platform aims to offer its clients
                the best services to develop internationally and invest in
                Africa. BFA wants to be the reference and the main channel for
                the development in Africa of consultants/experts, design
                offices, companies, suppliers, traders and investors by offering
                them adequate and personalized intelligent services.{' '}
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <img alt="..." src={subbanner2} height="95%" width="100%" />
          </div>
        </div>

        <div class="row mt-6 ">
          <div class="col-md-6">
            <img alt="..." src={subbanner3} height="85%" width="100%" />
          </div>
          <div class="col-md-6 col_stack">
            <div className="col_txt">
              <h1>Value Proposition</h1>
              <br />
              <br />
              <p>
                {' '}
                The added value of the platform for companies, investors,
                traders and experts will be through:{' '}
              </p>
              <ul className="Value_list">
                <li>
                  {' '}
                  Personalized access to business and job opportunities as soon
                  as they are published and anticipate the collection of future
                </li>
                <li>
                  {' '}
                  opportunities Online advice and support for preparing offers
                  and monitoring;
                </li>
                <li>
                  Online advice and support for preparing offers and monitoring
                  contracts and payments;{' '}
                </li>
                <li>
                  {' '}
                  contracts and payments; Provide useful information on
                  countries, their procurement systems, markets, statistics,
                  etc.;
                </li>
                <li>
                  {' '}
                  Facilitate the investment process and the search for
                  financing; Provide/produce economic, sectoral studies, market
                  studies,
                </li>
                <li>
                  {' '}
                  Access to quality expertise mobilized via the platform; etc.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row mt-6">
          <div class="col-md-6 col_stack">
            <div className="col_txt">
              <h1>Our Skills</h1>
              <br />
              <br />
              <p>
                {' '}
                The BFA platform, supported by international skills, has a vast
                network of partners and sectoral and functional experts,
                specialists in Africa and who can be mobilized within the IT
                platform, to best serve its customers. We have also selected
                experts with in-depth expertise in their sectors and African
                emerging markets who will be part of the platform's management
                team and board of directors.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <img alt="..." src={subbanner4} height="95%" width="100%" />
          </div>
        </div>
      </div>
      <br />
      <div className="title_offer">
        <h1 align="center"> A COMPLETE OFFER</h1>
      </div>
      <div class="container backstack_card">
        <div class="row d-flex justify-content-center mt-3">
          <div class="col-lg-6 col-sm-12 ">
            <div class="card card_landing">
              <div class="card-body">
                <h5 class="card-title">
                  <span>
                    {' '}
                    <img src={icon} height="13%" width="13%" alt="..." />{' '}
                  </span>
                  &nbsp;&nbsp;&nbsp;{' '}
                  <span className="title_land"> Individual Consultants </span>{' '}
                </h5>
                <br />
                <p class="card-text">
                  The BFA platform, supported by international skills, has a
                  vast network of partners and sectoral and functional experts,
                  specialists in Africa and who can be mobilized within the IT
                  platform, to best serve its customers. We have also selected
                  experts with in-depth expertise in their sectors and African
                  emerging markets who will be part of the platform's management
                  team and board of directors.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="card card_landing">
              <div class="card-body">
                <h5 class="card-title">
                  {' '}
                  <span>
                    {' '}
                    <img src={icon} height="13%" width="13%" alt="..." />{' '}
                  </span>{' '}
                  &nbsp;&nbsp;&nbsp;{' '}
                  <span className="title_land"> Investors and Funds </span>{' '}
                </h5>
                <br />
                <p class="card-text">
                  The platform aims to support your projects to make them
                  bankable, to help you raise capital and to accelerate the
                  realization of your transactions. The Investment Tracker will
                  allow you to track your admitted investments in the platform.
                  The platform will connect you with top experts to help bring
                  your deals to fruition and investors to help you raise funds.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3 d-flex justify-content-center">
          <div class="col-lg-6 col-sm-12">
            <div class="card card_landing">
              <div class="card-body">
                <h5 class="card-title">
                  {' '}
                  <span>
                    {' '}
                    <img src={icon} height="13%" width="13%" alt="..." />{' '}
                  </span>{' '}
                  &nbsp;&nbsp;&nbsp;{' '}
                  <span className="title_land"> Offices and Companies </span>
                </h5>
                <br />
                <p class="card-text">
                  We provide you with high-level consultants in all development
                  sectors. In your personalized site, the platform will provide
                  you with information on projects, investments, opportunities,
                  countries, markets, sectors, etc. The platform uses artificial
                  intelligence to better bid, know the competition, access unit
                  prices, documents, etc.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="card card_landing">
              <div class="card-body">
                <h5 class="card-title">
                  {' '}
                  <span>
                    {' '}
                    <img src={icon} height="13%" width="13%" alt="..." />{' '}
                  </span>{' '}
                  &nbsp;&nbsp;&nbsp;{' '}
                  <span className="title_land"> Banks and Insurance</span>
                </h5>
                <br />
                <p class="card-text">
                  The platform will allow you to get in touch with the right
                  customers to provide them with banking and insurance services
                  through the online simulation tool. You can also access
                  quality expertise mobilized via the platform. The platform
                  will provide you with relevant information on countries,
                  companies, projects, investments,
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <Footerlanding />
    </>
  )
}

export default Landingpage
