import React from "react";
import { NavLink } from "react-router-dom";
import avatar from "../../../assets/img/avatars/avatar.jpg";

function Profile() {
  const obj = localStorage.getItem("name");
  const name = JSON.parse(obj);
  // console.log(obj);

  return (
    <>
      <li className="nav-item dropdown">
        <a
          className="nav-icon dropdown-toggle d-inline-block d-sm-none"
          href="#"
          data-toggle="dropdown"
        >
          <i className="align-middle" data-feather="settings"></i>
        </a>

        <a
          className="nav-link dropdown-toggle d-none d-sm-inline-block"
          href="#"
          data-toggle="dropdown"
        >
          <img
            src={avatar}
            className="avatar img-fluid rounded-circle mr-1"
            alt="Chris Wood"
          />{" "}
          <span className="text-dark">{name}</span>
        </a>
        <div className="dropdown-menu dropdown-menu-right">
          <a className="dropdown-item" href="#">
            <i className="align-middle mr-1" data-feather="user"></i> Profile
          </a>
          {/* <a className="dropdown-item" href="/"> */}
          <NavLink className="dropdown-item" to="/">
            Sign out
          </NavLink>
          {/* Sign out */}
          {/* </a> */}
        </div>
      </li>
    </>
  );
}

export default Profile;
