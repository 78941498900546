import React, { useEffect, useRef, useState, useCallback } from "react";
import Header2 from "../../components/appSpecific/header2";
import Sidebar from "../../components/appSpecific/Sidebar";
import Footer from "../../components/appSpecific/Footer";
import Projectcard from "../../components/common/Projectcard";
import Loading from "../../components/appSpecific/Loading";
import Chartboard from "../ChartBoard/Chartboard";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { languages } from "../../components/appSpecific/LanguageCode";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { FaListUl } from "react-icons/fa";
import Listview from "../../components/tableview/Listview";
import { BsSearch } from "react-icons/bs";
import jobimg from "../../assets/img/job.png";
import { MdOutlinePlace } from "react-icons/md";
import { Pagination, SelectPicker } from "rsuite";
import "../../assets/css/ref.css";
import { BiBuildings } from "react-icons/bi";
import { useJobSearch } from "../../hooks/useJobSearch";
import { debounce } from 'lodash';


function Jobs() {
  const [openSide, setopenSide] = useState(false);
  const [btncolor, setBtncolor] = useState(false);
  const [show, setShow] = useState(true);
  const [search, setSearch] = useState(null);
  const [selectedCountry, setselectedCountry] = useState(null);
  const [selectedSector, setselectedSector] = useState(null);
  const countryRef = useRef();

  const {
    newJobData,
    newLoading,
    newTotalDataCount,
    countryList,
    sectorList,
    getJobData,
    page,
    perPage,
    setPage,
    setPerPage,
  } = useJobSearch();

  useEffect(() => {
    let searchParam = search ? search : null;
    let selectedCountryParam = selectedCountry ? selectedCountry : null;
    let selectedSectorParam = selectedSector ? selectedSector : null;
    getJobData(searchParam, selectedCountryParam, selectedSectorParam);
  }, [page, perPage]);

  const handleSearch = (searchValue) => {
    setSearch(searchValue);
    let selectedSectorParam = selectedSector ? selectedSector : null;
    let selectedCountryParam = selectedCountry ? selectedCountry : null;
    console.log("selectedCountryParam ", selectedCountryParam);
    getJobData(searchValue, selectedCountry, selectedSectorParam);
  };

  const handler = useCallback(debounce((value) => handleSearch(value), 1000), [selectedCountry]);

  const handleCountry = (e) => {
    const country_id = e.target.value;
    setselectedCountry(country_id);
    let searchParam = search ? search : null;
    let selectedSectorParam = selectedSector ? selectedSector : null;
    getJobData(searchParam, country_id, selectedSectorParam);
  };

  const handleSector = (e) => {
    const sector_id = e.target.value;
    setselectedSector(sector_id);
    let searchParam = search ? search : null;
    let selectedCountryParam = selectedCountry ? selectedCountry : null;
    getJobData(searchParam, selectedCountryParam, sector_id);
  };


  //>>>>>>>>>>>>>>>>>>>>>limit log for pagination....
  const limitOpt = [10, 20, 50];
  const limitOption = (data) => {
    const res = [];
    const length = newTotalDataCount;
    const allDataLength = length + (5 - (length % 5));
    for (let i = 5; i <= allDataLength; i += 5) {
      res.push(i);
    }
    return res;
  };
  const handleChangeLimit = (data) => {
    setPage(1);
    setPerPage(data);
  };


  const [toggleState, setToggleState] = React.useState(false);
  const handleClickChange = (val) => {
    setToggleState(val);
  };

  const obj = localStorage.getItem("jobs");
  const jobss = JSON.parse(obj);


  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Setting page stuff counter2");
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage, t]);

  return (
    <>
      <div class="wrapper">
        <Sidebar value={toggleState} openSideBar={openSide} />
        <div class="main">
          <Header2
            andleChange={handleClickChange}
            handleSideBar={() => {
              setopenSide(!openSide);
            }}
          />
          <main class="content">
            <div class="container-fluid p-0">
              <div class="row">
                <div class="col-12 col-xl-9 mb-5">
                  <span style={{ position: "relative", top: "-10px" }}>
                    <img
                      src={jobimg}
                      alt="img.."
                      style={{ position: "absolute", top: "7px" }}
                      height={70}
                      width={60}
                    />
                    <h3
                      class="font-weight-bold"
                      style={{
                        position: "relative",
                        top: "30px",
                        color: "",
                        left: "65px",
                      }}
                    >
                      {" "}
                      &nbsp;{t("Jobs")}{" "}
                    </h3>
                  </span>
                  <h6
                    class="font-weight-normal mb-0"
                    style={{ position: "relative", top: "15px", color: "" }}
                  >
                    {t("You have")}
                    <span class="text-primary">
                      &nbsp;{newTotalDataCount} {t("jobs!")}
                    </span>
                  </h6>
                </div>
              </div>
              <div class="row">
                <div class="col col-xl-4 mb-4">
                  <form className="">
                    <input
                      className="form-control search_control"
                      type="text"
                      placeholder={t("Search")}
                      aria-label="Search"
                      onChange={(event) => handler(event.target.value)} />
                    <span className="search_icon">
                      {" "}
                      <BsSearch />{" "}
                    </span>
                  </form>
                </div>

                <div class="col col-xl-2 mb-4" style={{ paddingLeft: "0" }}>
                  <spanr
                    style={{
                      position: "absolute",
                      fontSize: "20px",
                      marginLeft: "13px",
                      marginTop: "0px",
                    }}
                  >
                    {" "}
                    <MdOutlinePlace />
                  </spanr>

                  <select
                    class="form-select select_country"
                    ref={countryRef}
                    aria-label="Default select example"
                    onChange={handleCountry}>
                    &nbsp;
                    <option selected value="" className="option_txt">
                      {t("All Country")}
                    </option>
                    {countryList.map((elem) => {
                      return (
                        <>
                          <option value={elem.id}>{elem.name} </option>
                        </>
                      );
                    })}
                  </select>
                </div>

                <div class="col col-xl-2 mb-4" style={{ paddingLeft: "0" }}>
                  <span
                    style={{
                      position: "absolute",
                      fontSize: "20px",
                      marginLeft: "16px",
                      marginTop: "0px",
                      zIndex: "99999",
                    }}>
                    {" "}
                    <BiBuildings />
                  </span>
                  <select
                    class="form-select Doner_side"
                    aria-label="Default select example"
                    onChange={handleSector}>
                    <option selected value="" className="option_txt">
                      {t("All Sector")}
                    </option>
                    {sectorList.map((elem) => {
                      return (
                        <>
                          <option value={elem.id}>{elem.name} </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className="col col col-xl-4 mb-4" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <span style={{
                    marginRight: "20px"
                  }}>
                    <SelectPicker
                      value={perPage}
                      onChange={handleChangeLimit}
                      cleanable={false}
                      searchable={false}
                      data={limitOpt.map((key) => ({ value: key, label: key }))}
                    />
                  </span>
                  <button
                    type="button"
                    style={{
                      backgroundColor: btncolor ? "#56aae7" : "#F39C12",
                      borderRadius: "30px",
                      border: "none",
                      height: "32px",
                      color: "white",
                      width: "100px",
                      marginRight: "10px"
                    }}
                    onClick={() => {
                      setShow(true)
                      setBtncolor(false)
                    }}>
                    <span>
                      {" "}
                      <FaListUl />
                    </span>{" "}
                    <span
                      style={{ position: "relative", left: "2px", top: "1px" }}>
                      {t("List view")}{" "}
                    </span>
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    style={{
                      backgroundColor: btncolor ? "#F39C12" : "#56aae7",
                      borderRadius: "30px",
                      border: "none",
                      height: "32px",
                      color: "white",
                      width: "100px",
                      marginRight: "10px"
                    }}
                    onClick={() => {
                      setShow(false)
                      setBtncolor(true)
                    }}
                  >
                    <span>
                      {" "}
                      <BsFillGrid1X2Fill />
                    </span>{" "}
                    <span
                      style={{ position: "relative", left: "2px", top: "1px" }}>
                      {" "}
                      {t("Grid view")}
                    </span>
                  </button>{" "}


                </div>
              </div>
              {show && !newLoading ? (
                <Listview
                  jobdata={newJobData}
                  type={t("jobs")}
                  hader1={t("Country")}
                  hader2={t("Job Type")}
                  hader3={t("Organization")}
                  hader4={t("Title")}
                  hader5={t("Location")}
                  hader6={t("Publication")}
                  hader7={t("Deadline")}
                  hader8={t("Link")}
                />
              ) : (
                <div class="row">
                  {!newLoading ? (
                    newJobData.map((elem) => {
                      return (
                        <Projectcard
                          id={elem.id}
                          countrey={elem.country}
                          Report={elem.address}
                          status={elem.type}
                          Phase={elem.job_title}
                          row1={t("Organisation :")}
                          row2={t("Location :")}
                          row3={t("Publication :")}
                          row4={t("Deadline :")}
                          val1={elem.organization}
                          val2={elem.address}
                          val3={elem.publication_date}
                          val4={elem.apply_by}
                          btn1={t("Link")}
                          btn2={t("Details")}
                          type="Jobs"
                          link={elem.web_link}
                          Countreyid={elem.country_id}
                          detail1="Position title:"
                          detail4="Country:"
                          detail5="Organisation:"
                          publication_date="Publication Date:"
                          date_submision="Date of Submission:"
                          continet="Continetnt:"
                          sector1="Sector:"
                          typee="Type:"
                          experience="Experience:"
                          location="Location:"
                          linnk="Link"
                          experiencee={elem.experience}
                          liimk={elem.web_link}
                          heading={t("Job Description")}
                          summary={elem.job_description}
                          routpath="/jobs"
                        />
                      );
                    })
                  ) : (
                    <Loading />
                  )}
                </div>
              )}
              {/* <div className="row">
                </div>
              {} */}
              <Pagination
                style={{}}
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                size="xs"
                layout={["pager", "skip"]}
                total={newTotalDataCount}
                limitOptions={limitOption(newJobData)}
                limit={perPage}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </main>
          <Chartboard />
          <Footer />
        </div>
      </div>
    </>
  );
}
export default Jobs;
