import React, { useEffect, useState } from 'react'
import Header2 from '../../components/appSpecific/header2'
import Sidebar from '../../components/appSpecific/Sidebar'
import Footer from '../../components/appSpecific/Footer'
import bussinessAdvisors from '../../assets/img/catagory/Business_advisors.svg'
import projects from '../../assets/img/catagory/Projects.svg'
import tenders from '../../assets/img/catagory/Tenders.svg'
import investments from '../../assets/img/catagory/Investments.svg'
import jobs from '../../assets/img/catagory/Jobs.svg'
import unitPrices from '../../assets/img/catagory/Unit-prices.svg'
import news from '../../assets/img/catagory/News.svg'
import Dashboardcard from '../../components/common/Dashboardcard'
import axios from 'axios'
import Chartboard from '../ChartBoard/Chartboard'
import { useTranslation, withSSR } from 'react-i18next'
import cookies from 'js-cookie'
import { languages } from '../../components/appSpecific/LanguageCode'
//import "../../assets/css/style.css";
//import BackgroundImg from "../.././assets/img/bg.jpg";

export const ToggleClick = React.createContext(null)

function Dashboard() {
  const [res, setRes] = useState('')

  const dashBoardFun = () => {
    axios
      .get('https://api.bfa-platform.com/api/a/dashboard/counts2', {
        headers: {
          Authorization:
            'Bearer ' +
            'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiZjZiZGNjZTU1YmU0ZmEyNTdjMDY3YTEzOGQzM2U0NmIzMGQzNTlkYmQ1MjNjMjIzMmM4ZDY0N2EyZDU1ZTc5NTE5YjIwNDk0NjBlYmQxYmQiLCJpYXQiOjE2NDgxMTEyNDQuOTA4NDIzLCJuYmYiOjE2NDgxMTEyNDQuOTA4NDI2LCJleHAiOjE2Nzk2NDcyNDQuOTA1NTIsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.nJQncSRw9bxjv2eZK1hBHT3SaTTlC7uOKoryVVH0TsqzixhvKqZasOWeIgcHX6DFVKEtD3l4Su_NfS1hRIALrvivh105I45B9ZEzFW2gZVc5OdwpE4DR8f_z8mam7jPJLhDR7wrlWPXHbQrjscX7l5IQpO-KUT8wIuKyEXkwiKCBT8XIcCh6csmUIuGAtHNgJhlSxrUtmJlWgxkorWPCdc9gfBKsxiaCfNAkHdEGjg_lthxXWaq0pciwnR0HPzyZvRdWr4DqNI7CYMSkE5Gdy0FQi_xTsQvq7dqPj3iqZs0A6wJo1Dz5XGDAyLNoC24qgi8mKDnZ7GKEQkAaifq9G-ayLk8PpVZIbLg_aX-12oxcmMLIesuKN4_cUyKLDv_oeS1Zr5Fs-yOXUWIoowadZHWe4ZTO5O-OlfhiFt-XyYVUT3IS7U80LM8GTculsS2VIPQvlc_lOSpSbTPD5lvjc6oWXBfAAomdWHiu01Y0Mzwz0oeUnEGauzyZ-YoOO0SVJB37U6o0FKd1yVn_Ge-vUeJrJgaNJ6OVsxcaG9cmttZKmqDKxEmrG-uTmnJlI6YbrY-o2WztnMEhOQkpvLWwV667Iylm18xFINsOrR38Lta7sVVxbLRsizczH1hpqOAk571XONzYc0oczIbws1EyM2h1hx5yOw4X4kCF0GOGoI0',
        },
      })
      .then((res) => {
        localStorage.setItem('project', JSON.stringify(res.data.project))
        localStorage.setItem('tender', JSON.stringify(res.data.opportunities))
        localStorage.setItem(
          'investement',
          JSON.stringify(res.data.investements),
        )
        localStorage.setItem('jobs', JSON.stringify(res.data.jobs))
        localStorage.setItem('unit', JSON.stringify(res.data.prix))

        console.log('Success Dashboard', res)
        setRes(res.data)
        console.log(res.data)
      })
      .catch((e) => {
        console.log('Error--->', e.response.data)
      })
  }

  useEffect(() => {
    dashBoardFun()
  }, [])

  const obj = localStorage.getItem('name')
  const name = JSON.parse(obj)

  //laguage Translator
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()
  const [openSide, setopenSide] = useState(false)

  useEffect(() => {
    console.log('Setting page stuff counter2')
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage, t])

  const [toggleState, setToggleState] = React.useState(false)
  const handleClickChange = (val) => {
    setToggleState(val)
  }


  
  return (
    <div className="wrapper">
      <Sidebar value={toggleState} openSideBar={openSide} />

      <div className="main">
        <Header2
          handleChange={handleClickChange}
          handleSideBar={() => {
            setopenSide(!openSide)
          }}
        />

        <main className="content">
          {/* <img src={BackgroundImg} className="bg_jpg" alt="..."/> */}
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12 col-xl-8 mb-4 ">
                <h3 className="font-weight-bold">
                  {t('Welcome')} {name}
                </h3>
                <h6 className="font-weight-normal mb-0">
                  {t('You have')}
                  <span className="text-primary"> 6 {t('Tools')}</span>
                </h6>
              </div>
            </div>
            <div className="row">
              {/* <Dashboardcard
                  navlink="/bussinessadvisors"
                  image={bussinessAdvisors}
                  title={t("Business Advisors")}
                  subtitle="Have a chat with youradvisors and client supports"
                  date="10 /01/ 2022"
                  total="15"
                /> */}
              <Dashboardcard
                navlink="/projects"
                image={projects}
                title={t('Projects')}
                subtitle={t('List of projects')}
                date={new Date().toLocaleString().slice(0,10)}
                total={res.project}
              />
              <Dashboardcard
                navlink="/tenders"
                image={tenders}
                title={t('Tenders')}
                subtitle={t('Search the best tenders')}
                date={new Date().toLocaleString().slice(0,10)}
                total={res.opportunities}
              />
              <Dashboardcard
                navlink="/investment"
                image={investments}
                title={t('Investments')}
                subtitle={t('Select the best investments')}
                date={new Date().toLocaleString().slice(0,10)}
                total={res.investements}
              />
              <Dashboardcard
                navlink="/jobs"
                image={jobs}
                title={t('Jobs')}
                subtitle={t('Look for a job')}
                date={new Date().toLocaleString().slice(0,10)}
                total={res.jobs}
              />
              <Dashboardcard
                navlink="/unitprice"
                image={unitPrices}
                title={t('Unit-prices')}
                subtitle={t('Search the best unit-prices')}
                date={new Date().toLocaleString().slice(0,10)}
                total={res.prix}
              />
              <Dashboardcard
                navlink="/news"
                image={news}
                title={t('News')}
                subtitle={t('Search the best news')}
                date={new Date().toLocaleString().slice(0,10)}
                total={res.post}
              />
            </div>
          </div>
        </main>

        <Chartboard />
        <Footer />
      </div>
    </div>
  )
}

export default Dashboard
