import React, { useEffect, useState, useRef, useCallback } from 'react'
import Header2 from '../../components/appSpecific/header2'
import Sidebar from '../../components/appSpecific/Sidebar'
import Footer from '../../components/appSpecific/Footer'
import Projectcard from '../../components/common/Projectcard'
import axios from 'axios'
import Loading from '../../components/appSpecific/Loading'
import Chartboard from '../ChartBoard/Chartboard'
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { languages } from '../../components/appSpecific/LanguageCode'
import Listview from '../../components/tableview/Listview'
import { BsFillGrid1X2Fill } from 'react-icons/bs'
import { FaListUl } from 'react-icons/fa'
import { BsSearch } from 'react-icons/bs'
import tenderimg from '../../assets/img/tender.png'
import { MdOutlinePlace } from 'react-icons/md'
import { Pagination, SelectPicker } from 'rsuite'
import '../../assets/css/ref.css'
import { BiBuildings } from 'react-icons/bi'
import { useTenderSearch } from '../../hooks/useTenderSearch'
import { debounce } from 'lodash'

function Tenders() {
  const [openSide, setopenSide] = useState(false)
  const [btncolor, setBtncolor] = useState(false)
  const [show, setShow] = useState(true)
  const [search, setSearch] = useState(null)
  const [selectedCountry, setselectedCountry] = useState(null)
  const [selectedSector, setselectedSector] = useState(null)
  const countryRef = useRef()

  const {
    newTenderData,
    newLoading,
    newTotalDataCount,
    countryList,
    sectorList,
    getTenderData,
    page,
    perPage,
    setPage,
    setPerPage,
  } = useTenderSearch()

  useEffect(() => {
    let searchParam = search ? search : null
    let selectedCountryParam = selectedCountry ? selectedCountry : null
    let selectedSectorParam = selectedSector ? selectedSector : null
    getTenderData(searchParam, selectedCountryParam, selectedSectorParam)
  }, [page, perPage])

  const handleSearch = (searchValue) => {
    setSearch(searchValue)
    let selectedSectorParam = selectedSector ? selectedSector : null
    let selectedCountryParam = selectedCountry ? selectedCountry : null
    console.log('selectedCountryParam ', selectedCountryParam)
    getTenderData(searchValue, selectedCountry, selectedSectorParam)
  }

  const handler = useCallback(
    debounce((value) => handleSearch(value), 1000),
    [selectedCountry],
  )

  const handleCountry = (e) => {
    const country_id = e.target.value
    setselectedCountry(country_id)
    let searchParam = search ? search : null
    let selectedSectorParam = selectedSector ? selectedSector : null
    getTenderData(searchParam, country_id, selectedSectorParam)
  }

  const handleSector = (e) => {
    const sector_id = e.target.value
    setselectedSector(sector_id)

    let searchParam = search ? search : null
    let selectedCountryParam = selectedCountry ? selectedCountry : null
    getTenderData(searchParam, selectedCountryParam, sector_id)
  }

  //>>>>>>>>>>>>>>>>>>>>>limit log for pagination....
  const limitOpt = [10, 20, 50]
  const limitOption = (data) => {
    const res = []
    const length = newTotalDataCount
    const allDataLength = length + (5 - (length % 5))
    for (let i = 5; i <= allDataLength; i += 5) {
      res.push(i)
    }
    return res
  }

  const handleChangeLimit = (data) => {
    setPage(1)
    setPerPage(data)
  }

  const obj = localStorage.getItem('tender')
  const tenderdata = JSON.parse(obj)

  //laguage Translator
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    console.log('Setting page stuff counter2')
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage, t])

  const [toggleState, setToggleState] = React.useState(false)
  const handleClickChange = (val) => {
    setToggleState(val)
  }

  return (
    <>
      <div class="wrapper">
        <Sidebar value={toggleState} openSideBar={openSide} />
        <div class="main">
          <Header2
            handleChange={handleClickChange}
            handleSideBar={() => {
              setopenSide(!openSide)
            }}
          />
          <main class="content">
            <div class="container-fluid p-0">
              <div class="row">
                <div class="col-7 col-xl-6 ">
                  <span>
                    <img
                      src={tenderimg}
                      alt="img.."
                      style={{ position: 'absolute', top: '13px' }}
                      height={50}
                      width={50}
                    />
                    <h3
                      class="font-weight-bold"
                      style={{
                        position: 'relative',
                        top: '20px',
                        color: '',
                        left: '50px',
                      }}
                    >
                      {' '}
                      &nbsp;{t('Tenders')}{' '}
                    </h3>
                  </span>
                  <h6
                    class="font-weight-normal mb-0"
                    style={{ position: 'relative', top: '15px', color: '' }}
                  >
                    {' '}
                    {t('You have')}{' '}
                    <span class="text-primary">
                      {newTotalDataCount} {t('tender!')}
                    </span>
                  </h6>
                </div>
              </div>
              <div class="col col-xl-3 mb-5"></div>
            </div>

            <div class="row">
              <div class="col col-xl-4 mb-4">
                <form className="">
                  <input
                    className="form-control search_control"
                    type="text"
                    placeholder={t('Search')}
                    aria-label="Search"
                    // onChange={handleSearch}
                    onChange={(event) => handler(event.target.value)}
                  />
                  <span className="search_icon">
                    {' '}
                    <BsSearch />{' '}
                  </span>
                </form>
              </div>
              <div class="col col-xl-2 mb-4" style={{ paddingLeft: '0' }}>
                <span
                  style={{
                    position: 'absolute',
                    fontSize: '20px',
                    marginLeft: '13px',
                    marginTop: '0px',
                  }}
                >
                  {' '}
                  <MdOutlinePlace />
                </span>
                <div>
                  <select
                    class="form-select select_country"
                    ref={countryRef}
                    aria-label="Default select example"
                    // onChange={(e) => TenderFun(e.target.value)}
                    onChange={handleCountry}
                  >
                    &nbsp;
                    <option value="" className="option_txt">
                      {t('All Country')}
                    </option>
                    {countryList.map((elem) => {
                      return (
                        <>
                          <option value={elem.id}>{elem.name} </option>
                        </>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div class="col col-xl-2 mb-4" style={{ paddingLeft: '0' }}>
                <span
                  style={{
                    position: 'absolute',
                    fontSize: '20px',
                    marginLeft: '16px',
                    marginTop: '0px',
                    zIndex: '99999',
                  }}
                >
                  {' '}
                  <BiBuildings />
                </span>
                <select
                  class="form-select Doner_side"
                  aria-label="Default select example"
                  onChange={handleSector}
                >
                  <option selected value="" className="option_txt">
                    {t('All Sector')}
                  </option>
                  {sectorList.map((elem) => {
                    return (
                      <>
                        <option value={elem.id}>{elem.name} </option>
                      </>
                    )
                  })}
                </select>
              </div>

              {/* <div class="col col-xl-2 mb-4">
              </div> */}
              <div
                class="col col-xl-4 mb-4"
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <span style={{ marginRight: '10px' }}>
                  <SelectPicker
                    value={perPage}
                    onChange={handleChangeLimit}
                    cleanable={false}
                    searchable={false}
                    data={limitOpt.map((key) => ({ value: key, label: key }))}
                  />
                </span>
                &nbsp;&nbsp;
                <button
                  type="button"
                  style={{
                    backgroundColor: btncolor ? '#56aae7' : '#F39C12',
                    borderRadius: '30px',
                    border: 'none',
                    height: '32px',
                    color: 'white',
                    width: '100px',
                    marginRight: '5px',
                  }}
                  onClick={() => {
                    setShow(true)
                    setBtncolor(false)
                  }}
                >
                  <span>
                    {' '}
                    <FaListUl />
                  </span>{' '}
                  <span
                    style={{ position: 'relative', left: '2px', top: '1px' }}
                  >
                    {' '}
                    {t('List view')}{' '}
                  </span>
                </button>
                <button
                  type="button"
                  // class="btn btn-primary active grid_view"
                  style={{
                    backgroundColor: btncolor ? '#F39C12' : '#56aae7',
                    borderRadius: '30px',
                    border: 'none',
                    height: '32px',
                    color: 'white',
                    width: '100px',
                    marginRight: '10px',
                  }}
                  onClick={() => {
                    setShow(false)
                    setBtncolor(true)
                  }}
                >
                  <span>
                    {' '}
                    <BsFillGrid1X2Fill />
                  </span>{' '}
                  <span
                    style={{ position: 'relative', left: '2px', top: '2px' }}
                  >
                    {' '}
                    {t('Grid view')}{' '}
                  </span>
                </button>{' '}
              </div>
              <br />
              <br />
              {show && !newLoading ? (
                <div class="row">
                  <Listview
                    data={newTenderData}
                    type="tender"
                    hader1={t('Country')}
                    hader2={t('Type')}
                    hader3={t('Selection')}
                    hader4={t('Title')}
                    hader5={t('Donor')}
                    hader6={t('Sector')}
                    hader7={t('Amount ($)')}
                    hader8={t('Launch date')}
                    hader9={t('Deadline')}
                    hader10={t('Documents')}
                  />
                </div>
              ) : (
                <div class="row">
                  {/* {(selectedCountry && !countryFilteredData.length) && (
                    <p align="center" style={{ paddingBottom: "20px", color: "#85929E" }}>
                      sorry there is no data ...
                    </p>
                  )} */}
                  {!newLoading ? (
                    newTenderData.map((elem) => {
                      return (
                        <Projectcard
                          countrey={elem.country}
                          donor={elem.bailleur}
                          project={elem.project}
                          status={elem.selection_mode}
                          Report={elem.category_acquisition}
                          Phase={elem.acquisition_title}
                          // val1={elem.sector ? elem.sector : "N/A"}
                          val2={elem.sector ? elem.sector : 'N/A'}
                          // val3={elem.ftq ? elem.ftq : "N/A"}
                          val4={elem.publication_date}
                          val5={
                            elem.submission_date ? elem.submission_date : 'N/A'
                          }
                          val7={elem.country_decision_maker_name}
                          val8={elem.start_date}
                          // row1={t("Doner :")}
                          row2={t('Sector :')}
                          // row3={t("Cost :")}
                          row4={t('Lunch date :')}
                          row5={t('Deadline :')}
                          /* details pars start >>>>> */

                          type="Tenders"
                          btn3="Document"
                          btn2={t('Details')}
                          detail1="Title :"
                          detail2="Status:"
                          detail3="Continent:"
                          detail4="Country:"
                          detail5="Sector:"
                          detail9="Cost:"
                          detail10="Lunch date:"
                          detail11="Deadline"
                          detail6="Executive Agency:"
                          detail7="Responsable:"
                          detail8="Link:"
                          detail12="Address:"
                          detail13="Email:"
                          detail14="Donor:"
                          agency={
                            elem.executing_agency
                              ? elem.executing_agency
                              : 'N/A'
                          }
                          deadlinedate={elem.submission_date}
                          continent={elem.continent}
                          cost={elem.estimated_amount_currency}
                          response={elem.ftq ? elem.ftq : 'N/A'}
                          link={elem.ref ? elem.ref : 'N/A'}
                          address={elem.executing_agency_address}
                          emaill={elem.executing_agency_email}
                          summary={elem.acquisition_description}
                          heading="Tender summary"
                          document={elem.document_path}
                          responsable={elem.country_decision_maker_name}
                          routpath="/tenders"
                        />
                      )
                    })
                  ) : (
                    <Loading />
                  )}
                </div>
              )}
              <Pagination
                style={{}}
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                size="xs"
                layout={['pager', 'skip']}
                total={newTotalDataCount}
                limitOptions={limitOption(newTenderData)}
                limit={perPage}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </main>
          <Chartboard />
          <Footer />
        </div>
      </div>
    </>
  )
}
export default Tenders
