import React, { useEffect, useState } from "react";
import bussinessAdvisors from "../../assets/img/catagory/Business_advisors.svg";
import projects from "../../assets/img/catagory/Assetproject.png";
import tenders from "../../assets/img/catagory/Assettenders.png";
import investments from "../../assets/img/catagory/Assetinvestments.png";
import jobs from "../../assets/img/catagory/Assetjobs.png";
import unitPrices from "../../assets/img/catagory/Assetunitprices.png";
import logo from "../../assets/img/BFA High Quality.svg";
import news from "../../assets/img/catagory/Assetnews.png";
import * as Icon from "react-feather";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { languages } from "./LanguageCode";
import { ToggleClick } from "../../container/Dashboard/Dashboard";

function Sidebar(props) {
  console.log(props)
  // const data = React.useContext(ToggleClick);
  // console.log("Context>>>>>>>>>",data)

  // const [activeMenu, setActiveMenu] = useState("dashboard");
  // const [activeSidebar, setactiveSidebar] = useState("dashboard");
  // const setCMenu = (cMenu) => {
  //   setActiveMenu((pState) => (pState = cMenu));
  //   };
  
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    // setActiveMenu("dashboard");
    console.log("Setting page stuff counter2");
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage, t]);

  return (
    <>
      <nav id="sidebar" style={props.openSideBar ?{display:"none"}:{}} className="sidebar sidebar-sticky ">
        <div className="sidebar-content  js-simplebar" >
          <NavLink className="sidebar-brand" to="/dashbord">
            <span className="align-middle">
              <img src={logo} style={{ height: "60px" }} />
            </span>
          </NavLink>

          <ul className="sidebar-nav">
            {" "}
            {/*Nav link start*/}

            {/*>>>>>>>>>>>>>>>>>>>>>>>>>>>  dashbodr */}
            <div className="items"> 
            <li
              // className={
              //   activeMenu === "dashboard"
              //     ? "sidebar-item active"
              //     : "sidebar-item"
              // }
            >
                   
              <NavLink
                // onClick={() => setActiveMenu("dashboard")}
                className="main_n" activeClassName="active" exact to="/dashboard" >
                 <div className="active_part">  
               <span>  <Icon.Grid /></span>
             <span className="icon_dash">  {t("Dashboard")}</span> 
               </div> 
              </NavLink>
               </li>

                <li>
                <NavLink
                // onClick={() => setActiveMenu("projects")}
                // className="sidebar-link"
                // to="/projects"
                 className="main_n" to="/projects" >
                 <div className="active_part"> 
                 <img
                  src={projects}
                  className="mr-2"
                  style={{ height: "35px" }}/>
                 <span className="align-middle"> {t("Projects")} </span>
                 </div>
                 </NavLink>
                 </li>
                <li>
              <NavLink
                // onClick={() => setActiveMenu("tenders")}
                // className="sidebar-link"
                // to="/tenders"}
                className="main_n" to="/Tenders">
                <div className="active_part"> 
                <img
                  src={tenders}
                  className="mr-2"
                  style={{ height: "35px" }}
                />
                <span className="align-middle"> {t("Tenders")} </span>
                </div>
              </NavLink>
              </li>

              <li>
              <NavLink
                // onClick={() => setActiveMenu("investments")}
                className="main_n"
                to="/investment">
                 <div className="active_part">
                <img
                  src={investments}
                  className="mr-2"
                  style={{ height: "35px",  }}
                />{" "}
                <span className="align-middle"> {t("Investments")} </span>
                </div>
              </NavLink>
              </li>
              <li>
              <NavLink
                // onClick={() => setActiveMenu("jobs")}
                // className="sidebar-link"
                className="main_n"
                to="/jobs">
               <div className="active_part"> 
               <img src={jobs} className="mr-2" style={{ height: "35px" }} />
                <span className="align-middle">{t("Jobs")}</span>
               </div>                
              </NavLink>
              </li>         
              <li>
              <NavLink
                // onClick={() => setActiveMenu("unitprices")}
                // className="sidebar-link"
                className="main_n"
                to="/unitprice">
                   <div className="active_part">
                   <img
                  src={unitPrices}
                  className="mr-2"
                  style={{ height: "35px" }}
                />{" "}
                <span className="align-middle"> {t("Unit-prices")} </span>
                  </div> 
              </NavLink>
                 </li>

                 <li>
              <NavLink
                // onClick={() => setActiveMenu("news")}
                // className="sidebar-link"
                className="main_n"
                to="/news">
                 <div className="active_part"> 
                 <img src={news} className="mr-2" style={{ height: "35px" }} />{" "}
                <span className="align-middle"> {t("News")} </span>
                 </div>
              </NavLink>
            </li>


            </div>
            {/* <li
              className={
                activeMenu === "bussinessadvisors"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
               >
              <NavLink
                onClick={() => setActiveMenu("bussinessadvisors")}
                className="sidebar-link"
                to="/bussinessadvisors"
              >
                <img
                  src={bussinessAdvisors}
                  className="mr-2"
                  style={{ height: "25px" }}
                />
                <span className="align-middle"> {t("Business Advisors")}</span>
              </NavLink>
            </li> */}
          
          </ul>
        </div>
        </nav>
    </>
  );
}

export default Sidebar;
