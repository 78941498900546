import React from 'react'
import '../../assets/css/newscard.css'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { FaSquareFull } from 'react-icons/fa'
import { FiExternalLink } from 'react-icons/fi'
import { Markup } from 'interweave'
import { Link, NavLink, useNavigate } from 'react-router-dom'

function Newscard(props) {
  const { title, image, description, content } = props
  const source = 'https://api.bfa-platform.com/'

  const newsNavigate = useNavigate()
  const handelClickread = () => {
    newsNavigate('/newsdetails', { state: props })
  }

  return (
    <>
      <div className="col-sm-12 col-md-6 col-xl-3 mb-4  ">
        <div className="card card_news">
          <div className="card-body card_main">
            <div className="more_read" onClick={handelClickread}>
              <span className="more_p">
                <p>
                  {' '}
                  <span className="icon_flat">
                    <FiExternalLink />
                  </span>
                  Read More
                </p>{' '}
              </span>
            </div>
            <img src={source + image} className="img_card " alt="..." />
            <div className="card_box_content_title">
              <h5 className="headline" onClick={handelClickread}>
                <span className="icon_right">
                  <FaSquareFull />{' '}
                </span>
                {title.length < 14 ? "...": title
                            ? title.split(' ', 14).join(' ')
                            : ''}...
                
              </h5>
              {/* <p class="card-text"><Markup content={description}/> </p> */}
            </div>
            {/* <button
                    className="readmore_btn"
                    onClick={handelClickread}>
                     readmore <span><HiOutlineArrowNarrowRight/></span>
                    <i
                      className="align-middle mr-2"
                      data-feather="chevron-right"
                    ></i>
                   </button> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Newscard
