import React, { useEffect, useRef, useState, useCallback } from "react";
import Header2 from "../../components/appSpecific/header2";
import Sidebar from "../../components/appSpecific/Sidebar";
import Footer from "../../components/appSpecific/Footer";
import Chartboard from "../ChartBoard/Chartboard";
import Paginate from "../../components/common/Paginate";
import axios from "axios";
import Newscard from "../../components/common/Newscard";
import Loading from "../../components/appSpecific/Loading";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { languages } from "../../components/appSpecific/LanguageCode";
import Newsimg from "../../assets/img/News.png";
import { BsSearch } from "react-icons/bs";
import { MdOutlinePlace } from "react-icons/md";
import { BiBuildings } from "react-icons/bi";
import { SelectPicker, Pagination } from "rsuite";
import { useNewsSearch } from "../../hooks/useNewsSearch";
import { debounce } from 'lodash';

  function News() {
  const [openSide, setopenSide] = useState(false);
  // const [btncolor, setBtncolor] = useState(false);
  // const [show, setShow] = useState(true);
  const [search, setSearch] = useState(null);
  const [selectedCountry, setselectedCountry] = useState(null);
  const [selectedSector, setselectedSector] = useState(null);
  const countryRef = useRef();

  const {
    newNewsData,
    newLoading,
    newTotalDataCount,
    countryList,
    sectorList,
    getNewsData,
    page,
    perPage,
    setPage,
    setPerPage,
  } = useNewsSearch();

  useEffect(() => {
    let searchParam = search ? search : null;
    let selectedCountryParam = selectedCountry ? selectedCountry : null;
    let selectedSectorParam = selectedSector ? selectedSector : null;
    getNewsData(searchParam, selectedCountryParam, selectedSectorParam);
  }, [page, perPage]);

  const handleSearch = (searchValue) => {
    setSearch(searchValue);
    let selectedSectorParam = selectedSector ? selectedSector : null;
    let selectedCountryParam = selectedCountry ? selectedCountry : null;
    console.log("selectedCountryParam ", selectedCountryParam);
    getNewsData(searchValue, selectedCountry, selectedSectorParam);
  };
  const handler = useCallback(debounce((value) => handleSearch(value), 500), [selectedCountry]);

  const handleCountry = (e) => {
    const country_id = e.target.value;
    setselectedCountry(country_id);
    let searchParam = search ? search : null;
    let selectedSectorParam = selectedSector ? selectedSector : null;
    getNewsData(searchParam, country_id, selectedSectorParam);
  };

  const handleSector = (e) => {
    const sector_id = e.target.value;
    setselectedSector(sector_id);
    let searchParam = search ? search : null;
    let selectedCountryParam = selectedCountry ? selectedCountry : null;
    getNewsData(searchParam, selectedCountryParam, sector_id);
  };


  //>>>>>>>>>>>>>>>>>>>>>limit log for pagination....
  const limitOpt = [10, 20, 50];
  const limitOption = (data) => {
    const res = [];
    const length = newTotalDataCount;
    const allDataLength = length + (5 - (length % 5));
    for (let i = 5; i <= allDataLength; i += 5) {
      res.push(i);
    }
    return res;
  };
  const handleChangeLimit = (data) => {
    setPage(1);
    setPerPage(data);
  };


  const obj = localStorage.getItem("news");
  const newss = JSON.parse(obj);

  //laguage Translator
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Setting page stuff counter2");
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage, t]);

  const [toggleState, setToggleState] = React.useState(false);
  const handleClickChange = (val) => {
    setToggleState(val);
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar value={toggleState} openSideBar={openSide} />
        <div className="main">
          <Header2
            handleChange={handleClickChange}
            handleSideBar={() => {
              setopenSide(!openSide);
            }}
          />
          <main className="content">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col col-xl-9 mb-5 ">
                  <span>
                    <img
                      src={Newsimg}
                      alt="img.."
                      style={{ position: "absolute", top: "7px", left: "15px" }}
                      height={53}
                      width={53}
                    />
                    <h3
                      class="font-weight-bold"
                      style={{
                        position: "relative",
                        top: "20px",
                        color: "",
                        left: "50px",
                      }}
                    >
                      {" "}
                      &nbsp;{t("News")}{" "}
                    </h3>
                  </span>
                  <h6
                    class="font-weight-normal mb-0"
                    style={{ position: "relative", top: "20px", color: "" }}
                  >
                    {t("You have")}
                    <span class="text-primary">
                      {" "}
                      &nbsp;{newTotalDataCount} {t("news!")}
                    </span>
                  </h6>
                </div>
                <div className="row ">
                  <div className="col col-xl-4 mb-4">
                    <form className="">
                      <input
                        className="form-control search_control"
                        type="text"
                        placeholder={t("Search")}
                        aria-label="Search"
                        onChange={(event) => handler(event.target.value)}
                      />
                      <span className="search_icon">
                        {" "}
                        <BsSearch />{" "}
                      </span>
                    </form>
                    <br />
                  </div>

                  <div class="col col-xl-2 mb-4" style={{ paddingLeft: "0" }}>
                    <span
                      style={{
                        position: "absolute",
                        fontSize: "20px",
                        marginLeft: "13px",
                        marginTop: "0px",
                      }}>
                      {" "}
                      <MdOutlinePlace />
                    </span>
                    <select
                      class="form-select select_country"
                      aria-label="Default select example"
                      onChange={handleCountry}>
                      &nbsp;
                      <option selected value="" className="option_txt">
                        {t("All Country")}
                      </option>
                      {/* <option value=""> All Country</option> */}
                      {countryList.map((elem) => {
                        return (
                          <>
                            <option value={elem.id}>{elem.name} </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div class="col col-xl-2 mb-4" style={{ paddingLeft: "0" }}>
                    <span
                      style={{
                        position: "absolute",
                        fontSize: "20px",
                        marginLeft: "16px",
                        marginTop: "0px",
                        zIndex: "99999",
                      }}>
                      {" "}
                      <BiBuildings />
                    </span>
                    <select
                      class="form-select Doner_side"
                      aria-label="Default select example"
                      onChange={handleSector}>
                      <option selected value="" className="option_txt">
                        {t("All Sector")}
                      </option>
                      {sectorList.map((elem) => {
                        return (
                          <>
                            <option value={elem.id}>{elem.name} </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                  <div class="col col-xl-2 mb-2" style={{ paddingLeft: "0" }}>
                    <span style={{}}>
                      <SelectPicker
                        value={perPage}
                        onChange={handleChangeLimit}
                        cleanable={false}
                        searchable={false}
                        data={limitOpt.map((key) => ({ value: key, label: key }))}
                        style={{}} />
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                {/* {(selectedCountry && !countryFilteredData.length) && (
                  <p align="center" style={{ paddingBottom: "20px", color: "#85929E" }}>
                    sorry there is no data ...
                  </p>
                )} */}
                {!newLoading ? (
                  newNewsData.map((elem) => {
                    return (
                      <Newscard
                        title={elem.title}
                        image={elem.image}
                        description={elem.description}
                        content={elem.content}
                      />
                    );
                  })
                ) : (
                  <Loading />
                )}
              </div>
              <Pagination
                style={{}}
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                size="xs"
                layout={["pager", "skip"]}
                total={newTotalDataCount}
                limitOptions={limitOption(newNewsData)}
                limit={perPage}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </main>
          <Chartboard />
        </div>
      </div>
    </>
  );
}

export default News;
