import React, { useEffect, useRef, useState, useCallback } from "react";
import Header2 from "../../components/appSpecific/header2";
import Sidebar from "../../components/appSpecific/Sidebar";
import Footer from "../../components/appSpecific/Footer";
import Projectcard from "../../components/common/Projectcard";
import Chartboard from "../ChartBoard/Chartboard";
import Loading from "../../components/appSpecific/Loading";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { languages } from "../../components/appSpecific/LanguageCode";
import Listview from "../../components/tableview/Listview";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { FaListUl } from "react-icons/fa";
import { BsSearch } from "react-icons/bs";
import projectimg from "../../assets/img/project.png";
import { MdOutlinePlace } from "react-icons/md";
import { SelectPicker, Pagination } from "rsuite";
import { BiBuildings } from "react-icons/bi";
import "./ref.css";
import { useProjectSearch } from "../../hooks/useProjectSearch";
import { debounce } from 'lodash';


function Projects() {
  const [openSide, setopenSide] = useState(false);
  const [btncolor, setBtncolor] = useState(false);
  const [show, setShow] = useState(true);
  const [search, setSearch] = useState(null);
  const [selectedCountry, setselectedCountry] = useState(null);
  const [selectedSector, setselectedSector] = useState(null);
  const countryRef = useRef();

  const {
    newProjectData,
    newLoading,
    newTotalDataCount,
    countryList,
    sectorList,
    getProjectData,
    page,
    perPage,
    setPage,
    setPerPage,
  } = useProjectSearch();

  useEffect(() => {
    let searchParam = search ? search : null;
    let selectedCountryParam = selectedCountry ? selectedCountry : null;
    let selectedSectorParam = selectedSector ? selectedSector : null;
    getProjectData(searchParam, selectedCountryParam, selectedSectorParam);
  }, [page, perPage]);

  const handleSearch = (searchValue) => {
    setSearch(searchValue);
    let selectedSectorParam = selectedSector ? selectedSector : null;
    let selectedCountryParam = selectedCountry ? selectedCountry : null;
    console.log("selectedCountryParam ", selectedCountryParam);
    getProjectData(searchValue, selectedCountry, selectedSectorParam);
  };

  const handler = useCallback(debounce((value) => handleSearch(value), 1500), [selectedCountry]);


  const handleCountry = (e) => {
    const country_id = e.target.value;
    setselectedCountry(country_id);
    let searchParam = search ? search : null;
    let selectedSectorParam = selectedSector ? selectedSector : null;
    getProjectData(searchParam, country_id, selectedSectorParam);
  };

  const handleSector = (e) => {
    const sector_id = e.target.value;
    setselectedSector(sector_id);
    let searchParam = search ? search : null;
    let selectedCountryParam = selectedCountry ? selectedCountry : null;
    getProjectData(searchParam, selectedCountryParam, sector_id);
  };

  //>>>>>>>>>>>>>>>>>>>>>limit log for pagination....
  const limitOpt = [10, 20, 50];
  const limitOption = (data) => {
    const res = [];
    const length = newTotalDataCount;
    const allDataLength = length + (5 - (length % 5));
    for (let i = 5; i <= allDataLength; i += 5) {
      res.push(i);
    }
    return res;
  };
  const handleChangeLimit = (data) => {
    setPage(1);
    setPerPage(data);
  };

  const obj = localStorage.getItem("project");
  // const projects = JSON.parse(obj);
  //laguage Translator
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage, t]);

  const [toggleState, setToggleState] = React.useState(false);
  const handleClickChange = (val) => {
    setToggleState(val);
  };
  // const sector = "sector data : ";

  return (
    <>
      <div class="wrapper">
        <Sidebar value={toggleState} openSideBar={openSide} />
        <div class="main">
          <Header2
            handleChange={handleClickChange}
            handleSideBar={() => {
              setopenSide(!openSide);
            }}
          />

          <main class="content">
            <div class="container-fluid p-0">
              <div class="row">
                <div class="col-12 col-xl-7 mb-5 ">
                  <span>
                    <img
                      src={projectimg}
                      alt="img.."
                      style={{ position: "absolute", top: "10px" }}
                      height={50}
                      width={50}
                    />
                    <h3
                      class="font-weight-bold"
                      style={{
                        position: "relative",
                        top: "20px",
                        color: "",
                        left: "50px",
                      }}
                    >
                      {" "}
                      &nbsp;{t("Projects")}{" "}
                    </h3>
                  </span>
                  <h6
                    class="font-weight-normal mb-0"
                    style={{ position: "relative", top: "20px", color: "" }}
                  >
                    {t("You have")}
                    <span class="text-primary">
                      &nbsp;{newTotalDataCount} {t("Projects!")}{" "}
                    </span>
                  </h6>
                </div>
              </div>
              <div class="row align-items-start">
                <div className="col col-sm-4 mb-2">
                  <form className="">
                    <input
                      className="form-control search_control"
                      type="text"
                      placeholder={t("Search")}
                      aria-label="Search"
                      onChange={(event) => handler(event.target.value)}
                    // value={search}
                    />
                    <span className="search_icon">
                      {" "}
                      <BsSearch />{" "}
                    </span>
                  </form>
                </div>
                <div class="col col-xl-2 mb-2" style={{ paddingLeft: "0" }}>
                  <span
                    style={{
                      position: "absolute",
                      fontSize: "20px",
                      marginLeft: "13px",
                      marginTop: "0px",
                    }}
                  >
                    {" "}
                    <MdOutlinePlace />
                  </span>
                  <select
                    class="form-select select_country"
                    ref={countryRef}
                    aria-label="Default select example"
                    onChange={handleCountry}
                  >
                    &nbsp;
                    <option selected value="" className="option_txt">
                      {t("All Country")}
                    </option>
                    {countryList.map((elem) => {
                      return (
                        <>
                          <option value={elem.id}>{elem.name} </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div class="col col-xl-2 mb-2" style={{ paddingLeft: "0" }}>
                  <span
                    style={{
                      position: "absolute",
                      fontSize: "20px",
                      marginLeft: "16px",
                      marginTop: "0px",
                      zIndex: "99999",
                    }}
                  >
                    {" "}
                    <BiBuildings />
                  </span>
                  <select
                    class="form-select Doner_side"
                    aria-label="Default select example"
                    onChange={handleSector}
                  >
                    &nbsp;
                    <option selected value="" className="option_txt">
                      {t("All Sector")}
                    </option>
                    {sectorList.map((elem) => {
                      return (
                        <>
                          <option value={elem.id}>{elem.name} </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div
                  class="col col-xl-4 mb-2"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <span
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    <SelectPicker
                      value={perPage}
                      onChange={handleChangeLimit}
                      cleanable={false}
                      searchable={false}
                      data={limitOpt.map((key) => ({ value: key, label: key }))}
                    />
                  </span>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    style={{
                      backgroundColor: btncolor ? "#56aae7" : "#F39C12",
                      borderRadius: "30px",
                      border: "none",
                      height: "32px",
                      color: "white",
                      width: "100px",
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      setShow(true);
                      setBtncolor(false);
                    }}
                  >
                    <span>
                      {" "}
                      <FaListUl />
                    </span>{" "}
                    <span
                      style={{ position: "relative", left: "2px", top: "1px" }}
                    >
                      {" "}
                      {t("List view")}{" "}
                    </span>
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    // class="btn btn-primary active grid_view"
                    style={{
                      backgroundColor: btncolor ? "#F39C12" : "#56aae7",
                      borderRadius: "30px",
                      border: "none",
                      height: "32px",
                      color: "white",
                      width: "100px",
                      marginRight: "10px",
                      boxShadow: "rgb(17 17 26 / 10%) 0px 0px 16px",
                    }}
                    onClick={() => {
                      setShow(false);
                      setBtncolor(true);
                    }}
                  >
                    <span>
                      {" "}
                      <BsFillGrid1X2Fill />
                    </span>{" "}
                    <span
                      style={{ position: "relative", left: "2px", top: "2px" }}
                    >
                      {" "}
                      {t("Grid view")}{" "}
                    </span>
                  </button>{" "}
                </div>
              </div>
              <br />
              {show && !newLoading ? (
                <div className="row">
                  <Listview
                    projectdata={newProjectData}
                    type="project"
                    hader1={t("Country")}
                    hader2={t("Type")}
                    hader3={t("Donor")}
                    hader4={t("Title")}
                    hader5={t("Sector")}
                    hader6={t("Amount ($)")}
                    hader7={t("Reports")}
                    hader8={t("Tenders")}
                  />
                </div>
              ) : (
                <div class="row">
                  {/* {(selectedCountry && !countryFilteredData.length) && (
                    <p align="center" style={{ paddingBottom: "20px", color: "#85929E" }}>
                      sorry there is no data ...
                    </p>
                  )} */}
                  {!newLoading ? (
                    newProjectData.map((elem) => {
                      return (
                        <Projectcard
                          status={
                            elem.project_status ? elem.project_status : "N/A"
                          }
                          countrey={elem.country ? elem.country : "N/A"}
                          Phase={elem.name}
                          val1={elem.sector ? elem.sector : "N/A"}
                          val2={elem.donor}
                          val3={
                            elem.amount
                              ? elem.amount
                              : "N/A"
                          }
                          continent={elem.continent}
                          agency={
                            elem.executing_agency
                              ? elem.executing_agency
                              : "N/A"
                          }
                          link={elem.website ? elem.website : "N/A"}
                          response={elem.valid ? elem.valid : "N/A"}
                          heading={t("Project summary")}
                          row1={t("Sector :")}
                          descripition={
                            elem.analytic_summary == null
                              ? "N/A"
                              : elem.analytic_summary
                          }
                          row2={t("Doner :")}
                          row3={t("Amount :")}
                          type="Projects"
                          btn1={t("Reports")}
                          btn2={t("Details")}
                          btn3={t("Tenders")}
                          detail1="Name of the project:"
                          detail2={t("Status :")}
                          detail3="Name of the Continent:"
                          detail4="Country:"
                          detail5="Sector:"
                          detail6="Executive Agency:"
                          detail7="Responsable:"
                          detail8="Link:"
                          responsable={ elem.boss_name
                            == null
                            ? "N/A"
                            : elem.boss_name
                          }
                          repobtn={elem.par_report ? elem.par_report : ""}
                          annex={elem.annex ? elem.annex : ""}
                          ppmbtn={elem.ppm ? elem.ppm : ""}
                          routpath="/projects"
                          isDetails={true}
                        />
                      );
                    })
                  ) : (
                    <Loading />
                  )}
                </div>
              )}
              <Pagination
                style={{}}
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                size="xs"
                layout={["pager", "skip"]}
                total={newTotalDataCount}
                limitOptions={limitOption(newProjectData)}
                limit={perPage}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </main>
          <Chartboard />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Projects;
