import React from "react";
import Notification from "./HeaderComponents/Notification";
import Languages from "./HeaderComponents/Languages";
import Profile from "./HeaderComponents/Profile";
import Search from "./HeaderComponents/Search";
import "../../assets/css/classic.css";
import Sidebar from "./Sidebar";
import { HiOutlineMenuAlt1 } from "react-icons/hi";


 function Header2(props) {
  const [taggleState, setToggeleState] = React.useState(true);
   const humburgerManu = () => {
    props.handleSideBar();
    
  };

  return (
    <>
      {/* <Sidebar taggleState={taggleState} />    */}
      <nav className="navbar navbar-expand navbar-light bg-white">
        {/* <button onClick={humburgerManu}>Click Me</button> */}
         <span  onClick={humburgerManu} 
          className="hamclick"
        > <HiOutlineMenuAlt1/> </span>
          {/* <a className="sidebar-toggle d-flex mr-4">
          <i
            className="hamburger align-self-center"
            onClick={humburgerManu}
          ></i>
        </a> */}
        <Search />
        <div className="navbar-collapse collapse">
          <ul className="navbar-nav ml-auto">
            <Notification />
            <Languages />
            <Profile />
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Header2;
